<template>
    <div class="module__auth__logout">
        <div>
            <div class="ot-spinner" />

            <h5>{{ $t('dashboard.common.state.loading') }}</h5>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router/composables';
import { injectOrFail } from '../../../services/util';
import type { PluginsManager } from '../../../plugins';

const plugins = injectOrFail<PluginsManager>('plugins');
const router = useRouter();

void (async () => {
    try {
        await plugins.auth.logout();
    } catch (e) {
        // TODO SEND LOG
        // Stringified, because a redirect will make it impossible to open the object in the console.
        console.warn('Failed to logout', JSON.stringify({ e }));

        void router.push({ name: 'error', query: { reason: e instanceof Error ? e.message : 'Failed to logout' } });
    }
})();
</script>

<style lang="scss" scoped>
.module__auth__logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .ot-spinner::after {
        position: relative;
    }

    h5 {
        margin-top: 1rem;
    }
}
</style>
