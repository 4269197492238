<template>
    <div
        class="form-modal"
        data-testid="form-modal"
    >
        <div
            class="form-modal__backdrop"
            data-testid="form-modal-backdrop"
            role="button"
            tabindex="0"
            @click="close"
            @keydown.esc="close"
        />

        <OtCard
            class="form-modal__card"
        >
            <OtCardHeader
                :title="props.title"
                :subtitle="props.subtitle"
            />

            <OtCardContent ref="cardContent">
                <slot />
            </OtCardContent>

            <OtCardFooter class="form-modal__footer">
                <button
                    :title="$t('dashboard.common.action.cancel.title')"
                    class="ot-button is-dark"
                    data-testid="form-modal-cancel"
                    @click="close"
                >
                    <OtIcon
                        type="close"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.common.action.cancel.text') }}
                </button>
                <div class="form-modal__footer__right">
                    <button
                        v-if="addAnotherEnabled"
                        class="ot-button"
                        data-testid="form-modal-submit-add-another"
                        :title="addAnotherButtonLabels?.title || $t('dashboard.common.action.add_another.title')"
                        :disabled="!form.hasLocalChanges"
                        @click="submit(true)"
                    >
                        <OtIcon
                            type="plus"
                            class="ot-button-icon--left"
                        />
                        {{ addAnotherButtonLabels?.text || $t('dashboard.common.action.add_another.text') }}
                    </button>
                    <button
                        :disabled="!form.hasLocalChanges"
                        :title="submitButtonLabels?.title || $t('dashboard.common.action.save.title')"
                        class="ot-button"
                        data-testid="form-modal-submit"
                        @click="submit()"
                    >
                        <OtIcon
                            type="check"
                            class="ot-button-icon--left"
                        />
                        {{ submitButtonLabels?.text || $t('dashboard.common.action.save.text') }}
                    </button>
                </div>
            </OtCardFooter>
        </OtCard>
    </div>
</template>

<script setup lang="ts" generic="M extends Model<P, ModelConfig>, P extends Parent">
import {
    onMounted, onUnmounted, ref, type UnwrapNestedRefs,
} from 'vue';
import type { Model, ModelConfig, Parent } from '@openticket/lib-crud';
import { OtCardContent } from '@openticket/vue-dashboard-components';
import type { FormModalComposableResult, ModelFormComposableResult } from '../../composables/forms';
import { useGenericErrorHandling } from '../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<M, P>>,
    modal: UnwrapNestedRefs<FormModalComposableResult>,
    title: string,
    subtitle?: string,
    submitButtonLabels?: {
        text?: string,
        title?: string,
    },
    addAnotherEnabled?: boolean,
    addAnotherButtonLabels?: {
        text?: string,
        title?: string,
    },
}

const props = defineProps<Props>();

const { handleErrorSilently } = useGenericErrorHandling();
const cardContent = ref<InstanceType<typeof OtCardContent>>();

onMounted(() => {
    window.addEventListener('keydown', escListener, true);

    if (!cardContent.value) {
        return;
    }

    const inputElement = cardContent.value.$el.querySelector('input');
    if (inputElement) {
        inputElement.focus();
    }
});

onUnmounted(() => {
    window.removeEventListener('keydown', escListener, true);
});

async function submit(addAnother?: boolean) {
    try {
        const result = await props.form.submit();

        if (addAnother && result.success) {
            props.form.reset();
            return;
        }

        if (result.success) {
            close();
        }
    } catch (e) {
        void handleErrorSilently(e);
    }
}

function close() {
    props.modal.close();
}

function escListener(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') {
        void props.modal.close();
        event.stopImmediatePropagation();
    }

    if (event.key === 'Enter' && props.form.hasLocalChanges) {
        void submit();
    }
}
</script>

<style scoped lang="scss">
.form-modal {
    z-index: 300;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--ot-color-core-light-foreground-secondary) transparent;

    &__footer {
        text-wrap: nowrap;
        gap: var(--ot-spacing-default);
        align-items: center;

        &__right {
            justify-content: flex-end;
            display: flex;
            flex-wrap: wrap;
            gap: var(--ot-spacing-default);
            flex-grow: 1;
        }
    }

    @media (max-width: 40rem) {
        &__footer {
            align-items: stretch;
            text-wrap: wrap;

            &__right {
                align-items: stretch;
                flex-direction: column-reverse;
            }
        }
    }

    &__backdrop {
        position: fixed;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.3;
    }

    &__card {
        border-radius: var(--ot-input-radius);
        overflow: visible !important;
        z-index: 301;
        margin: 15vh auto var(--ot-spacing-3xl) auto;
        width: 100%;
        max-width: calc(65rem - var(--ot-layout-sidebar-width, 16rem));
        padding: 0;

        @media (max-width: 40rem) {
            margin: 0;
        }

        .card-footer {
            border-bottom-right-radius: var(--ot-input-radius);
            border-bottom-left-radius: var(--ot-input-radius);
        }

        .card-content {
            padding-bottom: var(--ot-spacing-4xl);
        }
    }
}
</style>
