<template>
    <FormWrapper
        class="waiting-list-settings"
        :form="waitingListSettingsForm"
        back-route="waiting_list.signup_list"
    >
        <template #header-title>
            <div
                class="waiting-list__header"
            >
                <h1>{{ $t('dashboard.waiting_list.settings.header.title') }}</h1>
                <div
                    v-if="context.event && context.event.name"
                    class="waiting-list__header__subtitle ot-text-body-strong"
                >
                    {{ context.event.name }}
                </div>
            </div>
        </template>
        <VerticalTabs>
            <template #default="{ selected }">
                <VerticalTabsItem
                    :label="$t('dashboard.waiting_list.settings.vertical_tabs.settings')"
                    :route="{ name: 'waiting_list.settings'}"
                    :selected="selected"
                >
                    <WaitingListSettingsForm :form="waitingListSettingsForm" />
                </VerticalTabsItem>
            </template>
        </VerticalTabs>
    </FormWrapper>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';
import { useUpdateForm } from '../../../composables/forms';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import FormWrapper from '../../../components/form/FormWrapperNew.vue';
import WaitingListSettingsForm from '../components/forms/WaitingListSettingsForm.vue';
import type { WaitingList } from '../../../services/waiting-list/client/models';
import type { WaitingListClient } from '../../../services/waiting-list/client/waitingListClient';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';

const context = injectOrFail<Context>('context');

// TODO add watcher for when the id on the url changes

if (!context.event || !context.event.id || !context.company || !context.company.id) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const waitingListClient = injectOrFail<WaitingListClient>('waitingListClient');
const waitingListSettings = ref<WaitingList<WaitingListClient>>();

const waitingListSettingsForm = reactive(
    useUpdateForm<WaitingList<WaitingListClient>, WaitingListClient>(
        context.event.waitingList.model,
        waitingListClient.waitingList,
    ),
);

void (async () => {
    if (context.event && context.event.id && context.company && context.company.id) {
        waitingListSettings.value = await waitingListClient.findOrCreate(context.event.id, context.company.id);
    } else {
        // TODO Properly log error & localise reason.
        throw Error('Invalid context');
    }
})();

</script>

<style lang="scss" scoped>
.waiting-list__header__subtitle {
    color: var(--ot-color-core-brand);
}
</style>
