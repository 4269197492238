<template>
    <div class="update-mutation">
        <div v-if="amountOfChanges === 1">
            <i18n
                path="dashboard.revisions.item.update.single"
                tag="p"
                class="update-mutation-single"
                :class="{ open: showContent }"
            >
                <template #name>
                    <strong>
                        <span class="capitalize">
                            {{ changedTypeTranslation }}
                        </span>
                        {{ changedKeyTranslation }}
                    </strong>
                </template>
                <template #oldValue>
                    <s>{{ formatChangeFromMutation(mutation).old }}</s>
                </template>
                <template #newValue>
                    <strong>{{ formatChangeFromMutation(mutation).val }}</strong>
                </template>
            </i18n>

            <p
                v-if="hasLongChange"
                role="button"
                tabIndex="0"
                class="update-mutation-toggle ot-text-small"
                @click="toggleContent"
                @keydown.space="toggleContent"
                @keydown.enter="toggleContent"
            >
                <template v-if="!showContent">
                    {{ $t('dashboard.revisions.toggle.open') }}
                </template>
                <template v-if="showContent">
                    {{ $t('dashboard.revisions.toggle.close') }}
                </template>
                <OtIcon
                    type="drop-down"
                    size="small"
                    :class="{ open: showContent }"
                />
            </p>
            <p class="update-mutation-meta ot-text-tiny">
                {{ $l.dateTimeLong(mutation.time) }}
                <span v-if="mutatedBy">
                    {{ ' - ' }}
                    {{ $t('dashboard.revisions.by') }}
                    {{ mutatedBy }}
                </span>
            </p>
        </div>

        <!-- multiple mutation -->
        <div v-if="amountOfChanges > 1">
            <i18n
                path="dashboard.revisions.item.update.multiple"
                tag="p"
                class="update-mutation-single"
            >
                <template #amountAndType>
                    <strong>
                        {{ $t('dashboard.revisions.item.update.amount_and_type', { amount: amountOfChanges }) }}
                    </strong>
                </template>
            </i18n>
            <p class="update-mutation-meta ot-text-tiny">
                {{ $l.dateTimeLong(mutation.time) }}
                <span v-if="mutatedBy">
                    {{ ' - ' }}
                    {{ $t('dashboard.revisions.by') }}
                    {{ mutatedBy }}
                </span>
            </p>
            <TimeLine
                :items="mutation.change.changes"
                :model="mutation.model.type"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import type { Mutation } from '../../../services/revisor';
import { formatChangeFromMutation, getChange, humanize } from '../changeFormatter';
import TimeLine from '../../timeline/TimeLine.vue';
import { useLocalization } from '../../../composables';

type Props = {
    mutation: Mutation<'update'>,
    mutatedBy?: string
}

const props = defineProps<Props>();

const { t, te } = useLocalization();

const showContent = ref<boolean>(false);

const changedKeyTranslation = computed<string>(() => {
    const key = `models.${props.mutation.model.type}.attributes.${Object.keys(props.mutation.change.changes)[0]}.label`;
    if (te(key)) {
        return t(key);
    }
    return humanize(Object.keys(props.mutation.change.changes)[0]);
});
const changedTypeTranslation = computed<string>(() => {
    const key = `models.${props.mutation.model.type}.name.single`;
    if (te(key)) {
        return t(key);
    }
    return props.mutation.model.type.toString();
});
const amountOfChanges = computed<number>(() => Object.keys(props.mutation.change.changes).length ?? 0);
const hasLongChange = computed<boolean>(() => {
    const maxLength = 160;
    const { val, old } = getChange(props.mutation);

    if (!val || !old) {
        return false;
    }

    return String(val).length + String(old).length > maxLength;
});

function toggleContent(): void {
    showContent.value = !showContent.value;
}
</script>

<style lang="scss" scoped>

.update-mutation {
    &-single {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow-wrap: anywhere;
        max-height: 5rem;
        transition: max-height 250ms ease-in-out;

        strong {
            & .capitalize {
                text-transform: capitalize;
            }
        }

        &.open {
            -webkit-line-clamp: 300;
            max-height: 50rem;
        }
    }

    &-meta {
        color: var(--ot-color-core-light-foreground-secondary);
    }

    &-toggle {
        color: var(--ot-color-core-brand);
        padding: 0.25rem 0;
        display: flex;
        align-items: center;
        gap: 0.125rem;
        cursor: pointer;
        font-weight: 600;

        > svg {
            transform: rotate(0deg);
            transition: transform 250ms ease-in-out;

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    strong {
        font-weight: 500;
    }
}

</style>
