<template>
    <div class="company-general">
        <div class="company-general__header">
            <h3>{{ $t('dashboard.company.edit.general.page_title') }}</h3>
            <span class="ot-text-body">{{ $t('dashboard.company.edit.general.page_description') }}</span>
        </div>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.company.edit.general.form.name.label')"
                :description="$t('dashboard.company.edit.general.form.name.description')"
                :required="form.rules.name?.includes('required')"
                :optional="form.rules.name?.includes('optional')"
                :error="form.errors.name"
            >
                <OtInput
                    v-model="form.model.$data.name"
                    :invalid="form.errors.name?.length > 0"
                    class="input"
                    data-testid="company-general-form-name"
                    type="text"
                    @input="form.errors.name = []"
                />
            </InputField>

            <InputField
                :label="$t('dashboard.company.edit.general.form.website.label')"
                :description="$t('dashboard.company.edit.general.form.website.description')"
                :required="form.rules.website?.includes('required')"
                :optional="form.rules.website?.includes('optional')"
                :error="form.errors.website"
            >
                <OtInput
                    v-model="form.model.$data.website"
                    :invalid="form.errors.website?.length > 0"
                    class="input"
                    data-testid="company-general-form-website"
                    type="text"
                    @input="form.errors.website = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.company.edit.general.form.currency.label')"
                :description="$t('dashboard.company.edit.general.form.currency.description')"
                :required="form.rules.currency?.includes('required')"
                :optional="form.rules.currency?.includes('optional')"
                :error="form.errors.currency"
            >
                <OtInputSelect
                    v-model="form.model.$data.currency"
                    :internal-search="true"
                    :invalid="form.errors.currency?.length > 0"
                    :options="currencies"
                    :searchable="true"
                    class="input"
                    data-testid="company-general-form-currency"
                    sort="alphabetically"
                    @input="form.errors.currency = []"
                />
            </InputField>

            <div />
        </OtFormRow>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.company.edit.general.form.locale.label')"
                :description="$t('dashboard.company.edit.general.form.locale.description')"
                :required="form.rules.locale?.includes('required')"
                :optional="form.rules.locale?.includes('optional')"
                :error="form.errors.locale"
            >
                <OtInputSelect
                    v-model="form.model.$data.locale"
                    :internal-search="true"
                    :invalid="form.errors.locale?.length > 0"
                    :options="locales"
                    :searchable="true"
                    class="input"
                    data-testid="company-general-form-locale"
                    sort="alphabetically"
                    @input="form.errors.locale = []"
                />
            </InputField>

            <InputField
                :label="$t('dashboard.company.edit.general.form.country.label')"
                :description="$t('dashboard.company.edit.general.form.country.description')"
                :required="form.rules.country?.includes('required')"
                :optional="form.rules.country?.includes('optional')"
                :error="form.errors.country"
            >
                <OtInputSelect
                    v-model="form.model.$data.country"
                    :internal-search="true"
                    :invalid="form.errors.country?.length > 0"
                    :options="countries"
                    :searchable="true"
                    class="input"
                    data-testid="company-general-form-country"
                    sort="alphabetically"
                    @input="form.errors.country = []"
                />
            </InputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import { computed } from 'vue';
import type { AuthClient, Company } from '@openticket/lib-auth';
import InputField from '../../../../components/form/InputField.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { useLocalization } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Company<AuthClient>, Company<AuthClient>>>
}

defineProps<Props>();

const { localization } = useLocalization();

const countries = computed<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    const { supportedCountries } = localization;

    if (supportedCountries) {
        for (const key of Object.keys(supportedCountries)) {
            map[key] = `${supportedCountries[key].flag} ${supportedCountries[key].territoryDisplayName}`;
        }
    }

    return map;
});

const currencies = computed<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    const { supportedCurrencies } = localization;

    if (supportedCurrencies) {
        for (const key of Object.keys(supportedCurrencies)) {
            map[key] = `${supportedCurrencies[key].currencySymbol} - ${supportedCurrencies[key].currencyDisplayName}`;
        }
    }

    return map;
});

const locales = computed<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    const { supportedLocales } = localization;

    if (supportedLocales) {
        for (const key of Object.keys(supportedLocales)) {
            map[key] = `${supportedLocales[key].flag} ${supportedLocales[key].languageDisplayName}`;
        }
    }

    return map;
});
</script>

<style lang="scss" scoped>
.company-general {
    &__header {
        margin-bottom: var(--ot-spacing-unit);
    }
}
</style>
