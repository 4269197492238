<template>
    <FormModal
        :form="form"
        :modal="modal"
        :title="$t('dashboard.access_moments.list.new.title')"
        :subtitle="$t('dashboard.access_moments.list.new.intro')"
        :submit-button-labels="{
            text: $t('dashboard.access_moments.list.new.create.text'),
            title: $t('dashboard.access_moments.list.new.create.title')
        }"
        :add-another-enabled="showAddAnotherButton"
    >
        <AccessMomentFormFields
            :form="form"
            :show-location-field="showLocationField"
            :show-name-field="showNameField"
        />
    </FormModal>
</template>

<script setup lang="ts">
import {
    onMounted,
    reactive, watch, type UnwrapNestedRefs,
} from 'vue';
import type {
    Event, EventDate, ManagementClient,
} from '@openticket/lib-management';
import { capitalize, injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import FormModal from '../../../components/form/FormModal.vue';
import { useCreateForm, type FormModalComposableResult } from '../../../composables/forms';
import AccessMomentFormFields from './forms/AccessMomentFormFields.vue';
import { useLocalization } from '../../../composables';

type Props = {
    modal: UnwrapNestedRefs<FormModalComposableResult>,
    showAddAnotherButton?: boolean;
    showLocationField?: boolean;
    showNameField?: boolean;
}

type Emits = {
    (e: 'submit', model: EventDate<ManagementClient>): void
}

withDefaults(defineProps<Props>(), {
    showLocationField: undefined,
    showNameField: undefined,
});
const emit = defineEmits<Emits>();

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');

const { localization } = useLocalization();

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const form = reactive(
    useCreateForm<EventDate<ManagementClient>, Event<ManagementClient>>(
        context.event.model.eventDates.new(),
        context.event.model.eventDates,
        {
            onSubmit(model) {
                emit('submit', model);
            },
        },
    ),
);

watch([ () => form.model.$data.start, () => form.model.$data.end ], ([ start, end ]) => {
    // Name is unset and both start and end are non-empty strings
    if (!form.model.$data.name && start && typeof start === 'string' && end && typeof end === 'string') {
        form.model.$data.name = generateName(start, end);
    }
});

onMounted(() => {
    // TODO This has to be fixed in the lib-management that the default data also sets the capacity value to 0.
    form.model.$data.capacity = 0;
});

function generateName(start: string, end: string) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const startDay = localization.locale.dayNamesWide[startDate.getDay()];

    // Checks whether the end date is 24 hours (86400000 ms) later then the start date
    if ((endDate.getTime() - startDate.getTime()) >= 86400000) {
        const endDay = localization.locale.dayNamesWide[endDate.getDay()];

        return `${capitalize(startDay)} - ${capitalize(endDay)}`;
    }

    return capitalize(startDay);
}
</script>
