import type {
    OpenTicketCrossWindowInitMessage,
    OpenTicketCrossWindowMessage,
    OpenTicketCrossWindowMessageEvent,
} from './types';

function isOpenTicketMessage(event: unknown): event is OpenTicketCrossWindowMessageEvent {
    return !!event
        && !!(event as { data?: unknown }).data
        && !!(event as { data: { _isOpenticket?: unknown } }).data._isOpenticket
        && !!(event as { data: { source?: MessageEventSource } }).data.source;
}

function isOpenTicketInitMessage(message: OpenTicketCrossWindowMessage): message is OpenTicketCrossWindowInitMessage {
    return !!(message as { init?: unknown }).init;
}

export class MinimalCrossWindowClient {

    readonly config: {
        window: WindowProxy;
    };

    connecting: Promise<void>;

    private resolveConnecting!: () => void;
    private rejectConnecting!: (error: Error) => void;

    constructor(windowProxy: WindowProxy) {
        if (windowProxy === window) {
            // TODO Proper error
            throw new Error('Cannot use the current window as a target');
        }

        // Prevent Vue from trying to observe a cross-domain address,
        // as this will lead to a security error on (modern) browsers.
        this.config = Object.freeze({
            window: windowProxy,
        });

        this.connecting = new Promise((
            resolve: () => void,
            reject: (error: Error) => void,
        ) => {
            this.resolveConnecting = resolve;
            this.rejectConnecting = reject;
        });

        // Listening to events is done on the client's own window.
        window.addEventListener('message', (ev: OpenTicketCrossWindowMessageEvent) => this.messageListener(ev));

        const initMessage: OpenTicketCrossWindowInitMessage = {
            _isOpenticket: true,
            init: 'SYN',
            scope: 'client',
        };

        // Send initial handshake message
        this.sendRawMessage(initMessage);
    }

    /**
     * Set static shop settings
     */
    public setStaticShopSettings(data: unknown): void {
        this.sendMessage({
            data,
            type: 'static_settings',
        });
    }

    /**
     * Internal handler for messages to do basic checks and filter spam
     */
    private messageListener(event: OpenTicketCrossWindowMessageEvent): void {
        // Discard other messages
        if (!isOpenTicketMessage(event)) {
            return;
        }

        if (event.data.scope === 'client') {
            // Clients can only interact with non-clients (and non-clients can only interact with clients)
            return;
        }

        if (event.source !== this.config.window) {
            // Discard if the origin of the event is different from the target window
            return;
        }

        const message: OpenTicketCrossWindowMessage = event.data;

        // Check if the message is an `init` message
        if (isOpenTicketInitMessage(message)) {
            switch (message.init) {
                case 'SYN':
                    this.sendMessage({ init: 'SYN-ACK', payload: { type: 'dashboard' } });

                    return;
                case 'SYN-ACK':
                    this.sendMessage({ init: 'ACK', payload: { type: 'dashboard' } });

                    this.resolveConnecting();

                    return;
                case 'ACK':
                    this.resolveConnecting();

                    return;
                default:
                    // TODO Proper error logging
                    console.error('Invalid OpenTicket cross window init message', { message });

                    throw new Error('Invalid OpenTicket cross window init message');
            }
        }

        // Currently the minimal implementation does not require handling anything but initialisation.
    }

    /**
     * Internal send method
     */
    private sendMessage(data: Omit<OpenTicketCrossWindowMessage, 'scope' | '_isOpenticket'> | { [key: string]: unknown; }): void {
        this.sendRawMessage({
            ...data,
            scope: 'client',
            _isOpenticket: true,
        });
    }

    /**
     * Sends a raw message to the specified window
     */
    private sendRawMessage(data: OpenTicketCrossWindowMessage | { [key: string]: unknown; }): void {
        this.config.window.postMessage(data, '*');
    }

}
