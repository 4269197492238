<template>
    <OtDialogModal
        ref="modalRef"
        class="link-unlink-modal"
        data-testid="link-unlink-modal"
    >
        <OtCard>
            <OtCardHeader
                :title="props.title"
                :subtitle="props.subtitle"
                data-testid="link-unlink-modal-title"
            />

            <OtCardContent
                class="link-unlink-modal__content"
            >
                <LinkUnlinkPicker
                    v-if="pickerInitialized"
                    :key="linkUnlinkPickerKey"
                    ref="linkUnlinkPickerRef"
                    :create-modal="createModal"
                    :items-label="itemsLabel"
                    :link-relation="linkRelation"
                    :pagination-relation="paginationRelation"
                    @saved="(items) => emit('saved', items)"
                    @submit="(items) => emit('submit', items)"
                />
            </OtCardContent>

            <OtCardFooter>
                <template #left>
                    <button
                        class="ot-button is-dark"
                        data-testid="link-unlink-modal-cancel"
                        :title="t('dashboard.common.action.cancel.title')"
                        @click="close"
                    >
                        <OtIcon
                            type="close"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.cancel.text') }}
                    </button>
                </template>

                <template #right>
                    <button
                        class="ot-button"
                        data-testid="link-unlink-modal-submit"
                        :title="saveLabel"
                        :disabled="!linkUnlinkPickerRef || !linkUnlinkPickerRef?.hasLocalChanges"
                        @click="save"
                    >
                        <OtIcon
                            type="check"
                            class="ot-button-icon--left"
                        />
                        {{ saveLabel }}
                    </button>
                </template>
            </OtCardFooter>
        </OtCard>
    </OtDialogModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { OtDialogModal } from '@openticket/vue-dashboard-components';
import type {
    CrudNode,
    LinkMultiable,
    Listable,
    Model,
    ModelConfig,
    Parent,
    Pickable,
    UnlinkMultiable,
} from '@openticket/lib-crud';
import LinkUnlinkPicker from './LinkUnlinkPicker.vue';
import type FormModal from '../../form/FormModal.vue';
import { useLocalization } from '../../../composables';

type Props = {
    createModal?: InstanceType<typeof FormModal>,
    inputDescription?: string,
    inputLabel?: string,
    itemsLabel?: string;
    linkRelation?: Pickable<Model<Parent, ModelConfig>, ModelConfig, Parent>
        & LinkMultiable<Model<CrudNode<Parent>, ModelConfig>, ModelConfig, CrudNode<Parent>, Model<Parent, ModelConfig>>
        & UnlinkMultiable<Model<CrudNode<Parent>, ModelConfig>, ModelConfig, CrudNode<Parent>, Model<Parent, ModelConfig>>,
    notShownLabel?: string,
    paginationRelation: Listable<Model<Parent, ModelConfig>, ModelConfig, Parent>,
    saveText?: string,
    subtitle?: string,
    title: string,
}

type Emits = {
    (e: 'saved', linkedGuids: string[]): void
    (e: 'submit', linkedGuids: string[]): void
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const { t } = useLocalization();

const modalRef = ref<InstanceType<typeof OtDialogModal> | null>(null);
// Only render the picker when the modal has been opened for the first time, to prevent a call at initialization.
const pickerInitialized = ref<boolean>(false);
const linkUnlinkPickerKey = ref((Math.random() + 1).toString(36).substring(7));
const linkUnlinkPickerRef = ref<InstanceType<typeof LinkUnlinkPicker> | null>(null);

const saveLabel = props.saveText || t('dashboard.common.action.save.text');

function save() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    linkUnlinkPickerRef.value?.submit();
    close();
}

function open() {
    if (modalRef.value) {
        pickerInitialized.value = true;
        linkUnlinkPickerKey.value = (Math.random() + 1).toString(36).substring(7);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value.show();
    }
}

function close() {
    if (modalRef.value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value.close();
    }
}

defineExpose({
    open,
});
</script>

<style lang="scss" scoped>
.link-unlink-modal {
    min-width: 600px;
    overflow: visible;

    &__content {
        padding-bottom: var(--ot-spacing-2xl) !important;
    }

    @media (max-width: 40rem) {
        min-width: unset;
        width: 100% !important;
    }

    &::v-deep {
        .card {
            overflow: visible !important;
        }

        .card-footer {
            border-radius: 0 0 var(--ot-input-radius) var(--ot-input-radius);
        }
    }
}
</style>
