<template>
    <div class="waiting-list-settings-form">
        <div class="waiting-list-settings-form__activation">
            <h3>{{ $t('dashboard.waiting_list.settings.settings_form.activation.title') }}</h3>
            <p class="ot-text-body">
                {{ $t('dashboard.waiting_list.settings.settings_form.activation.description') }}
            </p>
        </div>
        <OtFormRow>
            <InputField
                :label="$t('dashboard.waiting_list.settings.enabled.title')"
                :description="translateOrUndefined('dashboard.waiting_list.settings.enabled.description')"
                :required="form.rules.enabled && form.rules.enabled.includes('required')"
                :optional="form.rules.enabled && form.rules.enabled.includes('optional')"
                :error="form.errors.enabled"
            >
                <OtInput
                    v-model="form.model.$data.enabled"
                    :label="$t('dashboard.waiting_list.settings.enabled.label')"
                    class="input"
                    type="toggle"
                    data-testid="waiting-list-form-input-enabled"
                    @input="form.errors.enabled = []"
                />
            </InputField>
        </OtFormRow>
        <hr
            class="ot-separator waiting-list-settings-form__seperator"
        >
        <div class="waiting-list-settings-form__settings">
            <h3>{{ $t('dashboard.waiting_list.settings.settings_form.settings.title') }}</h3>
            <p
                v-if="$te('dashboard.waiting_list.settings.settings_form.settings.description')"
                class="ot-text-body"
            >
                {{ $t('dashboard.waiting_list.settings.settings_form.settings.description') }}
            </p>
        </div>
        <OtFormRow>
            <InputField
                :label="$t('dashboard.waiting_list.settings.type.title')"
                :description="translateOrUndefined('dashboard.waiting_list.settings.type.description')"
                :required="form.rules.type && form.rules.type.includes('required')"
                :optional="form.rules.type && form.rules.type.includes('optional')"
                :error="form.errors.type"
            >
                <OtInput
                    v-model="form.model.$data.type"
                    :options="typeOptions"
                    class="input"
                    type="select"
                    :disabled="!form.model.$data.enabled"
                    data-testid="waiting-list-form-input-type"
                    @input="form.errors.type = []"
                />
            </InputField>
        </OtFormRow>
        <OtFormRow>
            <InputField
                :label="$t('dashboard.waiting_list.settings.allow_quantity.title')"
                :description="translateOrUndefined('dashboard.waiting_list.settings.allow_quantity.description')"
                :required="form.rules.allow_quantity && form.rules.allow_quantity.includes('required')"
                :optional="form.rules.allow_quantity && form.rules.allow_quantity.includes('optional')"
                :error="form.errors.allow_quantity"
            >
                <OtInput
                    v-model="form.model.$data.allow_quantity"
                    :label="$t('dashboard.waiting_list.settings.allow_quantity.label')"
                    class="input"
                    type="toggle"
                    :disabled="!form.model.$data.enabled"
                    data-testid="waiting-list-form-input-allow-quantity"
                    @input="form.errors.allow_quantity = []"
                />
            </InputField>
        </OtFormRow>
        <OtFormRow
            v-if="flags['app__waiting_list__reseller']"
        >
            <InputField
                :label="$t('dashboard.waiting_list.settings.allow_reseller_ticketswap.title')"
                :description="translateOrUndefined('dashboard.waiting_list.settings.allow_reseller_ticketswap.description')"
                :required="form.rules.allow_quantity && form.rules.allow_quantity.includes('required')"
                :optional="form.rules.allow_quantity && form.rules.allow_quantity.includes('optional')"
                :error="form.errors.allow_reseller_ticketswap"
            >
                <OtInput
                    v-model="form.model.$data.allow_reseller_ticketswap"
                    :label="$t('dashboard.waiting_list.settings.allow_reseller_ticketswap.label')"
                    class="input"
                    type="toggle"
                    :disabled="!form.model.$data.enabled"
                    data-testid="waiting-list-form-input-allow-reseller-ticketswap"
                    @input="form.errors.allow_reseller_ticketswap = []"
                />
            </InputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import { computed } from 'vue';
import InputField from '../../../../components/form/InputField.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import type { WaitingList } from '../../../../services/waiting-list/client/models';
import type { WaitingListClient } from '../../../../services/waiting-list/client/waitingListClient';
import { injectOrFail } from '../../../../services/util';
import type { FeatureFlagPlugin } from '../../../../plugins/featureFlag.plugin';
import { useLocalization } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<WaitingList<WaitingListClient>, WaitingListClient>>
}

defineProps<Props>();

const { flags } = injectOrFail<FeatureFlagPlugin>('featureFlags');

const { t, te } = useLocalization();

const typeOptions = computed<Record<string, string>>(() => ({
    one_or_more_tickets_sold_out: t('dashboard.waiting_list.settings.type.options.one_or_more_tickets_sold_out'),
    all_tickets_sold_out: t('dashboard.waiting_list.settings.type.options.all_tickets_sold_out'),
    show_always: t('dashboard.waiting_list.settings.type.options.show_always'),
}));

const translateOrUndefined = (slug: string): string | undefined => (te(slug) ? t(slug) : undefined);
</script>

<style lang="scss" scoped>
.waiting-list-settings-form {
    &__activation, &__settings {
        margin-bottom: var(--ot-spacing-default);
    }
    &__seperator {
        margin: var(--ot-spacing-2xl) 0;
    }
}
</style>
