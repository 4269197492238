<template>
    <div
        v-if="!error"
        class="module__companies__members__invite"
    >
        <div class="module__companies__members__invite__content">
            <h3>{{ $t('dashboard.company.members.invite.title_pending_invites') }}</h3>

            <div>
                <OtSpinner v-if="!invitations || invitations.isLoading" />

                <OtDataGrid
                    v-else
                    :pagination="invitations"
                    type="table"
                    :columns="columns"
                    :empty-title="$t('dashboard.company.members.invites.empty.title')"
                    :empty-message="$t('dashboard.company.members.invites.empty.message')"
                >
                    <template #column__-data__roles="{ value }">
                        {{ $t(value.includes('Company Admin')
                            ? 'dashboard.members.role.manager'
                            : 'dashboard.members.role.view_only') }}
                    </template>

                    <template #column__-data__created_at="{ value }">
                        {{ $l.dateTimeLong(value) }}
                    </template>

                    <template #actions="{ record }">
                        <button
                            class="is-small is-dark"
                            type="button"
                            :title="$t('dashboard.company.members.invite.revoke.title')"
                            @click="revokeInvitation(record)"
                        >
                            <OtIcon
                                type="close"
                                size="small"
                                class="ot-button-icon--left"
                            />
                            {{ $t('dashboard.company.members.invite.revoke.text') }}
                        </button>
                    </template>
                </OtDataGrid>
            </div>
        </div>

        <OtPageFooter>
            <template #left>
                <button
                    class="ot-button is-dark"
                    type="button"
                    :title="$t('dashboard.common.action.back.title')"
                    @click="back()"
                >
                    <OtIcon
                        type="arrow-left"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.common.action.back.text') }}
                </button>
            </template>
        </OtPageFooter>
    </div>

    <ErrorView
        v-else-if="error"
        :label="error.message"
    />

    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { Invitee, AuthClient } from '@openticket/lib-auth';
import type { Pagination } from '@openticket/lib-crud';
import { OtPageFooter, type DialogController, type Column } from '@openticket/vue-dashboard-components';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import type VueNotifications from '@openticket/vue-notifications';
import ErrorView from '../../../../components/ErrorView.vue';
import { injectOrFail } from '../../../../services/util';
import { useGenericErrorHandling, useLocalization } from '../../../../composables';

const route = useRoute();
const router = useRouter();
const { error, handleError } = useGenericErrorHandling();
const { t } = useLocalization();

const auth = injectOrFail<AuthClient>('auth');
const dialog = injectOrFail<DialogController>('dialog');
const notifications = injectOrFail<VueNotifications>('notifications');

const invitations = ref<Pagination<Invitee<AuthClient>>>();

const columns: Column[] = [
    {
        key: [ '$data', 'email' ],
        type: 'email',
        label: 'E-mail',
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'roles' ],
        type: 'string',
        label: 'Role',
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'created_at' ],
        type: 'string',
        label: 'Invite Sent',
        simpleTileColumnSize: 'default',
    },
];

void (async () => {
    if (auth) {
        try {
            invitations.value = auth.invitees.list();
            await invitations.value.initialization;
        } catch (e) {
            void handleError(e);
        }
    }
})();

async function revokeInvitation(invitation: Invitee<AuthClient>): Promise<void> {
    if (!auth) {
        // TODO Proper error + logging
        console.error('Auth client not initialized yet!');

        // TODO @Peter save.fail is somewhat weird here...
        notifications.danger(t('dashboard.common.notification.save.fail'));

        throw new Error('Auth client not initialized yet!');
    }

    const confirm = await dialog?.confirm({
        title: t('dashboard.common.confirm.permanent.title'),
        description: t('dashboard.common.confirm.permanent.description'),
        type: 'is-danger',
    });

    if (!confirm) {
        return;
    }

    try {
        await auth.invitees.delete(invitation);

        notifications.success(
            t(
                'dashboard.company.members.invite.deletion.success',
                { email: invitation.$data.email },
            ),
        );
    } catch (e) {
        // TODO Logging

        notifications.warning(
            t(
                'dashboard.company.members.invite.deletion.warning',
                { email: invitation.$data.email },
            ),
        );

        throw e;
    }

    if (invitations.value) {
        await invitations.value.loadPage();
    }
}

function back() {
    void router.push({
        name: 'companies.edit.members',
        params: {
            company: route.params.company,
        },
    });
}
</script>

<style lang="scss" scoped>
.module__companies__members__invite {
    &__content {
        margin-bottom: var(--ot-spacing-xl);

        h3 {
            margin-bottom: var(--ot-spacing-unit);
        }
    }
}
</style>
