<template>
    <OtDialogModal
        ref="modalRef"
        class="add-event-to-shop-modal"
        @close="close"
    >
        <OtCard>
            <OtCardHeader
                :title="$t('dashboard.shop_tickets.add_event_modal.header.title')"
                :subtitle="$t('dashboard.shop_tickets.add_event_modal.header.subtitle')"
            />

            <OtCardContent>
                <div
                    v-if="!loading"
                    class="ot-mb[md]"
                >
                    <OtFormRow v-if="events">
                        <InputField
                            :label="$t('dashboard.shop_tickets.add_event_modal.inputs.event.label')"
                            :description="$t('dashboard.shop_tickets.add_event_modal.inputs.event.description')"
                        >
                            <OtInputSelect
                                v-model="selectedEvent"
                                class="input"
                                sort="alphabetically"
                                :options="eventsFiltered"
                                searchable
                                @search-change="handleSearchEvents"
                            >
                                <template #singleLabel="{ option }">
                                    {{ events[option].name }}
                                </template>

                                <template #option="{ option }">
                                    <div class="locations-input__list__item">
                                        <div class="locations-input__list__item__text">
                                            <span class="locations-input__list__item__text__name">
                                                {{ events[option].name }}
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </OtInputSelect>
                        </InputField>
                    </OtFormRow>

                    <OtFormRow v-if="events && selectedEvent && tickets">
                        <InputField
                            :label="$t('dashboard.shop_tickets.add_event_modal.inputs.tickets.label')"
                            :description="$t('dashboard.shop_tickets.add_event_modal.inputs.tickets.description')"
                        >
                            <OtSpinner v-if="ticketsLoading" />

                            <OtInputSelect
                                v-else
                                v-model="selectedTickets"
                                class="input"
                                sort="alphabetically"
                                :options="ticketsFiltered"
                                searchable
                                multiple
                                @search-change="handleSearchTickets"
                            >
                                <template #singleLabel="{ option }">
                                    {{ tickets[option].name }}
                                </template>

                                <template #option="{ option }">
                                    <div class="locations-input__list__item">
                                        <div class="locations-input__list__item__text">
                                            <span class="locations-input__list__item__text__name">
                                                {{ tickets[option].name }}
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </OtInputSelect>
                        </InputField>
                    </OtFormRow>
                </div>

                <OtSpinner v-else />
            </OtCardContent>

            <OtCardFooter>
                <template #left>
                    <button
                        class="ot-button is-dark"
                        :title="$t('dashboard.common.action.cancel.title')"
                        @click="close"
                    >
                        <OtIcon
                            type="close"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.cancel.text') }}
                    </button>
                </template>

                <template #right>
                    <button
                        class="ot-button"
                        :title="$t('dashboard.common.action.save.title')"
                        :disabled="saveDisabled"
                        @click="save"
                    >
                        <OtIcon
                            type="check"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.save.text') }}
                    </button>
                </template>
            </OtCardFooter>
        </OtCard>
    </OtDialogModal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { OtDialogModal, OtSpinner } from '@openticket/vue-dashboard-components';
import type {
    ManagementClient, EventData, TicketData,
} from '@openticket/lib-management';
import InputField from '../../../../components/form/InputField.vue';
import { injectOrFail } from '../../../../services/util';
import { useGenericErrorHandling } from '../../../../composables';
import type { Context } from '../../../../services/context';

type Emits = {
    (e: 'saved', ticketGuids: string[]): void
}

const management = injectOrFail<ManagementClient>('management');
const context = injectOrFail<Context>('context');

const emit = defineEmits<Emits>();

const { handleError } = useGenericErrorHandling();

const modalRef = ref<InstanceType<typeof OtDialogModal> | null>(null);

const events = ref<Record<string, EventData>>({});
const eventsFiltered = ref<Record<string, EventData>>({});
const selectedEvent = ref<string | null>(null);

const tickets = ref<Record<string, TicketData>>({});
const ticketsFiltered = ref<Record<string, TicketData>>({});
const selectedTickets = ref<string[] | null>();

const loading = ref(0);
const ticketsLoading = ref(0);

const saveDisabled = computed(() => !selectedEvent.value
    || (selectedTickets.value && selectedTickets.value.length === 0)
    || Boolean(loading.value)
    || Boolean(ticketsLoading.value));

watch(selectedEvent, () => {
    selectedTickets.value = [];
    void fetchLinkedTickets();
});

function save() {
    if (selectedEvent.value && selectedTickets.value && selectedTickets.value.length > 0) {
        emit('saved', selectedTickets.value);
        close();
    }
}

function open() {
    if (modalRef.value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value.show();
        void loadEvents();
    }
}

function close() {
    if (modalRef.value) {
        selectedTickets.value = null;
        selectedEvent.value = null;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value.close();
    }
}

async function loadEvents() {
    try {
        loading.value++;
        if (!context.isCompanyContext()) {
            return;
        }

        const companyEvents = context.company.model.events.list(100);
        await companyEvents.initialization;

        // Format to inputselect values
        // TODO Add pagination
        events.value = companyEvents.records.reduce((result: Record<string, EventData>, obj) => {
            if (obj.$data.guid) {
                result[obj.$data.guid] = obj.$data;
            }
            return result;
        }, {});

        eventsFiltered.value = events.value;
    } catch (e) {
        void handleError(e);
    } finally {
        loading.value--;
    }
}

async function fetchLinkedTickets() {
    if (!selectedEvent.value) {
        return;
    }

    try {
        ticketsLoading.value++;
        const event = await management.events.find(selectedEvent.value);
        const eventTickets = event.tickets.list(100);
        await eventTickets.initialization;

        // Format to inputselect values
        // TODO Add pagination
        tickets.value = eventTickets.records.reduce((result: Record<string, TicketData>, obj) => {
            if (obj.$data.guid) {
                result[obj.$data.guid] = obj.$data;
            }
            return result;
        }, {});

        ticketsFiltered.value = tickets.value;
    } catch (e) {
        void handleError(e);
    } finally {
        ticketsLoading.value--;
    }
}

function handleSearchEvents(query: string) {
    eventsFiltered.value = Object.keys(events.value)
        .filter((key) => {
            const event = events.value[key];
            return event.name.toUpperCase().includes(query.toUpperCase());
        })
        .reduce((res: Record<string, EventData>, key) => {
            res[key] = events.value[key];
            return res;
        }, {});
}

function handleSearchTickets(query: string) {
    ticketsFiltered.value = Object.keys(tickets.value)
        .filter((key) => {
            const ticket = tickets.value[key];
            return ticket.name.toUpperCase().includes(query.toUpperCase());
        })
        .reduce((res: Record<string, TicketData>, key) => {
            res[key] = tickets.value[key];
            return res;
        }, {});
}

defineExpose({
    open,
});
</script>

<style lang="scss" scoped>
.add-event-to-shop-modal {
    min-width: 600px;
    overflow: visible;

    @media (max-width: 40rem) {
        min-width: unset;
        width: 100% !important;
    }

    &::v-deep {
        .card {
            overflow: visible !important;
        }

        .card-footer {
            border-radius: 0 0 var(--ot-input-radius) var(--ot-input-radius);
        }
    }
}
</style>
