<template>
    <div class="module__companies__list">
        <OtCardHeader
            :title="$t('dashboard.company.list.title')"
            :subtitle="$t('dashboard.company.list.subtitle')"
        />

        <OtCardContent class="module__companies__list__content">
            <div v-if="companies && !companies.isLoading && !error">
                <div class="modules__companies__list__grid">
                    <div
                        v-for="company in companies.records"
                        :key="company.id"
                        role="button"
                        tabindex="0"
                        @click="openSettingsPage(company.id)"
                        @keydown.space="openSettingsPage(company.id)"
                        @keydown.enter="openSettingsPage(company.id)"
                    >
                        <div class="modules__companies__list__grid-item ot-clickable">
                            <div class="modules__companies__list__grid-item__content">
                                <div class="modules__companies__list__grid-item__avatar">
                                    <OtAvatar :name="company.$data.name" />
                                </div>
                                <h5 class="modules__companies__list__grid-item__name">
                                    {{ company.$data.name }}
                                </h5>
                                <div class="ot-label modules__companies__list__grid-item__website">
                                    {{ company.$data.website }}
                                </div>
                                <div class="is-tiny modules__companies__list__grid-item__label">
                                    <OtFlair
                                        v-if="managedCompanies[company.id]"
                                        :inversed="false"
                                        :centered="false"
                                        type="is-black"
                                    >
                                        {{ $t('dashboard.members.tag.manager') }}
                                    </OtFlair>
                                    <OtFlair
                                        v-else-if="!managedCompanies[company.id]"
                                        :inversed="false"
                                        :centered="false"
                                        type="is-light"
                                    >
                                        {{ $t('dashboard.members.tag.view_only') }}
                                    </OtFlair>
                                    <OtFlair
                                        v-if="company.$data.force_tfa"
                                        icon="check"
                                        :inversed="false"
                                        :centered="false"
                                        type="is-success"
                                    >
                                        {{ $t('dashboard.members.tag.tfa') }}
                                    </OtFlair>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ErrorView
                v-else-if="error"
                :label="error.message"
            />

            <OtSpinner v-else />
        </OtCardContent>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import type { AuthClient, Company } from '@openticket/lib-auth';
import { ROLE_COMPANY_ADMIN } from '@openticket/lib-auth';
import type { Pagination } from '@openticket/lib-crud';
import { useRoute, useRouter } from 'vue-router/composables';
import ErrorView from '../../../components/ErrorView.vue';
import { injectOrFail } from '../../../services/util';
import { useGenericErrorHandling } from '../../../composables';

const route = useRoute();
const router = useRouter();
const { error, handleError } = useGenericErrorHandling();

const auth = injectOrFail<AuthClient>('auth');

const companies = ref<Pagination<Company<AuthClient>> | null>(null);
const managedCompanies = ref<{ [id: string]: true }>({});

void (async (): Promise<void> => {
    try {
        if (auth.$token.$info) {
            const info: { roles: Record<string, string[]>; } = await auth.$token.$info;

            managedCompanies.value = Object.fromEntries(Object.entries(info.roles).map(([ id, roles ]):
        null | [string, true] => (roles.includes(ROLE_COMPANY_ADMIN) ? [ id, true ] : null))
                .filter((entry): entry is [string, true] => !!entry));
        }

        companies.value = auth.companies.list();
    } catch (e) {
        void handleError(e);
    }
})();

async function openSettingsPage(id: string): Promise<void> {
    await router.push({
        name: managedCompanies.value[id] ? 'companies.edit' : 'companies.edit.members',
        params: {
            ...route.params,
            company: id,
        },
    });
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.modules__companies__list {
    &__content {
        padding: var(--ot-card-padding);
        padding-top: 0;

        .ot-separator {
            margin: var(--ot-spacing-4xl) 0;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        grid-gap: 0rem 1rem;

        @include breakpoint(mob) {
            display: block;
        }
    }

    &__grid-item {
        position: relative;
        padding-top: 100%;
        transition: var(--ot-transition-default);

        &__content {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &__avatar {
            margin-bottom: 1rem;
        }

        &__name {
            flex: 0;
            margin-bottom: var(--ot-spacing-2xs);
        }

        &__website {
            margin-bottom: var(--ot-spacing-default);
        }

        &.ot-clickable:hover {
            box-shadow: var(--ot-card-shadow);
        }

        &__label {

            &:not(:last-child){
                margin-right: var(--ot-spacing-2xs);
            }

            .label-2fa {
                background-color: var(--ot-color-accent-green-dark);
                color: var(--ot-color-core-white);
                vertical-align: bottom;
            }

            .label-admin {
                background-color: var(--ot-color-core-black);
                color: var(--ot-color-core-white);
            }

            .label-view-only {
                background-color: var(--ot-color-core-light-background-secondary);
                color: var(--ot-color-core-light-foreground-secondary);
            }

            .ot-button {
                padding: var(--ot-spacing-3xs) var(--ot-spacing-xs);
                border-radius: .3rem;
            }
        }

    }
}
</style>
