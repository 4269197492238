import type VueInstance from 'vue';
import type { Route, RouteRecord } from 'vue-router';
import type {
    CompanyPath,
    Event,
    EventDate,
    ManagementClient,
    OrderExtended,
    Product,
    Shop,
    Ticket,
} from '@openticket/lib-management';
import { isOtError } from '@openticket/lib-log';
import type {
    AccessMomentContext,
    AddonProductContext,
    CompanyContext,
    ContextChangeHook,
    ContextParams,
    ContextType,
    ContextTypeArgs,
    ContextTypesArgs,
    ContextWithAccessMoment,
    ContextWithAddonProduct,
    ContextWithCompany,
    ContextWithDate,
    ContextWithEvent,
    ContextWithGlobal,
    ContextWithOrder,
    ContextWithShop,
    ContextWithTicket,
    DateContext,
    EventContext,
    GlobalContext,
    OrderContext,
    RouteValue,
    ShopContext,
    TicketContext,
    Value,
    WaitingListContext,
} from './types';
import { isContextModel } from './guards';
import type { PluginsManager } from '../../plugins';
import {
    ContextCompanyError,
    ContextDateError,
    ContextEventError,
    ContextInvalidError,
    ContextOrderError,
    ContextParentInvalidError,
    ContextPluginsUndefinedError,
    ContextRouteValueKeyReservedError,
    ContextShopError,
    ContextTicketError,
    ContextAddonProductError,
    ContextAccessMomentError,
    ContextWaitingListError,
} from './errors';
import type { WaitingListClient } from '../waiting-list/client/waitingListClient';
import type { WaitingList } from '../waiting-list/client/models';

const LOADING_TIMEOUT_DELAY_MS = 500;

export class Context {

    context: GlobalContext | null = null;
    plugins: PluginsManager | null = null;
    routeValues: RouteValue[] = [];
    changeHooks: ContextChangeHook[] = [];

    private loadingCounter: number = 0;
    private loadingTimerId: ReturnType<typeof setTimeout> | null = null;

    constructor(plugins: PluginsManager) {
        this.plugins = plugins;
    }

    get accessMoment(): AccessMomentContext | null {
        return this.context?.company?.event?.accessMoment || null;
    }

    get addonProduct(): AddonProductContext | null {
        return this.context?.company?.event?.addonProduct || null;
    }

    get waitingList(): WaitingListContext | null {
        return this.context?.company?.event?.waitingList || null;
    }

    get company(): CompanyContext | null {
        return this.context?.company || null;
    }

    get event(): EventContext | null {
        return this.context?.company?.event || null;
    }

    get ticket(): TicketContext | null {
        return this.context?.company?.event?.ticket || null;
    }

    /**
     * @deprecated replace with access moments
     */
    get date(): DateContext | null {
        return this.context?.company?.event?.date || null;
    }

    get shop(): ShopContext | null {
        return this.context?.company?.shop || null;
    }

    get order(): OrderContext | null {
        return this.context?.order || null;
    }

    get type(): ContextType {
        if (!this.context) {
            return 'global';
        }

        if (this.context.company) {
            if (this.context.company.event) {
                if (this.context.company.event.ticket) {
                    return 'ticket';
                }

                if (this.context.company.event.accessMoment) {
                    return 'accessMoment';
                }

                if (this.context.company.event.addonProduct) {
                    return 'addonProduct';
                }

                if (this.context.company.event.date) {
                    return 'date';
                }

                if (this.context.company.event.waitingList) {
                    return 'waitingList';
                }

                return 'event';
            }

            if (this.context.company.shop) {
                return 'shop';
            }

            return 'company';
        }

        if (this.context.order) {
            return 'order';
        }

        return 'global';
    }

    get contextValues(): Value[] {
        const values: Value[] = [];

        if (this.context) {
            if (this.context.company) {
                values.push({
                    record: null,
                    key: 'company',
                    value: this.context.company.name,
                });

                if (this.context.company.event) {
                    values.push({
                        record: null,
                        key: 'event',
                        value: this.context.company.event.name,
                    });

                    if (this.context.company.event.ticket) {
                        values.push({
                            record: null,
                            key: 'ticket',
                            value: this.context.company.event.ticket.name,
                        });
                    }

                    if (this.context.company.event.waitingList) {
                        values.push({
                            record: null,
                            key: 'waiting-list',
                            value: this.context.company.event.waitingList.name,
                        });
                    }

                    if (this.context.company.event.accessMoment) {
                        values.push({
                            record: null,
                            key: 'access-moment',
                            value: this.context.company.event.accessMoment.name,
                        });
                    }

                    if (this.context.company.event.addonProduct) {
                        values.push({
                            record: null,
                            key: 'addon-product',
                            value: this.context.company.event.addonProduct.name,
                        });
                    }

                    if (this.context.company.event.date) {
                        values.push({
                            record: null,
                            key: 'date',
                            value: this.context.company.event.date.name,
                        });
                    }
                }

                if (this.context.company.shop) {
                    values.push({
                        record: null,
                        key: 'shop',
                        value: this.context.company.shop.name,
                    });
                }
            }

            if (this.context.order) {
                values.push({
                    record: null,
                    key: 'order',
                    value: this.context.order.name,
                });
            }
        }

        return values;
    }

    get values(): Record<string, string> {
        return Object.fromEntries([
            ...this.routeValues,
            ...this.contextValues,
        ].map((value: Value) => [ value.key, value.value ]));
    }

    get loading(): boolean {
        return this.loadingCounter > 0;
    }

    async beforeRouteChange(to: Route): Promise<void> {
        this.routeValues = this.routeValues
            .filter((value: Value) => to.matched.some((record: RouteRecord) => record === value.record));

        const newContext: ContextType = this.extractContext(to);

        // Route params are casted as present here, actual presence is detected later in the _setXYZContext methods
        await this.updateContext(newContext, to.params as Required<ContextParams>);
    }

    extractContext(to: Route): ContextType {
        for (const routeRecord of to.matched.slice().reverse()) {
            const { contextIfSet } = routeRecord.meta;
            if (contextIfSet && to.params[contextIfSet]) {
                return contextIfSet;
            }

            if (routeRecord.meta && routeRecord.meta.context && typeof routeRecord.meta.context === 'string') {
                return routeRecord.meta.context;
            }
        }

        return 'global';
    }

    async updateContext(...[ context, paramsOrModel ]: ContextTypesArgs): Promise<void> {
        const oldContext = this.context;
        this._startDeferredLoading();

        try {
            switch (context) {
                case 'global':
                    await this._removeCompanyScope();
                    this.removeContext();
                    break;
                case 'company':
                    await this._setCompanyContext(paramsOrModel);
                    break;
                case 'event':
                    await this._setEventContext(paramsOrModel);
                    break;
                case 'ticket':
                    await this._setTicketContext(paramsOrModel);
                    break;
                case 'date':
                    await this._setDateContext(paramsOrModel);
                    break;
                case 'accessMoment':
                    await this._setAccessMomentContext(paramsOrModel);
                    break;
                case 'addonProduct':
                    await this._setAddonProductContext(paramsOrModel);
                    break;
                case 'waitingList':
                    await this._setWaitingListContext(paramsOrModel);
                    break;
                case 'shop':
                    await this._setShopContext(paramsOrModel);
                    break;
                case 'order':
                    await this._setOrderContext(paramsOrModel);
                    break;
                default:
                    await this._removeCompanyScope();
                    this.removeContext();
                    throw new ContextInvalidError(context);
            }
        } finally {
            this._stopLoading();
        }

        for (const hook of this.changeHooks) {
            await hook(oldContext, this.context);
        }
    }

    removeContext(): void {
        this.context = null;
    }

    private async _removeCompanyScope() {
        if (!this.plugins) {
            return this._setCompanyScope(null);
        }

        // DD-DASHBOARD-2721 & CU-86bxnhczr
        // TODO This is a temporary solution to improve the usability of the dashboard.
        // Set companyscope to available user companies when the view is in the global context.
        // Company request header has to be separated by a comma (,) to be readable by the api.
        const auth = await this.plugins.auth.loading;
        const info = await auth.$token.$info;
        const tokenCompanies = info?.companies.map((company) => company.guid).join(',') ?? '';

        return this._setCompanyScope(tokenCompanies !== '' ? tokenCompanies : null);
    }

    private async _setCompanyScope(companyScope: string | null) {
        if (!this.plugins) {
            return;
        }

        (await this.plugins.management.loading).setCompanyScope(companyScope);
        (await this.plugins.auth.loading).setCompanyScope(companyScope);
    }

    isGlobalContext(): this is Context & ContextWithGlobal {
        return !this.context;
    }

    isAccessMomentContext(): this is Context & ContextWithAccessMoment {
        return !!this.context?.company?.event?.accessMoment;
    }

    isAddonProductContext(): this is Context & ContextWithAddonProduct {
        return !!this.context?.company?.event?.addonProduct;
    }

    // Note: This checks if the company context exist, but doesn't check if e.g. the event context does not exist.
    isCompanyContext(): this is Context & ContextWithCompany {
        return !!this.context?.company;
    }

    isEventContext(): this is Context & ContextWithEvent {
        return !!this.context?.company?.event;
    }

    isTicketContext(): this is Context & ContextWithTicket {
        return !!this.context?.company?.event?.ticket;
    }

    /**
     * @deprecated replace with access moments
     */
    isDateContext(): this is Context & ContextWithDate {
        return !!this.context?.company?.event?.date;
    }

    isShopContext(): this is Context & ContextWithShop {
        return !!this.context?.company?.shop;
    }

    isOrderContext(): this is Context & ContextWithOrder {
        return !!this.context?.order;
    }

    setRouteValue(component: VueInstance, key: string, value: string): void {
        if ([ 'company', 'event', 'ticket', 'date', 'shop', 'order' ].includes(key)) {
            throw new ContextRouteValueKeyReservedError(key);
        }

        try {
            // Title replacement values are set for the current matched route record's default instance.
            // When changing to other routes, parts of this chain can be kept
            // (i.e. (parent) route records shared between both the from and to routes).
            // The update call will filter the value records.
            const record: RouteRecord | undefined = component.$route.matched
                .find((routeRecord: RouteRecord) => routeRecord.instances
                    && routeRecord.instances.default
                    && routeRecord.instances.default === component);

            if (!record) {
                return;
            }

            // For each key, only one value can exist, independent of route record they belong to.
            const val: Value | undefined = this.routeValues.find((existing: Value) => existing.key === key);

            if (val) {
                val.record = record;
                val.value = value;
            } else {
                this.routeValues.push({
                    record,
                    key,
                    value,
                });
            }

            // TODO should updates be triggered??? -> Document title, sidebar??
        } catch (e: unknown) {
            console.warn('Failed to set document title value', e); // TODO log proper error

            throw e;
        }
    }

    onChange(hook: ContextChangeHook): () => void {
        const cb: ContextChangeHook = async (oldContext, newContext): Promise<void> => {
            try {
                await hook.call(null, oldContext, newContext);
            } catch (_) { /* No-op */ }
        };

        this.changeHooks.push(cb);

        return () => {
            const i: number = this.changeHooks.findIndex((value: ContextChangeHook) => value === cb);

            if (i >= 0) {
                this.changeHooks.splice(i, 1);
            }
        };
    }

    private async _setAccessMomentContext(paramsOrModel: ContextTypeArgs<'accessMoment'>[1]): Promise<void> {
        let accessMomentModel: EventDate<ManagementClient>;
        let event: string | undefined;

        try {
            if (isContextModel(paramsOrModel)) {
                accessMomentModel = paramsOrModel;
                event = accessMomentModel.$data.event_id;
            } else {
                const { accessMoment } = paramsOrModel;
                event = paramsOrModel.event;

                if (!accessMoment) {
                    throw new ContextAccessMomentError();
                }

                accessMomentModel = await this._getAccessMomentModel(accessMoment);
            }

            if (!event) {
                throw new ContextAccessMomentError();
            }

            if (accessMomentModel.$data.event_id !== event) {
                throw new ContextParentInvalidError('event', accessMomentModel.$data.event_id, event);
            }

            const eventModel = await this._getEventModel(event);

            if (accessMomentModel.$data.event_id !== eventModel.id) {
                throw new ContextParentInvalidError('event', accessMomentModel.$data.event_id, eventModel.id);
            }

            // As an access moments model does not have a company id, the access moment model is used
            const companyModel = await this._getCompanyModel(eventModel.$data.company_id);

            if (eventModel.$data.company_id !== companyModel.id) {
                throw new ContextParentInvalidError('company', eventModel.$data.company_id, companyModel.id);
            }

            this.context = {
                company: {
                    id: companyModel.id,
                    model: companyModel,
                    name: companyModel.$data.name,
                    event: {
                        id: event,
                        model: eventModel,
                        name: eventModel.$data.name,
                        accessMoment: {
                            id: accessMomentModel.$data.guid,
                            model: accessMomentModel,
                            name: accessMomentModel.$data.name || '',
                        },
                    },
                },
            };
        } catch (e) {
            if (isOtError(e)) {
                throw (new ContextAccessMomentError()).causedBy(e);
            }

            throw e;
        }
    }

    private async _setAddonProductContext(paramsOrModel: ContextTypeArgs<'addonProduct'>[1]): Promise<void> {
        let addonProductModel: Product<ManagementClient>;
        let eventId: string | undefined;

        try {
            if (isContextModel(paramsOrModel)) {
                addonProductModel = paramsOrModel;
                eventId = addonProductModel.$data.event_id;
            } else {
                const { addonProduct } = paramsOrModel;
                eventId = paramsOrModel.event;

                if (!addonProduct) {
                    throw new ContextAddonProductError();
                }

                addonProductModel = await this._getAddonProduct(addonProduct);
            }

            if (!eventId) {
                throw new ContextAddonProductError();
            }

            if (addonProductModel.$data.event_id !== eventId) {
                throw new ContextParentInvalidError('event', addonProductModel.$data.event_id, eventId);
            }

            const eventModel = await this._getEventModel(eventId);

            if (addonProductModel.$data.event_id !== eventModel.id) {
                throw new ContextParentInvalidError('event', addonProductModel.$data.event_id, eventModel.id);
            }

            // As an addon products model does not have a company id, the addon products model is used
            const companyModel = await this._getCompanyModel(eventModel.$data.company_id);

            if (eventModel.$data.company_id !== companyModel.id) {
                throw new ContextParentInvalidError('company', eventModel.$data.company_id, companyModel.id);
            }

            this.context = {
                company: {
                    id: companyModel.id,
                    model: companyModel,
                    name: companyModel.$data.name,
                    event: {
                        id: eventId,
                        model: eventModel,
                        name: eventModel.$data.name,
                        addonProduct: {
                            id: addonProductModel.$data.guid,
                            model: addonProductModel,
                            name: addonProductModel.$data.name || '',
                        },
                    },
                },
            };
        } catch (e) {
            if (isOtError(e)) {
                throw (new ContextAddonProductError()).causedBy(e);
            }

            throw e;
        }
    }

    private async _setWaitingListContext(paramsOrModel: ContextTypeArgs<'waitingList'>[1]): Promise<void> {
        let waitingListModel: WaitingList<WaitingListClient>;
        let event: string | undefined;

        try {
            if (isContextModel(paramsOrModel)) {
                waitingListModel = paramsOrModel;
                event = waitingListModel.$data.event_id;
            } else {
                const { waitingList } = paramsOrModel;
                event = paramsOrModel.event;

                if (!waitingList) {
                    throw new ContextWaitingListError();
                }

                waitingListModel = await this._getWaitingListModel(waitingList);
            }

            if (!event) {
                throw new ContextWaitingListError();
            }

            if (waitingListModel.$data.event_id !== event) {
                throw new ContextParentInvalidError('event', waitingListModel.$data.event_id, event);
            }

            const eventModel = await this._getEventModel(event);

            if (waitingListModel.$data.event_id !== eventModel.id) {
                throw new ContextParentInvalidError('event', waitingListModel.$data.event_id, eventModel.id);
            }

            // As waiting list model does not have a company id, the event moment model is used
            const companyModel = await this._getCompanyModel(eventModel.$data.company_id);

            if (eventModel.$data.company_id !== companyModel.id) {
                throw new ContextParentInvalidError('company', eventModel.$data.company_id, companyModel.id);
            }

            this.context = {
                company: {
                    id: companyModel.id,
                    model: companyModel,
                    name: companyModel.$data.name,
                    event: {
                        id: event,
                        model: eventModel,
                        name: eventModel.$data.name,
                        waitingList: {
                            id: waitingListModel.$data.guid,
                            model: waitingListModel,
                            name: waitingListModel.$data.guid || '',
                        },
                    },
                },
            };
        } catch (e) {
            if (isOtError(e)) {
                throw (new ContextWaitingListError()).causedBy(e);
            }

            throw e;
        }
    }

    private async _setCompanyContext(paramsOrModel: ContextTypeArgs<'company'>[1]): Promise<void> {
        let companyModel: CompanyPath<ManagementClient>;

        try {
            if (isContextModel(paramsOrModel)) {
                companyModel = paramsOrModel;
            } else {
                const { company } = paramsOrModel;

                if (!company) {
                    throw new ContextCompanyError();
                }

                companyModel = await this._getCompanyModel(company);
            }

            this.context = {
                company: {
                    id: companyModel.id || '',
                    model: companyModel,
                    name: companyModel.$data.name,
                },
            };
        } catch (e) {
            if (isOtError(e)) {
                throw (new ContextCompanyError()).causedBy(e);
            }

            throw e;
        }
    }

    private async _setEventContext(paramsOrModel: ContextTypeArgs<'event'>[1]): Promise<void> {
        let eventModel: Event<ManagementClient>;

        try {
            if (isContextModel(paramsOrModel)) {
                eventModel = paramsOrModel;
            } else {
                const { event } = paramsOrModel;

                if (!event) {
                    throw new ContextEventError();
                }

                eventModel = await this._getEventModel(event);
            }

            const companyModel = await this._getCompanyModel(eventModel.$data.company_id);

            if (eventModel.$data.company_id !== companyModel.id) {
                throw new ContextParentInvalidError('company', eventModel.$data.company_id, companyModel.id);
            }

            this.context = {
                company: {
                    id: companyModel.id,
                    model: companyModel,
                    name: companyModel.$data.name,
                    event: {
                        id: eventModel.$data.guid,
                        model: eventModel,
                        name: eventModel.$data.name,
                    },
                },
            };
        } catch (e) {
            if (isOtError(e)) {
                throw (new ContextEventError()).causedBy(e);
            }

            throw e;
        }
    }

    private async _setTicketContext(paramsOrModel: ContextTypeArgs<'ticket'>[1]): Promise<void> {
        let ticketModel: Ticket<ManagementClient>;
        let event: string | undefined;

        try {
            if (isContextModel(paramsOrModel)) {
                ticketModel = paramsOrModel;
                event = ticketModel.$data.event_id;
            } else {
                const { ticket } = paramsOrModel;
                event = paramsOrModel.event;

                if (!ticket) {
                    throw new ContextTicketError();
                }

                ticketModel = await this._getTicketModel(ticket);
            }

            if (!event) {
                throw new ContextTicketError();
            }

            if (ticketModel.$data.event_id !== event) {
                throw new ContextParentInvalidError('event', ticketModel.$data.event_id, event);
            }

            const eventModel = await this._getEventModel(event);

            if (ticketModel.$data.event_id !== eventModel.id) {
                throw new ContextParentInvalidError('event', ticketModel.$data.event_id, eventModel.id);
            }

            // DD-DASHBOARD-2720
            if (ticketModel.$data.company_id !== eventModel.$data.company_id) {
                throw new ContextParentInvalidError('company', ticketModel.$data.company_id, eventModel.$data.company_id);
            }

            const companyModel = await this._getCompanyModel(ticketModel.$data.company_id);

            if (ticketModel.$data.company_id !== companyModel.id) {
                throw new ContextParentInvalidError('company', ticketModel.$data.company_id, companyModel.id);
            }

            this.context = {
                company: {
                    id: companyModel.id,
                    model: companyModel,
                    name: companyModel.$data.name,
                    event: {
                        id: event,
                        model: eventModel,
                        name: eventModel.$data.name,
                        ticket: {
                            id: ticketModel.$data.guid,
                            model: ticketModel,
                            name: ticketModel.$data.name,
                        },
                    },
                },
            };
        } catch (e) {
            if (isOtError(e)) {
                throw (new ContextTicketError()).causedBy(e);
            }

            throw e;
        }
    }

    /**
     * @deprecated replace with access moments
     */
    private async _setDateContext(paramsOrModel: ContextTypeArgs<'date'>[1]): Promise<void> {
        let dateModel: EventDate<ManagementClient>;
        let event: string | undefined;

        try {
            if (isContextModel(paramsOrModel)) {
                dateModel = paramsOrModel;
                event = dateModel.$data.event_id;
            } else {
                const { date } = paramsOrModel;
                event = paramsOrModel.event;

                if (!date) {
                    throw new ContextDateError();
                }

                dateModel = await this._getDateModel(date);
            }

            if (!event) {
                throw new ContextDateError();
            }

            if (dateModel.$data.event_id !== event) {
                throw new ContextParentInvalidError('event', dateModel.$data.event_id, event);
            }

            const eventModel = await this._getEventModel(event);

            if (dateModel.$data.event_id !== eventModel.id) {
                throw new ContextParentInvalidError('event', dateModel.$data.event_id, eventModel.id);
            }

            // As an EventDate model does not have a company id, the Event model is used
            const companyModel = await this._getCompanyModel(eventModel.$data.company_id);

            if (eventModel.$data.company_id !== companyModel.id) {
                throw new ContextParentInvalidError('company', eventModel.$data.company_id, companyModel.id);
            }

            this.context = {
                company: {
                    id: companyModel.id,
                    model: companyModel,
                    name: companyModel.$data.name,
                    event: {
                        id: event,
                        model: eventModel,
                        name: eventModel.$data.name,
                        date: {
                            id: dateModel.$data.guid,
                            model: dateModel,
                            name: dateModel.$data.name || '',
                        },
                    },
                },
            };
        } catch (e) {
            if (isOtError(e)) {
                throw (new ContextDateError()).causedBy(e);
            }

            throw e;
        }
    }

    private async _setShopContext(paramsOrModel: ContextTypeArgs<'shop'>[1]): Promise<void> {
        let shopModel: Shop<ManagementClient>;

        try {
            if (isContextModel(paramsOrModel)) {
                shopModel = paramsOrModel;
            } else {
                const { shop } = paramsOrModel;

                if (!shop) {
                    throw new ContextShopError();
                }

                shopModel = await this._getShopModel(shop);
            }

            const companyModel = await this._getCompanyModel(shopModel.$data.company_id);

            if (shopModel.$data.company_id !== companyModel.id) {
                throw new ContextParentInvalidError('company', shopModel.$data.company_id, companyModel.id);
            }

            this.context = {
                company: {
                    id: companyModel.id,
                    model: companyModel,
                    name: companyModel.$data.name,
                    shop: {
                        id: shopModel.$data.guid,
                        model: shopModel,
                        name: shopModel.$data.name || '',
                    },
                },
            };
        } catch (e) {
            if (isOtError(e)) {
                throw (new ContextShopError()).causedBy(e);
            }

            throw e;
        }
    }

    private async _setOrderContext(paramsOrModel: ContextTypeArgs<'order'>[1]): Promise<void> {
        let orderModel: OrderExtended<ManagementClient>;

        try {
            if (isContextModel(paramsOrModel)) {
                orderModel = paramsOrModel;
            } else {
                const { order } = paramsOrModel;
                if (!order) {
                    throw new ContextOrderError();
                }

                orderModel = await this._getOrderModel(order);
            }

            // Do not forget the company context when the user views an order and the company id is set.
            let company = {};

            if ('company' in paramsOrModel && typeof paramsOrModel.company === 'string' && paramsOrModel.company) {
                const companyModel = await this._getCompanyModel(paramsOrModel.company);
                company = {
                    company: {
                        id: companyModel.id || '',
                        model: companyModel,
                        name: companyModel.$data.name,
                    },
                };
            }

            this.context = {
                order: {
                    id: orderModel.$data.guid || '',
                    model: orderModel,
                    name: `${orderModel.$data.firstName} ${orderModel.$data.lastName}` || '',
                },
                ...company,
            };
        } catch (e) {
            if (isOtError(e)) {
                throw (new ContextOrderError()).causedBy(e);
            }

            throw e;
        }
    }

    private async _getAccessMomentModel(accessMoment: string): Promise<EventDate<ManagementClient>> {
        if (!this.plugins || !this.plugins.management) {
            throw new ContextPluginsUndefinedError();
        }

        // Current stored model is same as newly requested, return already existing one
        if (this.context?.company?.event?.accessMoment?.id === accessMoment) {
            return this.context.company.event?.accessMoment.model;
        }

        const management = await this.plugins.management.loading;

        return management.eventdates.find(accessMoment);
    }

    private async _getAddonProduct(addonProduct: string): Promise<Product<ManagementClient>> {
        if (!this.plugins || !this.plugins.management) {
            throw new ContextPluginsUndefinedError();
        }

        // Current stored model is same as newly requested, return already existing one
        if (this.context?.company?.event?.addonProduct?.id === addonProduct) {
            return this.context.company.event.addonProduct.model;
        }

        const management = await this.plugins.management.loading;

        return management.products.find(addonProduct);
    }

    private async _getWaitingListModel(waitingList: string): Promise<WaitingList<WaitingListClient>> {
        if (!this.plugins || !this.plugins.waitingList) {
            throw new ContextPluginsUndefinedError();
        }

        // Current stored model is same as newly requested, return already existing one
        if (this.context?.company?.event?.waitingList?.id === waitingList) {
            return this.context.company.event?.waitingList.model;
        }

        const waitingListClient = await this.plugins.waitingList.loading;

        return waitingListClient.waitingList.find(waitingList);
    }

    private async _getCompanyModel(company?: string): Promise<CompanyPath<ManagementClient>> {
        if (!company) {
            // TODO Properly log error & localise reason.
            throw Error('No company id given');
        }

        if (!this.plugins || !this.plugins.auth) {
            throw new ContextPluginsUndefinedError();
        }

        // Current stored model is same as newly requested, return already existing one
        if (this.context?.company?.id === company) {
            return this.context.company.model;
        }

        const auth = await this.plugins.auth.loading;
        auth.setCompanyScope(company);

        const info = await auth.$token.$info;
        const userCompanies = info?.companies;

        if (!userCompanies) {
            // TODO Properly log error & localise reason.
            throw Error('User has no companies');
        }

        let foundCompany = userCompanies.find((c: {guid: string}) => c.guid === company);

        if (!foundCompany) {
            const isSuperAdmin = await auth.$token.isSuperAdmin();
            const isWhitelabelAdmin = await auth.$token.isWhitelabelAdmin();

            // If the user is a whitelabel admin, the company can be fetched directly.
            if (isSuperAdmin || isWhitelabelAdmin) {
                foundCompany = (await auth.companies.find(company)).$data;
            }
        }

        if (!foundCompany) {
            // TODO Properly log error & localise reason.
            throw Error('No matching company found');
        }

        const management = await this.plugins.management.loading;
        management.setCompanyScope(company);

        return management.companyPath.$factory({
            ...foundCompany,
            guid: company,
        });
    }

    private async _getEventModel(event: string): Promise<Event<ManagementClient>> {
        if (!this.plugins || !this.plugins.management) {
            throw new ContextPluginsUndefinedError();
        }

        // Current stored model is same as newly requested, return already existing one
        if (this.context?.company?.event?.id === event) {
            return this.context.company.event.model;
        }

        const management = await this.plugins.management.loading;

        return management.events.find(event);
    }

    private async _getTicketModel(ticket: string): Promise<Ticket<ManagementClient>> {
        if (!this.plugins || !this.plugins.management) {
            throw new ContextPluginsUndefinedError();
        }

        // Current stored model is same as newly requested, return already existing one
        if (this.context?.company?.event?.ticket?.id === ticket) {
            return this.context.company.event.ticket.model;
        }

        const management = await this.plugins.management.loading;

        return management.tickets.find(ticket);
    }

    /**
     * @deprecated replace with access moments
     */
    private async _getDateModel(date: string): Promise<EventDate<ManagementClient>> {
        if (!this.plugins || !this.plugins.management) {
            throw new ContextPluginsUndefinedError();
        }

        // Current stored model is same as newly requested, return already existing one
        if (this.context?.company?.event?.date?.id === date) {
            return this.context.company.event.date.model;
        }

        const management = await this.plugins.management.loading;

        return management.eventdates.find(date);
    }

    private async _getShopModel(shop: string): Promise<Shop<ManagementClient>> {
        if (!this.plugins || !this.plugins.management) {
            throw new ContextPluginsUndefinedError();
        }

        // Current stored model is same as newly requested, return already existing one
        if (this.context?.company?.shop?.id === shop) {
            return this.context.company.shop.model;
        }

        const management = await this.plugins.management.loading;

        return management.shops.find(shop);
    }

    private async _getOrderModel(order: string): Promise<OrderExtended<ManagementClient>> {
        if (!this.plugins || !this.plugins.management) {
            throw new ContextPluginsUndefinedError();
        }

        // Current stored model is same as newly requested, return already existing one
        if (this.context?.order?.id === order) {
            return this.context.order.model;
        }

        const management = await this.plugins.management.loading;

        return management.ordersExtended.find(order);
    }

    private _startDeferredLoading() {
        this.loadingTimerId = setTimeout(() => this.loadingCounter++, LOADING_TIMEOUT_DELAY_MS);
    }

    private _stopLoading() {
        if (this.loadingTimerId) {
            clearTimeout(this.loadingTimerId);
        }

        if (this.loadingCounter > 0) {
            this.loadingCounter--;
        }
    }

}
