import type { VueConstructor } from 'vue';
import { isOtError, send } from '@openticket/lib-log';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import { RudderStack } from '../services/rudderstack';
import type { RudderstackOptions } from './types';

export class RudderStackPlugin extends Plugin<RudderStack> {

    async install(plugins: PluginsManager, Vue: VueConstructor): Promise<void> {
        const rudderstack = new RudderStack();

        const options: RudderstackOptions = plugins.options.rudderstack || {};

        try {
            const whitelabel = await plugins.whitelabel.loading;

            const rudderstackPlaneUrl = options.rudderstackPlaneUrl || whitelabel.dashboard.rudderstack_data_plane_url;
            const rudderstackWriteKey = options.rudderstackWriteKey || whitelabel.dashboard.rudderstack_write_key;

            if (rudderstackWriteKey && rudderstackPlaneUrl) {
                rudderstack.init(
                    rudderstackWriteKey,
                    rudderstackPlaneUrl,
                );
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());

                if (isOtError(e)) {
                    send(e);
                }
            }
        } finally {
            Vue.mixin({ provide: { rudderstack } });

            this.resolve(rudderstack);
        }
    }

}
