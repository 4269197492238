<template>
    <OtInlineModal
        ref="invitationsModal"
        :width="800"
    >
        <div
            v-if="invitations"
            class="ot-card profile-invitations__modal"
        >
            <OtCardHeader
                :title="$t('dashboard.header.profile.invitations.title')"
                :right-align-actions="true"
            >
                <template #actions>
                    <button
                        class="ot-button is-dark"
                        type="button"
                        :title="$t('dashboard.common.action.close.title')"
                        @click="closeInvitations"
                    >
                        <OtIcon
                            type="close"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.close.text') }}
                    </button>
                </template>
            </OtCardHeader>

            <OtCardContent class="profile-invitations__modal__content">
                <img
                    v-if="invitations.isLoading"
                    class="profile-invitations__modal__loading"
                    :src="$whitelabel.resources.spinner"
                    alt="loading..."
                >

                <InvitationsList
                    v-else
                    :invitations="pageValues"
                    :show-redirect-to-fix-page="false"
                    :two-factor-enabled="tfaEnabled"
                />
            </OtCardContent>
        </div>
    </OtInlineModal>
</template>

<script lang="ts" setup>
import type {
    AuthClient, Invitation, UserRaw,
} from '@openticket/lib-auth';
import type { Pagination } from '@openticket/lib-crud';
import { OtInlineModal } from '@openticket/vue-dashboard-components';
import { computed, ref } from 'vue';
import InvitationsList from '../../../components/invitations/InvitationsList.vue';
import { injectOrFail } from '../../../services/util';

interface MinimalCompany {
    name: string;
    guid: string;
    force_tfa: boolean;
}

type Emits = {
    (e: 'close'): void
}
const emit = defineEmits<Emits>();

const auth = injectOrFail<AuthClient>('auth');

const invitationsModal = ref<InstanceType<typeof OtInlineModal>>();
const user = ref<UserRaw | undefined>(undefined);
const invitations = ref<Pagination<Invitation<AuthClient>> | null>(null);

const tfaEnabled = computed((): boolean => !!(user.value && user.value.two_factor_enabled));
const pageValues = computed<MinimalCompany[]>(() => {
    if (!invitations.value) {
        return [];
    }

    // TODO: Cleanup invitation type in map function
    return invitations.value.records.map((invitation: Invitation<AuthClient>) => invitation.$data as MinimalCompany);
});

void (async () => {
    const tokenInfo = await auth.$token.$info;
    user.value = tokenInfo ? tokenInfo.user : undefined;

    invitations.value = auth.invitations.list();

    await invitations.value?.initialization;
})();

async function refreshInvitations(): Promise<void> {
    if (invitations.value) {
        await invitations.value.loadPage();
    }
}

function open(): void {
    void refreshInvitations();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    invitationsModal.value?.open();
}

async function closeInvitations(): Promise<void> {
    void refreshInvitations();
    emit('close');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await invitationsModal.value?.close();
}

defineExpose({
    open,
});
</script>

<style lang="scss" scoped>
.profile-invitations {
    &__modal {
        padding: 0;
        border-radius: var(--ot-input-radius);

        &__loading {
            height: 3rem;
        }

        &__content {
            margin-bottom: var(--ot-spacing-2xl);

            .ot-separator {
                margin-top: var(--ot-spacing-3xl);
                margin-bottom: var(--ot-spacing-3xl);
            }
        }
    }
}
</style>
