<template>
    <OtAdvancedTile
        title=""
        :record="record"
        class="order-details-ticket-list__record"
    >
        <template #content>
            <div class="order-details-tickets-list__record__content">
                <OtIcon
                    type="ticket"
                    size="large"
                />

                <div class="order-details-tickets-list__record__content__text">
                    <h3>{{ record.$data.ticket.name }}</h3>

                    <span class="ot-text-body-strong">{{ record.$data.ticket.event.name }}</span>

                    <div
                        v-if="record.$data.ticket.event.start && record.$data.ticket.event.end"
                        class="order-details-tickets-list__record__content__text__date"
                    >
                        <OtIcon
                            type="calendar"
                            size="small"
                        />
                        <span class="ot-text-body-strong">
                            {{ $l.dateTimeRangeCollapseLong(record.$data.ticket.event.start, record.$data.ticket.event.end) }}
                        </span>
                    </div>
                </div>

                <!--<OtFlair-->
                <!--    type="is-success"-->
                <!--    icon="check"-->
                <!-- > -->
                <!--    Checked-in-->
                <!--</OtFlair>-->
            </div>
        </template>

        <template #subcontent>
            <div class="order-details-tickets-list__record__subcontent">
                <div class="order-details-tickets-list__record__subcontent__top">
                    <div class="order-details-tickets-list__record__subcontent__top__info">
                        <OtDataDisplay>
                            <OtDataDisplayItem
                                :label="$t('dashboard.orders.details.total_transaction_fee')"
                                :value="paymentData.hasPayment
                                    ? (paymentData.isFree
                                        ? paymentData.currencyFormatter(0)
                                        : `${paymentData.currencyFormatter(record.$data.finn_price)}
                                            (${paymentData.currencyFormatter(record.$data.finn_service_fee)})`
                                    )
                                    : $t('dashboard.orders.details.no_payment')"
                            />
                            <OtDataDisplayItem
                                :label="$t('dashboard.orders.details.ticket.barcode')"
                                :value="record.$data.ticket_number"
                            />
                        </OtDataDisplay>

                        <OtDataDisplay v-if="!!record.$data.metadata.length">
                            <OtDataDisplayItem
                                v-for="(item, index) in record.$data.metadata"
                                :key="index"
                                :label="item.metadata.name"
                                :value="item.value"
                            />
                        </OtDataDisplay>
                    </div>

                    <!--<div class="order-details-tickets-list__record__subcontent__top__qr">-->
                    <!--    <img src="../../../assets/images/qr.svg" />-->

                    <!--    <div class="order-details-tickets-list__record__subcontent__top__qr__show">-->
                    <!--        <OtIcon type="show-password" size="small" />-->
                    <!--        <span class="ot-text-tiny-strong">Show QR</span>-->
                    <!--    </div>-->
                    <!--</div>-->
                </div>

                <div class="order-details-tickets-list__record__subcontent__bottom">
                    <div
                        v-if="invalidatedReason && record.$data.invalidated_since"
                        class="order-details-tickets-list__record__subcontent__bottom__invalidated"
                    >
                        <span class="ot-text-small">
                            {{ $t(invalidatedReason, {
                                invalidated_since: $l.dateTimeLong(record.$data.invalidated_since),
                                invalidated_reason: record.$data.invalidated_reason
                            }) }}
                        </span>
                        <OtIcon
                            type="info"
                            size="tiny"
                        />
                    </div>

                    <button
                        class="is-outline is-tiny order-details-tickets-list__record__subcontent__bottom__more"
                        @click="showMoreInfo = !showMoreInfo"
                    >
                        {{ $t('dashboard.orders.details.ticket.show_more') }}
                        <OtIcon
                            :type="showMoreInfo ? 'drop-up' : 'drop-down'"
                            size="tiny"
                            class="ot-button-icon--right"
                        />
                    </button>
                </div>

                <div
                    v-show="showMoreInfo"
                    class="order-details-tickets-list__record__subcontent__more"
                >
                    <OtDataDisplay>
                        <OtDataDisplayItem
                            :label="$t('dashboard.orders.details.ticket.download_link')"
                            :value="record.$data.download_link || $t('dashboard.orders.details.ticket.not_available')"
                            :url="record.$data.download_link"
                        />
                    </OtDataDisplay>
                </div>
            </div>
        </template>
    </OtAdvancedTile>
</template>

<script setup lang="ts">
import type { ManagementClient, OrderExtended, OrderTicketExtended } from '@openticket/lib-management';
import { computed, ref } from 'vue';
import type { UnwrapNestedRefs } from 'vue';
import type { PaymentDataComposableResult } from '../composables/usePaymentData';

type Props = {
    paymentData: UnwrapNestedRefs<PaymentDataComposableResult>,
    record: OrderTicketExtended<OrderExtended<ManagementClient>>,
}

const props = defineProps<Props>();

const showMoreInfo = ref<boolean>(false);

const invalidatedReasonSlugs: Record<string, string> = {
    'ticket is ticketswapped': 'dashboard.orders.tickets.invalidated_reason.swapped',
    default: 'dashboard.orders.tickets.invalidated_reason.default',
};

const invalidatedReason = computed<string | null>(() => {
    if (!props.record.$data.invalidated_since || !props.record.$data.invalidated_reason) {
        return null;
    }

    return invalidatedReasonSlugs[props.record.$data.invalidated_reason] || invalidatedReasonSlugs.default;
});
</script>

<style scoped lang="scss">
.order-details-tickets-list__record {
    &__content {
        display: flex;
        align-items: center;
        gap: var(--ot-spacing-lg);

        & img {
            border-radius: .5rem;
            width: 4rem;
            height: 4rem;
            background-color: var(--ot-color-core-accent-secondary);
            overflow: hidden;
        }

        &__text {
            flex: 1;
            color: var(--ot-color-core-light-foreground-secondary);

            h3 {
                color: var(--ot-color-core-light-foreground-primary);
            }

            &__date {
                display: flex;
                align-items: center;
                gap: var(--ot-spacing-xs);
            }
        }
    }

    &__subcontent {
        &__top {
            display: flex;
            border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);
            padding-bottom: var(--ot-spacing-xs);
            margin-bottom: var(--ot-spacing-xs);

            &__info {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: var(--ot-spacing-sm);
            }

            &__qr {
                position: relative;
                width: 6rem;
                height: 6rem;

                img {
                    opacity: 0.1;
                    width: 100%;
                    height: 100%;
                }

                &__show {
                    position: absolute;
                    background: var(--ot-color-core-light-background-primary);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 67%;
                    height: 67%;
                    border-radius: var(--ot-input-radius);
                    padding: 1rem 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: var(--ot-color-core-brand);
                    cursor: pointer;
                }
            }
        }

        &__bottom {
            display: flex;
            align-items: center;
            gap: var(--ot-spacing-xs);
            color: var(--ot-color-core-light-foreground-secondary);

            &__more {
                margin-left: auto;
            }
        }
    }
}
</style>
