<template>
    <OTTFAConfirmation
        v-if="tfaTypes"
        :error="error"
        :tfa-types="tfaTypes"
        :require-password="false"
        hide-logo
        hide-logout
        @submit="submitAfterTfaConfirmation"
        @back="tfaConfirmationBack"
    />

    <div
        v-else
        class="fix-reset-password"
    >
        <ModalHeader hide-padding />

        <div class="fix-reset-password__header">
            <h1>{{ $t('dashboard.reset_password.title') }}</h1>
            <div class="ot-label">
                <i18n path="dashboard.reset_password.subtitle.text">
                    <template #contact>
                        <a
                            v-if="$whitelabel.dashboard.support_url"
                            class="ot-link ot-clickable"
                            target="_blank"
                            :href="$whitelabel.dashboard.support_url"
                        >
                            {{ $t('dashboard.reset_password.subtitle.contact') }}
                        </a>
                        <span v-else>
                            {{ $t('dashboard.reset_password.subtitle.contact') }}
                        </span>
                    </template>
                    <template #entity>
                        <span class="terms-entity terms-entity-first">
                            {{ $whitelabel.name }}
                        </span>
                    </template>
                </i18n>
            </div>
            <h5
                v-if="form.errors.new_password?.length > 0"
                class="warning"
            >
                {{ $t(form.errors.new_password[0].message, form.errors.new_password[0].data) }}
            </h5>
            <h5
                v-if="form.errors.new_password_confirmation?.length > 0"
                class="warning"
            >
                {{ $t(form.errors.new_password_confirmation[0].message, form.errors.new_password_confirmation[0].data) }}
            </h5>
        </div>
        <form @submit.prevent="submit()">
            <div class="fix-reset-password__content">
                <InputField
                    :label="$t('dashboard.reset_password.form.create_password')"
                    :required="form.rules.new_password?.includes('required')"
                    :optional="form.rules.new_password?.includes('optional')"
                >
                    <OtInput
                        v-model="form.model.$data.new_password"
                        data-testid="password-field"
                        type="password"
                        :invalid="form.errors.new_password?.length > 0"
                        :placeholder="$t('dashboard.user.change_password.new_password')"
                        @input="form.errors.new_password = []"
                    />
                </InputField>
                <InputField
                    :label="$t('dashboard.reset_password.form.confirm_password')"
                    :required="form.rules.new_password?.includes('required')"
                    :optional="form.rules.new_password?.includes('optional')"
                    :error="form.errors.new_password_confirmation"
                    @input="form.errors.new_password_confirmation = []"
                >
                    <OtInput
                        v-model="form.model.$data.new_password_confirmation"
                        data-testid="password-confirmation-field"
                        type="password"
                        :invalid="form.errors.new_password_confirmation?.length > 0"
                        :placeholder="$t('dashboard.user.change_password.new_password_confirmation')"
                        @input="form.errors.new_password_confirmation = []"
                    />
                </InputField>
            </div>
            <div class="fix-reset-password__footer">
                <button
                    class="ot-button is-large"
                    type="submit"
                    :disabled="!formCompleted"
                    :title="$t('dashboard.reset_password.form.button.title')"
                >
                    <OtIcon
                        class="ot-button-icon--left"
                        type="check"
                    />
                    {{ $t('dashboard.reset_password.form.button.text') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, type UnwrapNestedRefs } from 'vue';
import type { TFATypes } from '@openticket/vue-tfa-confirmation';
import type { AuthClient, ForgotPassword, Password } from '@openticket/lib-auth';
import { OtInput } from '@openticket/vue-input';
import { useRoute } from 'vue-router/composables';
import type { ValidationError } from '@openticket/lib-crud';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import InputField from '../../../components/form/InputField.vue';
import { injectOrFail } from '../../../services/util';
import type { ModelFormComposableResult } from '../../../composables/forms';
import { useGenericErrorHandling } from '../../../composables';

type Props = {
    form: UnwrapNestedRefs<
        ModelFormComposableResult<
            ForgotPassword<Password<AuthClient>>,
            ForgotPassword<Password<AuthClient>>
        >
    >,
}

type TfaErrors = {
    incorrectPassword: ValidationError & { customAttribute?: string } | null,
    incorrectTFAInput: ValidationError & { customAttribute?: string } | null
}

const props = defineProps<Props>();

const auth = injectOrFail<AuthClient>('auth');
const route = useRoute();
const { handleError } = useGenericErrorHandling();

const tfaTypes = ref<TFATypes | null>(null);
const error = ref<TfaErrors>({
    incorrectPassword: null,
    incorrectTFAInput: null,
});

const formCompleted = computed(
    (): boolean => !!props.form.model.$data.new_password && !!props.form.model.$data.new_password_confirmation,
);

async function submit(): Promise<void> {
    const { token } = route.params;
    props.form.model.$data.token = token;

    try {
        const response = await props.form.submit();

        if (response.success) {
            return;
        }

        if (props.form.errors.new_password && props.form.errors.new_password.length > 0) {
            [ error.value.incorrectPassword ] = props.form.errors.new_password;
        } else if (props.form.errors.tfa_type) {
            error.value.incorrectPassword = null;
            error.value.incorrectTFAInput = null;

            for (const err of props.form.errors.tfa_type) {
                let split = err.message.split(':');

                if (split[0] === 'validation.in') {
                    split = split[1].split(',');

                    tfaTypes.value = {};
                    for (const tfa_method of split) {
                        tfaTypes.value[tfa_method] = { name: tfa_method, settings: 1 };
                    }
                }
            }
        } else if (props.form.errors.tfa_input) {
            [ error.value.incorrectTFAInput ] = props.form.errors.tfa_input;
        }
    } catch (e) {
        handleError(e);
    }
}

async function submitAfterTfaConfirmation(tfaConfirmed: { input: string, type: string }): Promise<void> {
    if (!auth) {
        return;
    }

    props.form.model.$data.tfa_type = tfaConfirmed.type;
    props.form.model.$data.tfa_input = tfaConfirmed.input;

    await submit();
}

function tfaConfirmationBack(): void {
    tfaTypes.value = null;
}
</script>

<style lang="scss" scoped>
.fix-reset-password {
    &__header {
        margin-bottom: var(--ot-spacing-lg);

        .warning {
            color: var(--ot-color-accent-orange-dark);
        }

        &__top {
            display: flex;
            margin-bottom: var(--ot-spacing-lg);

            &__logo {
                max-width: 40%;
                text-align: left;
            }

            &__logout {
                color: var(--ot-color-core-brand);
                margin: auto 0 auto auto;
            }
        }

        &__title {
            text-align: center;
        }
    }

    &__content {
        margin-bottom: var(--ot-spacing-3xl);
    }

    &__footer .ot-button {
        width: 100%;
    }
}
</style>
