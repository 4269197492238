<template>
    <div
        class="draggable-group"
        data-testid="draggable-group"
    >
        <div class="draggable-group__content">
            <div class="draggable-group__header">
                <div class="draggable-group__title">
                    <h3
                        class="draggable-group__title__text"
                        data-testid="draggable-group-name"
                    >
                        <OtIcon
                            v-if="group.concept"
                            type="file"
                            size="small"
                        />
                        {{ title }}
                    </h3>
                    <slot name="sub-text" />
                </div>

                <button
                    class="draggable-group__edit ot-button is-tiny is-outline"
                    data-testid="draggable-group-edit-group"
                    @click="emit('open-edit:group', group)"
                >
                    <OtIcon
                        class="ot-button-icon--left"
                        size="tiny"
                        type="edit"
                    />
                    <slot name="edit">
                        {{ $t('dashboard.components.draggable_group.edit_item', { item: groupName.toLowerCase() }) }}
                    </slot>
                </button>
                <button
                    class="draggable-group__add-item ot-button is-tiny is-outline"
                    data-testid="draggable-group-add-item"
                    @click="emit('open-add:item', group.guid)"
                >
                    <OtIcon
                        type="plus"
                        size="tiny"
                        class="draggable-group__add-item__icon ot-button-icon--left"
                    />
                    <slot name="add">
                        {{ $t('dashboard.components.draggable_group.add_item', { item: itemName }) }}
                    </slot>
                </button>
                <button
                    v-if="isDraggable"
                    data-drag-handle
                    data-testid="draggable-group-drag-handle"
                    class="ot-button is-tiny is-text drop-both"
                >
                    <OtIcon
                        type="drop-both"
                        size="small"
                    />
                </button>
                <button
                    v-if="isRemovable"
                    class="ot-button is-tiny is-text close"
                    data-testid="draggable-group-remove"
                    @click="emit('remove:group', group.guid)"
                >
                    <OtIcon
                        type="close"
                        size="small"
                    />
                </button>
            </div>
            <LazyLoadScroll
                :has-next="group.hasNext"
                @next-page="emit('paginate:group', group.guid)"
            >
                <Draggable
                    v-if="items"
                    :list="items"
                    handle="[data-drag-handle]"
                    :group="draggableGroupKey"
                    class="draggable-group__draggable"
                    @change="onChange"
                >
                    <DraggableItem
                        v-for="item in items"
                        :key="`${item.guid}-${Math.random().toString(36).substring(7)}`"
                        :icon="icon"
                        :item="item"
                        :is-draggable="isChildDraggable"
                        data-testid="draggable-group-item"
                        @remove="emit('remove:item', item.guid, group.guid)"
                    />
                </Draggable>
                <OtSpinner
                    v-if="loading"
                    class="draggable-group__spinner"
                    :class="{ 'draggable-group__spinner--transition': loading }"
                />
            </LazyLoadScroll>
        </div>
        <slot name="footer" />
    </div>
</template>

<script setup lang="ts">
import Draggable from 'vuedraggable';
import { computed, ref } from 'vue';
import { type OtIconTypes } from '@openticket/vue-icons';
import type { DragGroup, DragItem } from './types';
import DraggableItem from './DraggableItem.vue';
import LazyLoadScroll from '../lazy-load-scroll/LazyLoadScroll.vue';
import type { LinkEvent, SortEvent } from '../../composables/draggable-interpreter/types';
import useDraggableInterpreter from '../../composables/draggable-interpreter/useDraggableInterpreter';

type Props = {
    draggableGroupKey: object | string,
    group: DragGroup,
    icon?: OtIconTypes,
    isChildDraggable?: boolean,
    isDraggable?: boolean,
    isRemovable?: boolean,
    itemName?: string,
    groupName?: string,
}

type Emits = {
    (e: 'update:group', value: DragGroup): void,
    (e: 'remove:group', groupId: string): void,
    (e: 'remove:item', itemId: string, groupId: string): void,
    (e: 'open-edit:group', group: DragGroup): void,
    (e: 'open-add:item', groupId: string): void,
    (e: 'paginate:group', groupId: string): void,
    (e: 'move:group', mutation: SortEvent): void,
    (e: 'move:item', mutation: SortEvent): void,
    (e: 'link', mutation: LinkEvent): void,
    (e: 'unlink', mutation: LinkEvent): void,
}

const props = withDefaults(
    defineProps<Props>(),
    {
        isChildDraggable: false,
        isDraggable: false,
        isRemovable: false,
        icon: undefined,
        itemName: 'item',
        groupName: 'Group',
    },
);
const emit = defineEmits<Emits>();

const items = ref<DragItem[]>(props.group.items);
const loading = ref<boolean>(false);

const title = computed<string>(() => (!props.group.name ? props.groupName : props.group.name));
const {
    onChange,
} = useDraggableInterpreter(items.value, emit, props.group);
</script>

<style lang="scss" scoped>
.draggable-group {
    &__content {
        border-radius: var(--ot-input-radius);
        border: 1px solid var(--ot-color-core-accent-secondary);
        overflow: hidden;
        background-color: var(--ot-color-core-light-background-secondary);
    }

    &__spinner {
        padding-top: var(--ot-spacing-xl) !important;
        padding-bottom: var(--ot-spacing-xl) !important;
        overflow: hidden;
        height: 0;
        transition: height 0.3s ease, padding 0.3s ease;

        &--transition {
            height: auto;
        }
    }

    &__title {
        flex: 1;
        display: flex;

        &__text {
            flex: initial;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__edit {
        margin-left: var(--ot-spacing-unit);
    }

    &__header {
        background-color: var(--ot-color-core-white);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: var(--ot-spacing-lg);
        flex-wrap: nowrap;
        text-wrap: nowrap;

        > *:not(:first-child) {
            margin-left: var(--ot-spacing-xs);
            color: var(--ot-color-core-foreground-secondary);
        }

        .close, .drop-both {
            margin-left: var(--ot-spacing-lg);
        }

        .close:hover {
            color: var(--ot-color-accent-orange-dark);
        }

        .drop-both:hover {
            color: var(--ot-color-core-brand);
        }
    }

    &__draggable {
        padding-left: var(--ot-spacing-lg);
        padding-right: var(--ot-spacing-lg);
    }

    &__footer {
        color: var(--ot-color-core-accent-primary);
        margin-top: var(--ot-spacing-unit);
        padding-left: var(--ot-spacing-lg);
        padding-right: var(--ot-spacing-lg);
        padding-bottom: var(--ot-spacing-unit);
    }

    &__add-item {
        &__icon {
            color: var(--ot-color-core-light-foreground-primary);
        }
    }

    &:deep(.sortable-ghost) {
        border-radius: 0;
        padding: var(--ot-spacing-unit) 0;
        border: none;
    }

    // To keep the moved object from swapping position with the footer
    &__footer + :deep(.sortable-ghost) {
        display: none;
    }
}
</style>
