<template>
    <div class="ot-card outstanding-invitation">
        <ModalHeader
            :title="$t( 'dashboard.header.profile.invitations.title')"
            class="outstanding-invitation__header"
        />
        <div v-if="user">
            <div class="outstanding-invitation__content">
                <InvitationsList
                    :invitations="invitations"
                    :two-factor-enabled="user.two_factor_enabled"
                    :no-invitation-warning="{
                        title: 'dashboard.header.profile.invitations.no_invitations.title',
                        description: noInvitationsDescription,
                    }"
                    :show-redirect-to-fix-page="true"
                    @accepted="acceptedInvitation"
                />
                <!-- DD-DASHBOARD-2714 - New users with no valid invitation (all are deleted or expired),
                cannot create a new company. All they can do is log out.-->
            </div>

            <OtCardFooter>
                <template #right>
                    <button
                        v-if="tfaInvite && !user.two_factor_enabled"
                        class="ot-button is-dark"
                        type="button"
                        :title="$t('dashboard.user.tfa.action.enable.title')"
                        @click="redirectTfa"
                    >
                        <OtIcon
                            class="ot-button-icon--left"
                            type="call"
                        />
                        {{ $t('dashboard.user.tfa.action.enable.text') }}
                    </button>
                    <button
                        class="ot-button"
                        type="button"
                        :disabled="!hasACompany"
                        :title="$t('dashboard.common.action.next.title')"
                        @click="redirectLogin"
                    >
                        {{ $t('dashboard.common.action.next.text') }}
                        <OtIcon
                            class="ot-button-icon--right"
                            type="arrow-right"
                        />
                    </button>
                </template>
            </OtCardFooter>
        </div>

        <OtSpinner v-else />
    </div>
</template>

<script setup lang="ts">
import type { AuthClient } from '@openticket/lib-auth';
import urlJoin from 'url-join';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import InvitationsList from '../../../components/invitations/InvitationsList.vue';
import { injectOrFail } from '../../../services/util';
import type { OptionalClientConfig, StrippedMinimalUser } from '../types';
import type { RudderStack } from '../../../services/rudderstack';

interface MinimalCompany {
    name: string;
    guid: string;
    force_tfa: boolean;
}

const auth = injectOrFail<AuthClient>('auth');
const user = injectOrFail<StrippedMinimalUser>('minimal_user');
const rudderstack = injectOrFail<RudderStack>('rudderstack');

const tfaInvite = ref<boolean>(false);
const hasACompany = ref<boolean>(false);
const invitations = ref<MinimalCompany[]>([]);
const clientConfig = ref<OptionalClientConfig>();

const noInvitationsDescription = computed((): string => {
    if (user.has_a_company) {
        return 'dashboard.header.profile.invitations.no_invitations.description_with_companies';
    }

    return 'dashboard.header.profile.invitations.no_invitations.description';
});

const route = useRoute();
const router = useRouter();

void (async (): Promise<void> => {
    clientConfig.value = route.query;

    // TODO - @openticket/lib-auth
    const response: { data: { data: MinimalCompany[] } } = await auth.$http.get<{ data: MinimalCompany[] }>(
        urlJoin(auth.$path, 'invitations', 'companies'),
    );

    invitations.value = response.data.data;
    tfaInvite.value = invitations.value.some((invite): boolean => invite.force_tfa);

    hasACompany.value = user.has_a_company;

    rudderstack.track('vue-dashboard fix zero companies init', { invitationsCount: invitations.value.length ?? 0 });
})();

async function redirectTfa(): Promise<void> {
    await router.push({ name: 'fix.tfa', query: { ...clientConfig.value, 'forced-warning': 'hide' } });
}

async function redirectLogin(): Promise<void> {
    rudderstack.track('vue-dashboard fix zero companies redirect login');
    await router.push({ name: 'auth.login', query: clientConfig.value });
}

function acceptedInvitation(): void {
    hasACompany.value = true;
    rudderstack.track('vue-dashboard fix zero companies accepted invitation');
}
</script>

<style lang="scss" scoped>
.outstanding-invitation {
    padding: 0;

    &__header {
        padding: var(--ot-card-padding);

        &::v-deep h1 {
            text-align: left;
        }
    }

    &__content {
        padding: var(--ot-card-padding);
        padding-top: 0;
    }
}

.card-footer {
    border-radius: 0 0 var(--ot-card-border-radius) var(--ot-card-border-radius);}
</style>
