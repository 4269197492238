<template>
    <div
        class="shop-home-wrapper"
        data-testid="shop-home-wrapper"
    >
        <div class="shop-home-wrapper__header ot-pb[lg] ot-mb[lg]">
            <h1>{{ context.shop.name }}</h1>

            <a
                v-if="showPreviewButton"
                class="ot-button is-outline is-small"
                :href="shopUrl || '#'"
                target="_blank"
            >
                <OtIcon
                    type="show-password"
                    class="ot-mr[xs]"
                    size="small"
                />
                {{ $t('dashboard.shop_tickets.preview_shop.text') }}
            </a>
        </div>

        <router-view />
    </div>
</template>

<script setup lang="ts">
import {
    computed, provide, ref, watch,
} from 'vue';
import urlJoin from 'url-join';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRoute } from 'vue-router/composables';
import { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import type { Ref, WatchStopHandle } from 'vue';
import axios from 'axios';
import type { DialogController, OtPreview } from '@openticket/vue-dashboard-components';
import type VueNotifications from '@openticket/vue-notifications';
import type { NavigationGuard } from 'vue-router';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import { shopSettingsConfigKey } from '../keys';
import type { PluginsManager } from '../../../plugins';
import { useGenericErrorHandling, useLocalization } from '../../../composables';
import { MinimalCrossWindowClient } from '../services/minimalCrossWindowClient';

const context = injectOrFail<Context>('context');
const dialog = injectOrFail<DialogController>('dialog');
const notifications = injectOrFail<VueNotifications>('notifications');
const plugins = injectOrFail<PluginsManager>('plugins');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

if (!context.isShopContext()) {
    throw Error('Invalid context');
}

const { error, handleError } = useGenericErrorHandling();
const { t } = useLocalization();
const route = useRoute();

const crossWindowClient = ref<MinimalCrossWindowClient | null>();
const hasLocalChanges = ref<boolean>(false);
const loading = ref<number>(0);
const previewRef = ref<InstanceType<typeof OtPreview> | null>() as Ref<InstanceType<typeof OtPreview> | null>;
const settings = ref<CustomShopSettingsClient>(new CustomShopSettingsClient());

let stopWatcher: WatchStopHandle | null = null;

const shopUrl = computed<string>(() => {
    if (!whitelabel || !context.isShopContext()) {
        return '';
    }

    return urlJoin(whitelabel.shop.url, context.shop.id, '?nocache');
});

const showPreviewButton = computed<boolean>(() => !!shopUrl.value && (route.name === 'shops.tickets'));

watch(() => context?.shop?.id, async () => {
    await initShopSettings();
});

/**
 * Config that is provided to all nested Shop Settings components
 */
provide(shopSettingsConfigKey, {
    error,
    hasLocalChanges,
    loading,
    onLoadPreview,
    previewRef,
    save,
    shopUrl,
});
provide('previewRef', previewRef);
provide('settings', settings.value);

async function initShopSettings(showSpinner = true) {
    if (loading.value) {
        return;
    }

    loading.value += showSpinner ? 1 : 0;
    if (stopWatcher) {
        stopWatcher();
    }
    await loadShopSettings();
    loading.value -= showSpinner ? 1 : 0;
}

async function loadShopSettings() {
    if (context.isShopContext()) {
        try {
            const auth = await plugins.auth.loading;
            await settings.value.init({
                baseUrl: whitelabel.shop.custom_shop_settings_url || 'https://custom.shop.openticket.tech/',
                disableCache: true,
                httpRead: axios.create(),
                httpWrite: auth.$http,
                shopId: context.shop.id,
            });

            stopWatcher = watch(() => settings.value.static, () => {
                hasLocalChanges.value = true;
                crossWindowClient.value?.setStaticShopSettings(settings.value.static);
            }, { deep: true });
        } catch (e) {
            void handleError(e);
        }
    }
}

async function save() {
    if (context.isShopContext()) {
        try {
            // TODO: replace with auth.withCompanyScope
            settings.value.setCompanyScope(context.company.id);
            await settings.value.updateStaticSettings(settings.value.static);
            hasLocalChanges.value = false;
            notifications.success({ message: t('dashboard.common.notification.save.success') });
            sendLegacyDashboardCallback();
        } catch (err) {
            notifications.danger({ message: t('dashboard.common.notification.save.fail') });
        }
    }
}

async function onLoadPreview(): Promise<void> {
    try {
        if (
            previewRef.value
            && previewRef.value.contentRef
            && previewRef.value.contentRef instanceof HTMLIFrameElement
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            && previewRef.value.contentRef.contentWindow
        ) {
            // Autocomplete correctly recognizes the types
            // but eslint does not so disabling member-access and argument unsafe rules.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
            crossWindowClient.value = new MinimalCrossWindowClient(previewRef.value.contentRef.contentWindow);
            await crossWindowClient.value.connecting;
            crossWindowClient.value.setStaticShopSettings(settings.value.static);
        }
    } catch (e) {
        void handleError(e);
    }
}

function isOpenerWindowProxy(opener: unknown): opener is WindowProxy {
    return !!opener && !!(opener as { postMessage?: unknown }).postMessage;
}

function sendLegacyDashboardCallback(): void {
    try {
        if (isOpenerWindowProxy(window.opener)) {
            window.opener.postMessage('shop-settings-updated', 'https://dashboard.eventix.io');
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.debug('error while calling legacy dashboard', e);
    }
}

const handleRouteChange: NavigationGuard = async (to, from, next) => {
    if (hasLocalChanges.value) {
        const confirm = await dialog.confirm({
            // TODO: Replace translation function in Vue 3
            title: t('dashboard.common.confirm.unsaved_changes.title') || '',
            description: t('dashboard.common.confirm.unsaved_changes.description') || '',
            type: 'is-danger',
        });

        if (!confirm) {
            return;
        }

        // Reset shop forms when navigating between vertical tabs items and the data is discarded
        if (previewRef.value && (to.name?.startsWith('shops.styling') || to.name?.startsWith('shops.edit'))) {
            await initShopSettings(false);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            previewRef.value.reloadContent();
        }

        hasLocalChanges.value = false;
    }

    next();
};

void initShopSettings();

onBeforeRouteLeave(handleRouteChange);
onBeforeRouteUpdate(handleRouteChange);
</script>

<style lang="scss">
.shop-home-wrapper {
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);

        h1 {
            min-height: 2.5rem;
        }
    }
}
</style>
