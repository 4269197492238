<template>
    <iframe
        v-if="productPortalUrl"
        v-resize="{
            heightCalculationMethod: 'lowestElement',
            log: false,
        }"
        :src="productPortalUrl"
        :title="$t('dashboard.document_title.product_portal.title')"
        frameborder="0"
        width="100%"
    />

    <ErrorView
        v-else-if="error"
        :label="error.message"
    />
</template>

<script lang="ts" setup>
import { useGenericErrorHandling } from '../../../composables';
import ErrorView from '../../../components/ErrorView.vue';

const { error } = useGenericErrorHandling();

const productPortalUrl = getproductPortalUrl();

function getproductPortalUrl(): string | null {
    // TODO: move this url to whitelabel config
    const url = new URL('https://portal.productboard.com/4xfuu9jpyfhbjncq7fxqkssh');
    url.searchParams.set('hide_header', '0');

    return url.toString();
}
</script>

<style lang="scss" scoped>
iframe {
  height: calc(100vh - var(--ot-layout-header-height, 5.5rem));
  position: absolute;
  left: 0;
}
</style>
