<template>
    <div
        v-if="finalizeForm || tokenErrors.notLoggedOut || tokenErrors.token"
        class="ot-card complete-profile"
    >
        <div v-if="tokenErrors.notLoggedOut">
            <ModalHeader hide-padding>
                <template #logout>
                    <button
                        class="is-text is-small"
                        type="button"
                        :title="$t('dashboard.invitation.invalid.logout.title')"
                        @click="logoutAndReload"
                    >
                        <OtIcon
                            class="ot-button-icon--left"
                            type="logout"
                        />
                        {{ $t('dashboard.invitation.invalid.logout.text') }}
                    </button>
                </template>
            </ModalHeader>

            <div class="complete-profile__header">
                <h1>{{ $t('dashboard.invitation.complete_profile.title') }}</h1>
            </div>

            <div class="complete-profile__content">
                <OtAside
                    :title="$t('dashboard.invitation.invalid.invalid')"
                    :description="$t(tokenErrors.notLoggedOut)"
                    type="is-warning"
                    icon="warning"
                />
            </div>

            <div class="complete-profile__footer">
                <button
                    class="ot-button"
                    type="button"
                    :title="$t('dashboard.invitation.invalid.dashboard.title')"
                    @click="$router.push({name: 'auth.login'})"
                >
                    {{ $t('dashboard.invitation.invalid.dashboard.text') }}
                    <OtIcon
                        class="ot-button-icon--right"
                        type="arrow-right"
                    />
                </button>
            </div>
        </div>

        <div v-else-if="tokenErrors.token">
            <ModalHeader hide-padding />
            <div class="complete-profile__header">
                <h1>{{ $t('dashboard.invitation.complete_profile.title') }}</h1>
            </div>

            <div class="complete-profile__content">
                <OtAside
                    :title="$t('dashboard.invitation.invalid.invalid')"
                    :description="$t(tokenErrors.token)"
                    type="is-warning"
                    icon="warning"
                />
            </div>
        </div>

        <div v-else-if="finalizeForm">
            <ModalHeader hide-padding />
            <div class="complete-profile__header">
                <h1>{{ $t('dashboard.invitation.complete_profile.title') }}</h1>
            </div>

            <div class="complete-profile__content">
                <OtForm form="">
                    <OtFormRow>
                        <InputField
                            :label="$t('dashboard.invitation.complete_profile.form.your_name')"
                            :error="finalizeForm.errors.name"
                            :required="finalizeForm.rules.name?.includes('required')"
                            :optional="finalizeForm.rules.name?.includes('optional')"
                        >
                            <OtInput
                                v-model="finalizeForm.model.$data.name"
                                class="input"
                                type="text"
                                :invalid="!!finalizeForm.errors.name"
                            />
                        </InputField>
                    </OtFormRow>

                    <OtFormRow>
                        <InputField
                            :label="$t('dashboard.invitation.complete_profile.form.create_password')"
                            :error="finalizeForm.errors.new_password"
                            :required="finalizeForm.rules.new_password?.includes('required')"
                            :optional="finalizeForm.rules.new_password?.includes('optional')"
                        >
                            <OtInput
                                v-model="finalizeForm.model.$data.new_password"
                                class="input"
                                type="password"
                                :invalid="!!finalizeForm.errors.new_password"
                                data-testid="password-field"
                            />
                        </InputField>
                    </OtFormRow>

                    <OtFormRow>
                        <InputField
                            :label="$t('dashboard.invitation.complete_profile.form.confirm_password')"
                            :error="finalizeForm.errors.new_password_confirmation"
                            :required="finalizeForm.rules.new_password_confirmation?.includes('required')"
                            :optional="finalizeForm.rules.new_password_confirmation?.includes('optional')"
                        >
                            <OtInput
                                v-model="finalizeForm.model.$data.new_password_confirmation"
                                class="input"
                                type="password"
                                :invalid="!!finalizeForm.errors.new_password_confirmation"
                                data-testid="confirm-password-field"
                            />
                        </InputField>
                    </OtFormRow>

                    <div class="terms">
                        <InputField :error="finalizeForm.errors.accept_tos">
                            <OtInput
                                id="terms"
                                v-model="finalizeForm.model.$data.accept_tos"
                                class="input terms-label__checkbox"
                                type="checkbox"
                            />

                            <i18n
                                path="dashboard.invitation.complete_profile.form.terms.first"
                                class="ot-input-label ot-checkbox-label terms-label__label"
                            >
                                <template #tos>
                                    <a
                                        class="terms-tos-first ot-link"
                                        :href="$whitelabel.dashboard.terms_url || 'https://eventix.io/documents/2018_EN_organiser_terms.pdf?lang=en_GB'"
                                        target="_blank"
                                    >
                                        {{ $t('dashboard.invitation.complete_profile.form.terms.tos') }}
                                    </a>
                                </template>

                                <template #entity>
                                    <span class="terms-entity terms-entity-first">
                                        {{ $whitelabel.name }}
                                    </span>

                                    <span
                                        v-if="finalizeForm.rules.accept_tos?.includes('required')"
                                        class="ot-input-label--required"
                                    >
                                        *
                                    </span>
                                </template>
                            </i18n>
                        </InputField>
                    </div>
                </OtForm>
            </div>

            <div class="complete-profile__footer">
                <button
                    class="ot-button is-large"
                    type="button"
                    :disabled="formNotCompleted"
                    :title="$t('dashboard.invitation.complete_profile.form.button.title')"
                    @click="submit()"
                >
                    <OtIcon
                        class="ot-button-icon--left"
                        type="check"
                    />
                    {{ $t('dashboard.invitation.complete_profile.form.button.text') }}
                </button>
            </div>
        </div>
    </div>
    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { AuthClient, FinalizeToken, FinalizeUser } from '@openticket/lib-auth';
import { isAxiosError } from 'axios';
import urlJoin from 'url-join';
import { computed, reactive } from 'vue';
import { OtInput } from '@openticket/vue-input';
import { useRoute, useRouter } from 'vue-router/composables';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import { isErrorDescriptionAxiosError } from '../../../services/http/axios';
import InputField from '../../../components/form/InputField.vue';
import { injectOrFail } from '../../../services/util';
import type { RudderStack } from '../../../services/rudderstack';
import { useCustomValidatedPostForm } from '../../../composables/forms';
import { useResetTokenCheck } from '../composable/useResetTokenCheck';

const auth = injectOrFail<AuthClient>('auth');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const rudderstack = injectOrFail<RudderStack>('rudderstack');

const formNotCompleted = computed<boolean>(() => !finalizeForm.model.$data.name
    || !finalizeForm.model.$data.new_password
    || !finalizeForm.model.$data.new_password_confirmation
    || !finalizeForm.model.$data.accept_tos);

const route = useRoute();
const router = useRouter();
const { tokenErrors, checkValidResetToken } = useResetTokenCheck('finalizeUser');

const finalizeForm = reactive(
    useCustomValidatedPostForm<FinalizeUser<AuthClient>, FinalizeUser<AuthClient>>(
        auth.finalizeUser.new(),
        auth.finalizeUser,
        {
            byId: true,
            onSubmit() {
                if (whitelabel.dashboard.login_url) {
                    window.location.href = whitelabel.dashboard.login_url;
                } else {
                    void router.push({ name: 'auth.login' });
                }
            },
        },
    ),
);

void (async (): Promise<void> => {
    if (!auth) {
        return;
    }

    rudderstack.track('vue-dashboard fix finalize profile init');

    await checkValidResetToken(undefined, receiveAndSetUsername);
})();

function receiveAndSetUsername(response: FinalizeToken<AuthClient> | undefined) {
    if (!response) {
        return;
    }

    if (!response.$data.user.name) {
        return;
    }

    finalizeForm.model.$data.name = response.$data.user.name;
}

async function logoutAndReload(): Promise<void> {
    try {
        // TODO - @openticket/lib-auth
        await Promise.all([
            auth.$token.logout(), // TODO @Peter - lib-auth add a 'clear tokens' (e.g. logout without hooks) method.
            auth.$http.get(urlJoin(auth.$path, 'sessions', 'logout')),
        ]);
    } catch (e: unknown) {
        if (isAxiosError(e) && e.response && (
            e.response.status === Number(import.meta.env.VITE_LOGOUT_REDIRECT_STATUS)
                || e.response.status === 355
        )) {
            await checkValidResetToken(undefined, receiveAndSetUsername);
        } else {
            await router.push({ name: 'error' });
        }
    }
}

async function submit(): Promise<void> {
    if (!auth) {
        return;
    }

    const { token } = route.params;
    finalizeForm.model.$data.token = token;

    try {
        await finalizeForm.submit();
    } catch (e: unknown) {
        if (!isAxiosError(e) || !isErrorDescriptionAxiosError(e)) {
            void router.push({ name: 'error' });
        }
    }
}
</script>

<style lang="scss" scoped>
.complete-profile {

    &__header{
        margin-bottom: var(--ot-spacing-lg);

        &__top {
            display: flex;
            margin-bottom: var(--ot-spacing-lg);

            &__logo {
                max-width: 40%;
                text-align: left;
            }

            &__logout {
                color: var(--ot-color-core-brand);
                margin: auto 0 auto auto;
            }
        }

        &__title {
            text-align: center;
        }
    }

    &__content {
        margin-bottom: var(--ot-spacing-3xl);

        .terms {
            &::v-deep .terms-label__checkbox {
                flex: initial;
                width: auto;
                margin-right: 0;
            }

            &-label__label {
                cursor: default;
            }
        }
    }

    &__footer .ot-button {
        &.is-text {
            color: var(--ot-color-core-brand);
        }

        width: 100%;
        margin-bottom: var(--ot-spacing-default);
    }
}
</style>
