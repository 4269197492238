<template>
    <div
        v-if="!!goBackToClassicRoute"
        class="back-to-card ot-p[md]"
    >
        <h3 class="back-to-card-title">
            {{ $t('dashboard.classic.back_to_classic_dashboard_card.title') }}
        </h3>
        <p class="back-to-card-description ot-text-small">
            {{ $t('dashboard.classic.back_to_classic_dashboard_card.description') }}
        </p>
        <a
            :title="$t('dashboard.classic.back_to_classic_dashboard_card.button.title')"
            :aria-label="$t('dashboard.classic.back_to_classic_dashboard_card.button.title')"
            :href="goBackToClassicRoute"
            class="ot-button is-outline ot-px[xs]"
        >
            <OtIcon
                type="carrot-left"
                class="ot-mr[xs]"
                size="small"
            />
            <span class="ot-text-small">
                {{ $t('dashboard.classic.back_to_classic_dashboard_card.button.text') }}
            </span>
        </a>
    </div>
</template>

<script setup lang="ts">
import { useClassicDashboardRouting } from '../composables';

const { goBackToClassicRoute } = useClassicDashboardRouting();
</script>

<style scoped lang="scss">
.back-to-card {
    background-color: var(--ot-color-core-background-primary);
    border-radius: var(--ot-input-radius);
    display: flex;
    flex-direction: column;
    gap: var(--ot-spacing-xs);
}
</style>
