<template>
    <OtCard
        class="tfa-codes"
        no-padding
    >
        <ModalHeader
            :hide-logo="tfaSetupProvider.hideLogo"
            :hide-logout="tfaSetupProvider.hideLogout"
            text-centered
        >
            <template #title>
                <h1>{{ $t('dashboard.user.tfa.setup.code.title') }}</h1>
            </template>

            <template #extra>
                <h5 class="tfa-codes__header__success ot-label">
                    {{ $t(tfaSetupProvider.successMessage) }}
                </h5>
            </template>
        </ModalHeader>

        <OtCardContent>
            <div class="tfa-codes__explanation-label">
                {{ $t('dashboard.user.tfa.setup.code.explanation') }}
            </div>

            <div class="tfa-codes__codes">
                <div
                    v-for="(code, key) in codes"
                    :key="key"
                    class="tfa-codes__codes__code mb-3"
                >
                    {{ code }}
                </div>
            </div>
            <OtAside
                :title="$t('dashboard.user.tfa.setup.code.explanation_warning.title')"
                :description="$t('dashboard.user.tfa.setup.code.explanation_warning.description')"
                type="is-danger"
                icon="alert"
            />
            <div class="tfa-codes__download">
                <button
                    class="ot-button is-outline"
                    type="button"
                    :title="$t('dashboard.user.tfa.setup.code.download.title')"
                    @click="saveContent"
                >
                    <OtIcon
                        type="download"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.user.tfa.setup.code.download.text') }}
                </button>
                <button
                    class="ot-button is-outline"
                    type="button"
                    :title="$t('dashboard.user.tfa.setup.code.copy.title')"
                    @click="copyContent"
                >
                    <div v-if="!copied">
                        <OtIcon
                            type="copy"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.user.tfa.setup.code.copy.text') }}
                    </div>
                    <div
                        v-if="copied"
                        class="copied_success"
                    >
                        <OtIcon
                            type="check"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.user.tfa.setup.code.copy.success') }}
                    </div>
                </button>
            </div>
        </OtCardContent>

        <OtCardFooter class="tfa-codes__footer">
            <template #right>
                <button
                    type="button"
                    :title="$t('dashboard.common.action.continue.title')"
                    @click="close"
                >
                    {{ $t('dashboard.common.action.continue.text') }}
                </button>
            </template>
        </OtCardFooter>
    </OtCard>
</template>

<script setup lang="ts">
import type { DialogController, ModalController } from '@openticket/vue-dashboard-components';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { inject, ref } from 'vue';
import ModalHeader from '../modal/ModalHeader.vue';
import { injectOrFail } from '../../services/util';
import type { TfaSetupProvider } from './types';
import { useClipboard, useLocalization } from '../../composables';

type Props = {
    codes: number[],
}

type Emits = {
    (e: 'close'): void
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const dialog = injectOrFail<DialogController>('dialog');
const modal = inject<ModalController | null>('modal', null);
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const tfaSetupProvider = injectOrFail<TfaSetupProvider>('TfaSetupProvider');

const copied = ref<boolean>(false);

const { clipboard } = useClipboard();
const { t } = useLocalization();

void (() => {
    if (modal) {
        modal.registerBeforeClose(async () =>
            // Only close code modal when user confirm they have saved the recovery codes
            // eslint-disable-next-line implicit-arrow-linebreak
            dialog.confirm({
                title: t('dashboard.user.tfa.setup.code.confirm_close.title'),
                description: t('dashboard.user.tfa.setup.code.confirm_close.description'),
                type: 'is-info',
            }));
    }
})();

const close = (): void => {
    emit('close');
};

const saveContent = () => {
    const link = document.createElement('a');
    link.download = `${whitelabel.name} - recovery codes.txt`;
    link.href = `data:,${props.codes.join(', ')}`;
    link.click();
};

const copyContent = async () => {
    await clipboard.writeText(props.codes.join(', ')).then(() => {
        copied.value = true;
        setTimeout(() => {
            copied.value = false;
        }, 1500);
    });
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins.scss";

.tfa-codes {
    border-radius: var(--ot-spacing-xs);

    &__header__success {
        margin-top: .5rem;
        color: var(--ot-color-accent-green-dark);
    }

    &__explanation-label {
        font-size: 1rem;
        margin-bottom: var(--ot-spacing-default);
        color: var(--ot-color-core-foreground-secondary);
    }

    &__download {
        margin-bottom: var(--ot-spacing-3xl);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--ot-spacing-xs);

        @include breakpoint(mob) {
            margin-bottom: 0;
        }

        button {
            @include breakpoint(mob) {
                width: 100%;
            }
            & > div {
                display: inline-flex;

                &.copied_success {
                    color: var(--ot-color-accent-green-dark);
                }
            }
        }
    }

    &__codes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        margin-bottom: var(--ot-spacing-2xl);

        &__code {
            font-weight: 500;
            font-size: 26px;
            line-height: 32px;
            margin-bottom: var(--ot-spacing-xs);
        }
    }

    &__footer {
        @include breakpoint(mob) {
            background: var(--ot-color-core-white) !important;
            flex-direction: column;
            gap: var(--ot-spacing-xs);
        }

        button {
            @include breakpoint(mob) {
                width: 100%;
            }
        }
    }
}

::v-deep {
    & .card-footer__left,
    & .card-footer__right {
        & > * {
            @include breakpoint(mob) {
                margin: 0 !important;
            }
        }
    }

    & .card-footer__left {
        @include breakpoint(mob) {
            order: 3;
        }
    }

    & .card-footer__right {
        @include breakpoint(mob) {
            order: 1;
        }
    }
}
</style>
