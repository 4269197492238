<template>
    <OtInlineModal
        ref="modalRef"
        width="800"
    >
        <ErrorView
            v-if="error"
            :label="error.message"
        />

        <OtCard
            v-else-if="invite && rules"
            data-testid="invitemember-modalcard"
        >
            <OtCardHeader :title="$t('dashboard.company.members.invite.title_new_member')" />
            <OtCardContent>
                <OtFormRow>
                    <InputField
                        :label="$t('dashboard.common.input.name')"
                        :required="rules.name && rules.name.includes('required')"
                        :optional="rules.name && rules.name.includes('optional')"
                        :error="$t(validationError.name, validationRules.name)"
                    >
                        <OtInput
                            v-model="invite.$data.name"
                            class="input"
                            type="text"
                            data-testid="invitemember-form-name"
                            :invalid="!!validationError.name"
                        />
                    </InputField>

                    <InputField
                        :label="$t('dashboard.common.input.role')"
                        :required="rules.roles && rules.roles.includes('required')"
                        :optional="rules.roles && rules.roles.includes('optional')"
                        :error="$t(validationError.roles, validationRules.roles)"
                    >
                        <OtInput
                            v-model="invite.$data.roles"
                            class="input"
                            type="select"
                            :options="roles"
                            data-testid="invitemember-form-role"
                            :invalid="!!validationError.roles"
                            @input="onRolesChange"
                        />
                    </InputField>
                </OtFormRow>

                <InputField
                    :label="$t('dashboard.common.input.email')"
                    :required="rules.email && rules.email.includes('required')"
                    :optional="rules.email && rules.email.includes('optional')"
                    :error="$t(validationError.email, validationRules.email)"
                >
                    <OtInput
                        v-model="invite.$data.email"
                        class="input"
                        type="email"
                        data-testid="invitemember-form-email"
                        :invalid="!!validationError.email"
                    />
                </InputField>
            </OtCardContent>
            <OtCardFooter>
                <template #left>
                    <button
                        class="ot-button is-dark"
                        :title="$t('dashboard.common.action.cancel.title')"
                        @click="close"
                    >
                        <OtIcon
                            type="close"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.cancel.text') }}
                    </button>
                </template>
                <template #right>
                    <button
                        class="ot-button"
                        :title="$t('dashboard.company.members.invite.send_invite.title')"
                        data-testid="invitemember-form-submit"
                        @click="submit"
                    >
                        <OtIcon
                            type="check"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.company.members.invite.send_invite.text') }}
                    </button>
                </template>
            </OtCardFooter>
        </OtCard>

        <OtSpinner v-else />
    </OtInlineModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { AuthClient, Invitee, MixinAPIAuthError } from '@openticket/lib-auth';
import type { ValidationMessages } from '@openticket/lib-crud';
import type VueNotifications from '@openticket/vue-notifications';
import { isAxiosError } from 'axios';
import InputField from '../../../../components/form/InputField.vue';
import type SimpleFormModal from '../../../../components/modal/SimpleFormModal.vue';
import { injectOrFail } from '../../../../services/util';
import type { PluginsManager } from '../../../../plugins';
import type { Context } from '../../../../services/context';
import { isErrorDescriptionAxiosError } from '../../../../services/http/axios';
import { useGenericErrorHandling, useLocalization } from '../../../../composables';

const { error, handleError } = useGenericErrorHandling();
const { t } = useLocalization();

const context = injectOrFail<Context>('context');
const notifications = injectOrFail<VueNotifications>('notifications');
const plugins = injectOrFail<PluginsManager>('plugins');

const invite = ref<Invitee<AuthClient>>();
const modalRef = ref<InstanceType<typeof SimpleFormModal> | null>(null);
const rules = ref<ValidationMessages>();

let auth: AuthClient;

// TODO remove once validation is standardize
const validationError = ref<{
    name: string | null;
    email: string | null;
    roles: string | null;

}>({
    name: null,
    email: null,
    roles: null,
});

// TODO remove once validation is standardize
const validationRules = ref<{
    name: {
        [key: string]: string;
    };
    email: {
        [key: string]: string;
    };
    roles: {
        [key: string]: string;
    };
} >({
    name: { attribute: t('dashboard.common.input.name') },
    email: { attribute: t('dashboard.common.input.email') },
    roles: { attribute: t('dashboard.common.input.role') },
});

const roles: Record<string, string> = {
    // TODO Extract to lib auth...
    //  Preferably through an endpoint, but at the very least through constants.
    'Is In Company': t('dashboard.members.role.view_only'),
    'Company Admin': t('dashboard.members.role.manager'),
};

function close() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    modalRef.value?.close();
}

async function open() {
    try {
        if (!context.isCompanyContext()) {
            // TODO Proper error + logging
            throw Error('Cannot invite a new member when not in company context');
        }

        auth = await plugins.auth.loading;

        rules.value = await auth.invitees.$rules();

        invite.value = auth.invitees.$factory({
            email: '',
            name: '',
            roles: [ 'Is In Company' ],
        });

        validationError.value = {
            name: null,
            email: null,
            roles: null,
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value?.open();
    } catch (e) {
        void handleError(e);
    }
}

async function submit(): Promise<void> {
    if (!auth || !invite.value) {
        // TODO Proper error + logging
        console.error('Auth client or invite not initialized yet!');

        // TODO save.fail is not right here!!
        notifications.danger(t('dashboard.common.notification.save.fail'));

        throw new Error('Auth client or invite not initialized yet!');
    }

    try {
        validationError.value = {
            name: null,
            email: null,
            roles: null,
        };

        await auth.invitees.inviteUser(invite.value);

        // TODO The 204 status (already in company) is hidden behind the above method.
        //  It should be exposed as an error and then handled below in the catch.
        //  It should not simply be ignored, due to a potential difference in roles.
        //  this.$notifications.success(this.$t('dashboard.company.members.invite.already_in_company'));
    } catch (e) {
        // TODO
        if (!isAxiosError(e) || !isErrorDescriptionAxiosError(e)) {
            // TODO Remove typecasting
            const err = e as MixinAPIAuthError;

            if (err.axiosError && isAxiosError(err.axiosError) && err.axiosError.response?.status === 406) {
                // TODO remove once validation is standardize
                interface errorAnswers {
                    error_description: {
                        name: string[],
                        email: string[],
                        roles: string[]
                    }
                }

                const { error_description } = err.axiosError.response.data as errorAnswers;

                if (error_description.name && error_description.name.length > 0) {
                    [ validationError.value.name ] = error_description.name;
                }

                if (error_description.email && error_description.email.length > 0) {
                    [ validationError.value.email ] = error_description.email;
                }

                if (error_description.roles && error_description.roles.length > 0) {
                    [ validationError.value.roles ] = error_description.roles;
                }

                return;
            }
            notifications.danger(t('dashboard.company.members.invite.send_invite.danger'));

            throw e;
        } else {
            notifications.danger(t('dashboard.company.members.invite.send_invite.danger'));
        }
        throw e;
    }

    notifications.success(t('dashboard.company.members.invite.send_invite.success'));
    close();
}

// TODO This should probably be rethought.
function onRolesChange(value: string): void {
    if (!invite.value) {
        return;
    }

    invite.value.$data.roles = [ value ];
}

defineExpose({
    open,
});
</script>

<style lang="scss" scoped>
.inline-modal {
    &::v-deep {
        .inline-modal__content {
            padding-bottom: var(--ot-spacing-xl)
        }

        // < @todo : migrate to dashboard-components
        .card {
            overflow: visible;
            border-radius: 12px;
        }
        .card-footer {
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
        }
        // />

        .card-content {
            padding-bottom: var(--ot-spacing-4xl);
        }
    }
}
</style>
