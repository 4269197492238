<template>
    <FormModal
        :form="form"
        :modal="modal"
        :title="$t('dashboard.addon_products.product_group.edit.title')"
        :subtitle="$t('dashboard.addon_products.product_group.edit.intro')"
        :submit-button-labels="{
            text: $t('dashboard.addon_products.product_group.edit.submit.text'),
            title: $t('dashboard.addon_products.product_group.edit.submit.title')
        }"
    >
        <AddonProductGroupForm :form="form" />
    </FormModal>
</template>

<script setup lang="ts">
import { reactive, type UnwrapNestedRefs } from 'vue';
import type { ManagementClient, ProductGroup, Ticket } from '@openticket/lib-management';
import FormModal from '../../../../components/form/FormModal.vue';
import { type FormModalComposableResult, useUpdateForm } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import AddonProductGroupForm from '../forms/AddonProductGroupForm.vue';

type Props = {
    modal: UnwrapNestedRefs<FormModalComposableResult>,
    productGroup: UnwrapNestedRefs<ProductGroup<ManagementClient> | ProductGroup<Ticket<ManagementClient>>>,
}

type Emits = {
    (e: 'submit', model: ProductGroup<ManagementClient>): void
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

if (!context.isTicketContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const form = reactive(useUpdateForm<ProductGroup<ManagementClient>, ManagementClient>(
    props.productGroup,
    management.productGroups,
    {
        onSubmit(model: ProductGroup<ManagementClient>) {
            emit('submit', model);
        },
    },
));
</script>
