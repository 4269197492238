<template>
    <div class="company-bank-info">
        <div class="company-bank-info__header">
            <h3>{{ $t('dashboard.company.edit.bank_info.page_title') }}</h3>
            <span class="ot-text-body">{{ $t('dashboard.company.edit.bank_info.page_description') }}</span>
        </div>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.company.edit.bank_info.form.bank_account_name.label')"
                :description="$t('dashboard.company.edit.bank_info.form.bank_account_name.description')"
                :required="form.rules.bank_account_name?.includes('required')"
                :optional="form.rules.bank_account_name?.includes('optional')"
                :error="form.errors.bank_account_name"
            >
                <OtInput
                    v-model="form.model.$data.bank_account_name"
                    :invalid="form.errors.bank_account_name?.length > 0"
                    class="input"
                    data-testid="company-bank-info-form-bank-account-name"
                    type="text"
                    @input="form.errors.bank_account_name = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.company.edit.bank_info.form.bank_account_number.label')"
                :description="$t('dashboard.company.edit.bank_info.form.bank_account_number.description')"
                :required="form.rules.bank_account_number?.includes('required')"
                :optional="form.rules.bank_account_number?.includes('optional')"
                :error="form.errors.bank_account_number"
            >
                <OtInput
                    v-model="form.model.$data.bank_account_number"
                    :invalid="form.errors.bank_account_number?.length > 0"
                    :placeholder="$t('dashboard.company.edit.bank_info.form.bank_account_number.placeholder')"
                    class="input"
                    data-testid="company-bank-info-form-bank-account-number"
                    type="text"
                    @input="form.errors.bank_account_number = []"
                />
            </InputField>

            <InputField
                :label="$t('dashboard.company.edit.bank_info.form.bank_account_bic.label')"
                :description="$t('dashboard.company.edit.bank_info.form.bank_account_bic.description')"
                :required="form.rules.bank_account_bic?.includes('required')"
                :optional="form.rules.bank_account_bic?.includes('optional')"
                :error="form.errors.bank_account_bic"
            >
                <OtInput
                    v-model="form.model.$data.bank_account_bic"
                    :invalid="form.errors.bank_account_bic?.length > 0"
                    class="input"
                    data-testid="company-bank-info-form-bank-account-bic"
                    type="text"
                    @input="form.errors.bank_account_bic = []"
                />
            </InputField>
        </OtFormRow>

        <div class="ot-separator" />

        <div class="company-bank-info__header">
            <h3>{{ $t('dashboard.company.edit.bank_info.second_title') }}</h3>
            <span class="ot-text-body">{{ $t('dashboard.company.edit.bank_info.second_description') }}</span>
        </div>

        <OtFormRow>
            <!-- TODO: Update error to have correct payout threshold label -->
            <InputField
                :label="$t('dashboard.company.edit.bank_info.form.payout_frequency.label')"
                :description="$t('dashboard.company.edit.bank_info.form.payout_frequency.description')"
                :required="form.rules.payout_frequency?.includes('required')"
                :optional="form.rules.payout_frequency?.includes('optional')"
                :error="[...(form.errors.payout_frequency || []), ...(form.errors.payout_threshold || [])]"
            >
                <OtInput
                    v-model="form.model.$data.payout_frequency"
                    :disabled="form.model.$data.payout_frequency === 'disabled_by_admin' && !roles.isSuperAdmin"
                    :invalid="form.errors.payout_frequency?.length > 0"
                    :options="payoutOptions"
                    class="input"
                    data-testid="company-bank-info-form-payout-frequency"
                    type="select"
                    @input="form.errors.payout_frequency = []"
                />

                <OtInput
                    v-if="form.model.$data.payout_frequency === 'threshold'"
                    v-model="form.model.$data.payout_threshold"
                    :invalid="form.errors.currency?.length > 0"
                    :suffix="form.model.$data.currency"
                    class="input"
                    data-testid="company-bank-info-form-payout-threshold"
                    type="float"
                    @input="form.errors.payout_threshold = []"
                />
            </InputField>

            <InputField
                :label="$t('dashboard.company.edit.bank_info.form.payout_reference.label')"
                :description="$t('dashboard.company.edit.bank_info.form.payout_reference.description')"
                :required="form.rules.payout_reference?.includes('required')"
                :optional="form.rules.payout_reference?.includes('optional')"
                :error="form.errors.payout_reference"
            >
                <OtInput
                    v-model="form.model.$data.payout_reference"
                    :invalid="form.errors.payout_reference?.length > 0"
                    class="input"
                    data-testid="company-bank-info-form-payout-reference"
                    type="text"
                    @input="form.errors.payout_reference = []"
                />
            </InputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import { computed, type UnwrapNestedRefs } from 'vue';
import type { AuthClient, Company } from '@openticket/lib-auth';
import InputField from '../../../../components/form/InputField.vue';
import { injectOrFail } from '../../../../services/util';
import type { Roles } from '../../../../plugins/types';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { useLocalization } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Company<AuthClient>, Company<AuthClient>>>
}

const props = defineProps<Props>();

const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

const { t } = useLocalization();

const payoutOptions = computed<Record<string, string>>(() => {
    const options: Record<string, string> = {
        weekly: t('dashboard.company.edit.bank_info.form.payout_frequency.options.weekly'),
        monthly: t('dashboard.company.edit.bank_info.form.payout_frequency.options.monthly'),
    };

    // Threshold can only be chosen by super admins.
    if (props.form.model.$data.payout_frequency === 'threshold' || roles.isSuperAdmin) {
        options.threshold = t('dashboard.company.edit.bank_info.form.payout_frequency.options.threshold');
    }

    if (props.form.model.$data.payout_frequency === 'disabled_by_admin' || roles.isSuperAdmin) {
        options.disabled_by_admin = t('dashboard.company.edit.bank_info.form.payout_frequency.options.disabled_by_admin');
    }

    return options;
});
</script>

<style lang="scss" scoped>
.company-bank-info {
    &__header {
        margin-bottom: var(--ot-spacing-unit);
    }

    .ot-separator {
        margin: var(--ot-spacing-xl) 0;
    }
}
</style>
