<template>
    <div class="profile-locale-switch">
        <div
            class="profile-locale-switch__trigger"
            role="switch"
            tabindex="0"
            :aria-expanded="localeSwitchOpen"
            :aria-checked="localeSwitchOpen"
            @click="otLocaleSwitch.toggle()"
            @keydown.space="otLocaleSwitch.toggle()"
            @keydown.enter="otLocaleSwitch.toggle()"
        >
            <OtLocaleFlag
                class="profile-locale-switch__trigger__flag"
                :locale="$localization.locale"
                size="long"
                align-left
            />

            <OtIcon
                v-if="!localeSwitchOpen"
                type="drop-down"
                size="small"
            />
            <OtIcon
                v-if="localeSwitchOpen"
                type="drop-up"
                size="small"
            />
        </div>

        <OtLocaleSwitch
            ref="otLocaleSwitch"
            class="profile-locale-switch__switch"
            @opened="onLocaleSwitchOpened"
        />
    </div>
</template>

<script lang="ts" setup>
import { OtLocaleFlag, OtLocaleSwitch } from '@openticket/vue-localization';
import { ref } from 'vue';
import { injectOrFail } from '../../../../services/util';

const otLocaleSwitch = injectOrFail<typeof OtLocaleSwitch>('localization');
const localeSwitchOpen = ref<boolean>(false);

function onLocaleSwitchOpened(opened: boolean): void {
    localeSwitchOpen.value = opened;
}
</script>

<style lang="scss" scoped>
.profile-locale-switch {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    --ot-card-padding: var(--ot-spacing-lg);
    padding: .6em var(--ot-input-padding-x);
    font-weight: 500;
    color: var(--ot-input-color);
    border: var(--ot-input-border);
    border-radius: var(--ot-input-radius);
    box-shadow: var(--ot-input-shadow);
    user-select: none;

  &:has([aria-expanded]) {
    border-color: var(--ot-input-border-color-focus);
    box-shadow: 0 0 0 1px var(--ot-input-border-color-focus);
  }

    &__trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &__flag {
            flex-grow: 1;
        }

        .oti {
            margin-left: 0.5rem;
        }
    }

    &__switch {
        overflow: hidden;

        :deep(.locale-switch__container) {
            margin-top: var(--ot-spacing-default, 1rem);
        }
    }
}
</style>
