<template>
    <OtSimpleTile
        class="modules__shops__list__record"
        :record="record"
        :title="record.$data.name"
        clickable
        @click="goToShopPage(record.$data.guid)"
    >
        <template #asset>
            <img
                class="modules__shops__list__record-asset"
                src="../../../assets/images/shop_asset.svg"
                alt="Shop Asset"
            >
        </template>

        <template #title>
            <div class="modules__shops__list__record-title">
                <h3>{{ record.$data.name }}</h3>
            </div>
        </template>

        <template #column__-data__company_id>
            <a
                v-if="company"
                class="modules__shops__list__record-company-name ot-link ot-clickable"
                role="button"
                tabindex="0"
                @click.stop="$emit('context-switch', record.$data.company_id)"
                @keydown.space="$emit('context-switch', record.$data.company_id)"
                @keydown.enter="$emit('context-switch', record.$data.company_id)"
            >{{ company.name }}</a>
        </template>

        <template #column__url>
            <ShopUrl :record="record" />
        </template>

        <template #actions>
            <OtTileActions
                :buttons="
                    [{
                        title: $t('dashboard.shops.list.edit.title', { shop: record.$data.name }),
                        text: $t('dashboard.shops.list.edit.text', { shop: record.$data.name }),
                        icon: 'carrot-right',
                        classes: 'is-outline is-small',
                        clickHandler: () => goToShopPage(record.$data.guid)
                    }]"
            />
        </template>
    </OtSimpleTile>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import type VueRouter from 'vue-router';
import type {
    CompanyPath, Event, ManagementClient, Shop,
} from '@openticket/lib-management';
import { useRouter } from 'vue-router/composables';
import type { AuthClient, Company, CompanyRaw } from '@openticket/lib-auth';
import ShopUrl from './ShopUrl.vue';
import { injectOrFail } from '../../../services/util';

type Props = {
    record: Shop<ManagementClient | CompanyPath<ManagementClient> | Event<ManagementClient>>;
}

type Emits = {
    (e: 'context-switch', value: string): void
}

const props = defineProps<Props>();
defineEmits<Emits>();

const auth = injectOrFail<AuthClient>('auth');
const companies = ref<CompanyRaw[] | null>(null);
const router: VueRouter = useRouter();

void (async () => {
    // Retrieve companies from the token, to prevent fetching the companies
    // list. This assumes the user is only able to access companies
    // that are saved within the token.
    const token = await auth.$token.$info;

    if (token?.companies) {
        companies.value = token.companies;
    }
})();

function goToShopPage(shop: string): void {
    void router.push({
        name: 'shops.home',
        params: { shop },
    });
}

const company = computed<Company<AuthClient> | null>(() => {
    if (!props.record?.$data?.company_id) {
        return null;
    }

    return companies?.value?.find((singleCompany) => singleCompany.guid === props.record.$data.company_id);
});
</script>

<style scoped lang="scss">
@import '../../../assets/scss/mixins.scss';
.modules__shops__list__record {
    &-company-name::after {
        display: none;
    }

    &-asset {
        height: 100%;
        margin-right: var(--ot-spacing-xl);

        @include breakpoint(mob) {
            margin-right: var(--ot-spacing-xs);
        }

        & img {
            width: 100%;
            height: 100%;
        }
    }

    &-title {
        display: flex;

        h3 {
            flex: none;
        }
    }
}
</style>
