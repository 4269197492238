<template>
    <div class="date-asset ot-text-body">
        <span>
            {{ month }}
        </span>
        <span class="date-asset__day">
            {{ day }}
        </span>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useLocalization } from '../composables';

type Props = {
    date: Date | string;
}

const { l } = useLocalization();

const props = defineProps<Props>();

const month = computed(() => l.parts.dateShort(props.date).find((x) => x.type === 'month')?.value.toUpperCase());
const day = computed(() => l.parts.dateShort(props.date).find((x) => x.type === 'day')?.value);
</script>

<style scoped lang="scss">
.date-asset {
    border-radius: .5rem;
    width: 4rem;
    height: 4rem;
    background-color: var(--ot-color-core-light-foreground-primary);
    color: var(--ot-color-core-white);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__day {
        font-weight: 700;
        font-size: 1.125rem;
    }
}
</style>
