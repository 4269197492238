import {
    reactive, type VueConstructor,
} from 'vue';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import { definedFlags, localFlags } from '../services/featureFlag';
import type { DefinedFlags } from '../services/featureFlag/types';

export class FeatureFlagPlugin extends Plugin<FeatureFlagPlugin> {

    private localMode: boolean = import.meta.env.VITE_LOCAL_FEATURE_FLAG_ENABLED === 'true';

    plugins: PluginsManager | null = null;
    flags = reactive<DefinedFlags>(this.localMode ? localFlags : definedFlags);

    async install(plugins: PluginsManager, Vue: VueConstructor): Promise<void> {
        this.plugins = plugins;

        await this.init();

        Vue.mixin({
            provide:
            {
                featureFlags: this,
            },
        });
        this.resolve(this);
    }

    async init(): Promise<void> {
        const context = await this.plugins?.context.loading;

        await this._setFeatureFlagCompanyContext();

        context?.onChange(async (oldContext, newContext) => {
            if (oldContext?.company?.id !== newContext?.company?.id) {
                await this._setFeatureFlagCompanyContext(newContext?.company?.id);
            }
        });
    }

    private async _setFeatureFlagCompanyContext(companyId?: string): Promise<void> {
        if (!this.plugins || this.localMode) {
            return;
        }

        const rudderStack = await this.plugins.rudderstack.loading;
        const auth = await this.plugins.auth.loading;
        const user = (await auth.$token.$info)?.user;

        if (!rudderStack && !user && !window.posthog) {
            // eslint-disable-next-line no-console
            console.log('Keep default Feature Flags');
            return;
        }

        if (user && window.posthog) {
            const handler = (resolve: (value: unknown) => void) => {
                if (window.posthog?.featureFlags) {
                    window.posthog.featureFlags.addFeatureFlagsHandler((_, variants) => {
                        for (const key of Object.keys(this.flags)) {
                            this.flags[key] = typeof variants[key] === 'string' ? variants[key] === 'true' : variants[key];
                        }
                        resolve(this.flags);
                    });
                } else {
                    resolve(this.flags);
                }
            };

            const p = () => new Promise((resolve) => {
                handler(resolve);
            });

            rudderStack.identify(user.guid, {
                current_company_context: companyId || '',
            });

            await p().finally(() => {
                if (window.posthog && window.posthog.featureFlags) {
                    window.posthog.featureFlags.featureFlagEventHandlers = [];
                }
            });
        }
    }

}
