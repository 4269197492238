<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <OtSpinner v-else-if="!waitingListSignups" />
    <div
        v-else
        class="module__waiting-list__signup-list"
    >
        <OtDataGrid
            class="module__waiting-list__signup-list__grid"
            type="table"
            :pagination="waitingListSignups"
            :columns="filteredSignupColumns()"
            :empty-title="$t(emptyTitleSlug)"
            :empty-message="$t(emptyMessageSlug)"
            :empty-message-active-filter="$t('dashboard.common.filters.not_found')"
            :loading-message="$t('dashboard.waiting_list.list.header.loading')"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="waitingListSignups"
                    searchable
                    search-key="email"
                    :search-placeholder="$t('dashboard.waiting_list.signup_list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.waiting_list.signup_list')"
                >
                    <template #filters>
                        <OtFilters
                            :categories-to-hide="filterCategoriesToHide"
                            :labels="$t('models.waiting-list')"
                            :pagination="waitingListSignups"
                            :translations="$t('dashboard.common.filters')"
                            :view-filter="{ label: context.event?.name }"
                        />
                    </template>

                    <template #actions-top>
                        <div class="module__waiting-list__signup-list__actions">
                            <button
                                class="ot-button is-small is-outline"
                                data-testid="waiting-list-button-settings"
                                :title="$t('dashboard.waiting_list.signup_list.header.settings.title')"
                                @click="goToWaitingListSettings"
                            >
                                {{ $t('dashboard.waiting_list.signup_list.header.settings.text') }}
                                <OtIcon
                                    type="carrot-right"
                                    size="small"
                                    class="ot-button-icon--right"
                                />
                            </button>
                            <button
                                class="ot-button is-small"
                                data-testid="waiting-list-button-export"
                                :title="$t('dashboard.waiting_list.signup_list.header.export.title')"
                                @click="handleExport"
                            >
                                <OtIcon
                                    type="download"
                                    size="small"
                                    class="ot-button-icon--left"
                                />
                                {{ $t('dashboard.waiting_list.signup_list.header.export.text') }}
                            </button>
                        </div>
                    </template>
                </OtListHeader>
            </template>
            <template #empty-action-no-filter>
                <div
                    v-if="$te('dashboard.waiting_list.signup_list.empty.help_text')"
                >
                    <a
                        v-if="empty_help_link"
                        class="ot-link ot-clickable"
                        :href="empty_help_link"
                        target="_blank"
                    >
                        {{ $t('dashboard.waiting_list.signup_list.empty.help_text') }}
                    </a>
                    <span v-else>
                        {{ $t('dashboard.waiting_list.signup_list.empty.help_text') }}
                    </span>
                </div>
                <div
                    v-if="isDisabledBySettings"
                    class="module__waiting-list__signup-list__grid__empty-button-wrapper"
                >
                    <button
                        class="ot-button is-small is-outline"
                        :title="$t('dashboard.waiting_list.signup_list.empty.settings_button.title')"
                        @click="goToWaitingListSettings"
                    >
                        {{ $t('dashboard.waiting_list.signup_list.empty.settings_button.text') }}
                        <OtIcon
                            type="carrot-right"
                            size="small"
                            class="ot-button-icon--right"
                        />
                    </button>
                </div>
            </template>

            <template #column__-data__name="{ record }">
                {{ record.$data.first_name }} {{ record.$data.last_name }}
            </template>

            <template #column__-data__quantity="{ record }">
                {{ $tc('dashboard.waiting_list.signup_list.column_values.quantity', record.$data.quantity) }}
            </template>

            <template #column__-data__submitted="{ record }">
                <span :title="$l.dateTime(new Date(record.$data.created_at))">
                    {{ getPastRelativeTimeString(new Date(record.$data.created_at), localization.locale.language) }}
                </span>
            </template>

            <template #actions="{ record }">
                <div
                    class="ot-clickable module__waiting-list__signup-list__remove-button"
                    :title="$t('dashboard.waiting_list.signup_list.remove.title')"
                    role="button"
                    tabIndex="0"
                    @click="handleDelete(record.$data.guid)"
                    @keydown.space="handleDelete(record.$data.guid)"
                    @keydown.enter="handleDelete(record.$data.guid)"
                >
                    <OtIcon
                        type="close"
                        size="small"
                        class="ot-button-icon--left"
                    />
                </div>
            </template>
        </OtDataGrid>
    </div>
</template>

<script lang="ts" setup>
import type { Column, DialogController } from '@openticket/vue-dashboard-components';
import { computed, ref, watch } from 'vue';
import type { Pagination } from '@openticket/lib-crud';
import { isAxiosError } from 'axios';
import { useRouter } from 'vue-router/composables';
import type VueNotifications from '@openticket/vue-notifications';
import ErrorView from '../../../components/ErrorView.vue';
import type { Context } from '../../../services/context';
import { getPastRelativeTimeString, injectOrFail } from '../../../services/util';
import { useGenericErrorHandling, useLocalization } from '../../../composables';
import type { WaitingList, WaitingListSignup } from '../../../services/waiting-list/client/models';
import type { WaitingListClient } from '../../../services/waiting-list/client/waitingListClient';

const { error } = useGenericErrorHandling();
const { t, localization } = useLocalization();

const context = injectOrFail<Context>('context');
const dialog = injectOrFail<DialogController>('dialog');
const notifications = injectOrFail<VueNotifications>('notifications');

const router = useRouter();

const empty_help_link = t('dashboard.waiting_list.signup_list.empty.help_link');

const columns: Array<Column> = [
    {
        key: [ '$data', 'name' ],
        type: 'custom',
        label: t('dashboard.waiting_list.signup_list.columns.name'),
    },
    {
        key: [ '$data', 'email' ],
        type: 'email',
        label: t('models.waiting_list_signup.attributes.email.label'),
    },
    {
        key: [ '$data', 'quantity' ],
        type: 'custom',
        label: t('dashboard.waiting_list.signup_list.columns.quantity'),
    },
    {
        key: [ '$data', 'submitted' ],
        type: 'custom',
        label: t('dashboard.waiting_list.signup_list.columns.created_at'),
    },
    {
        key: [ '$data', 'reseller_ticketswap_enabled' ],
        type: 'boolean',
        label: t('dashboard.waiting_list.signup_list.columns.reseller_ticketswap_enabled'),
        hideInTable: true,
    },
];

const filterCategoriesToHide = [
    'updated_at',
    'deleted_at',
];

const waitingListClient = injectOrFail<WaitingListClient>('waitingListClient');
const waitingListSettings = ref<WaitingList<WaitingListClient>>();
const waitingListSignups = ref<Pagination<WaitingListSignup<WaitingList<WaitingListClient>>>>();

const isDisabledBySettings = computed<boolean>(
    () => !!(waitingListSettings.value && waitingListSettings.value.$data && !waitingListSettings.value.$data.enabled),
);

const emptyTitleSlug = computed<string>(() => {
    if (waitingListSignups.value && !!Object.keys(waitingListSignups.value.filters.active ?? {}).length) {
        return 'dashboard.waiting_list.signup_list.empty.title_filtered';
    }
    return 'dashboard.waiting_list.signup_list.empty.title';
});

const emptyMessageSlug = computed<string>(() => {
    if (isDisabledBySettings.value) {
        return 'dashboard.waiting_list.signup_list.empty.message';
    }
    return '';
});

watch(() => context.event?.id, (value) => {
    if (value) {
        if (context.company && context.company.id) {
            void loadSignupList(value, context.company.id);
        }
    }
});

async function loadSignupList(eventId: string, companyId: string) {
    if (!eventId) {
        return;
    }
    try {
        // Replace with backend findOrCreate when available
        waitingListSettings.value = await waitingListClient.findOrCreate(eventId, companyId);

        if (waitingListSettings.value && waitingListSettings.value.$data) {
            try {
                waitingListSignups.value = waitingListSettings.value.signups.list();
                await waitingListSignups.value.initialization;
            } catch (listError) {
                if (!isAxiosError(listError) || listError.response?.status !== 404) {
                    throw (listError);
                }
            }
        }
    } catch (e) {
        // TODO Proper error + logging
        console.error('Waiting list could not be loaded or created');
        notifications.danger(t('dashboard.common.generic_error'));
    }
}

void (async () => {
    if (context.event && context.event.id && context.company && context.company.id) {
        await loadSignupList(context.event.id, context.company.id);
    }
})();

async function handleDelete(guidToDelete: string) {
    if (guidToDelete && context.event && context.event.id) {
        const confirm = await dialog?.confirm({
            title: t('dashboard.waiting_list.signup_list.confirm.remove_signup.title') || '',
            description: t('dashboard.waiting_list.signup_list.confirm.remove_signup.description') || '',
            confirmButton: t('dashboard.waiting_list.signup_list.confirm.remove_signup.confirm_button') || '',
            type: 'is-danger',
        });

        if (!confirm || !waitingListSignups.value || !waitingListSettings.value) {
            return;
        }

        const signupToDelete = waitingListSignups.value?.records.find((signup) => signup.$data.guid === guidToDelete);
        if (signupToDelete) {
            await waitingListSettings.value?.signups.delete(signupToDelete);
            await loadSignupList(context.event.id, context.company.id);
        }
    }
}

function goToWaitingListSettings(): void {
    if (!context.event || !context.event.id) {
        return;
    }

    void router.push({
        name: 'waiting_list.settings',
        params: { event: context.event.id, waitingList: waitingListSettings.value.$data.guid },
    });
}

async function handleExport(): Promise<void> {
    if (!context.event || !context.event.id || !waitingListSignups.value) {
        return;
    }

    await waitingListSettings.value?.signups.exportList(context.event.name || '', waitingListSignups.value?.filters?.active);
}

function filteredSignupColumns(): typeof columns {
    const columnsToHide: string[] = [];
    if (!waitingListSettings.value || !waitingListSettings.value.$data.allow_quantity) {
        columnsToHide.push('quantity');
    }

    if (!waitingListSettings.value || !waitingListSettings.value.$data.allow_reseller_ticketswap) {
        columnsToHide.push('reseller_ticketswap_enabled');
    }

    return columns.filter((c) => c.key && c.key.length > 1 && typeof c.key[1] === 'string' && !columnsToHide.includes(c.key[1]));
}
</script>

<style lang="scss" scoped>
.module__waiting-list__signup-list {
    padding-top: var(--ot-spacing-default);

    &__actions {
        display: flex;
        align-items: center;
        gap: var(--ot-spacing-unit);
    }

    &__filters {
        display: flex;
        flex-direction: row;
        gap: var(--ot-spacing-default);
    }

    &__remove-button:hover {
        color: var(--ot-color-accent-orange-dark);
    }

    &__grid__empty-button-wrapper {
        margin-top: var(--ot-spacing-default);
        display: flex;
        justify-content: center;
    }

}
</style>
