<template>
    <div
        v-if="settings"
        class="shop-settings__shop"
    >
        <h2 class="has-margin">
            {{ $t('dashboard.shop.settings.shop.title') }}
        </h2>
        <h5 class="ot-text-tiny has-margin subtitle">
            {{ $t('dashboard.shop.settings.shop.description') }}
        </h5>

        <OtInputField
            :label="$t('dashboard.shop.settings.shop.pricing.title')"
            :description="$t('dashboard.shop.settings.shop.pricing.description')"
        >
            <OtInput
                v-model="settings.static.shop.enableCoupons"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.pricing.coupons.label')"
            />
        </OtInputField>

        <OtInputField
            :label="$t('dashboard.shop.settings.shop.timeslots.title')"
            :description="$t('dashboard.shop.settings.shop.timeslots.description')"
        >
            <OtInput
                v-model="settings.static.shop.enableTimeslotsFilters"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.timeslots.enable_timeslots_filters.label')"
            />
        </OtInputField>

        <OtInputField
            v-if="roles.isWhitelabelAdmin"
            :label="$t('dashboard.shop.settings.shop.seats.title')"
            :description="$t('dashboard.shop.settings.shop.seats.description')"
        >
            <p>For now these are admin only as well, after all translations are added, they could be opened to everyone.</p>
            <OtInput
                v-model="settings.static.shop.seats.hideLegend"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.seats.hide_legend.label')"
            />
            <OtInput
                v-model="settings.static.shop.seats.hideSectionsNotForSale"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.seats.hide_sections_not_for_sale.label')"
            />
            <OtInput
                v-model="settings.static.shop.seats.tooltip.hideActionHint"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_action_hint.label')"
            />
            <OtInput
                v-model="settings.static.shop.seats.tooltip.hideAvailability"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_availability.label')"
            />
            <OtInput
                v-model="settings.static.shop.seats.tooltip.hideCategory"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_category.label')"
            />
            <OtInput
                v-model="settings.static.shop.seats.tooltip.hideLabel"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_label.label')"
            />
            <OtInput
                v-model="settings.static.shop.seats.tooltip.hidePricing"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_pricing.label')"
            />
            <OtInput
                v-model="settings.static.shop.seats.tooltip.hideUnavailableNotice"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.seats.tooltip.hide_unavailable_notice.label')"
            />
        </OtInputField>

        <OtInputField
            v-if="roles.isWhitelabelAdmin"
            :label="$t('dashboard.shop.settings.shop.admin.title')"
            :description="$t('dashboard.shop.settings.shop.admin.description')"
        >
            <OtInput
                v-model="settings.static.shop.blockFrameAccess"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.admin.block_frame_access.label')"
            />

            <OtInput
                v-model="settings.static.shop.rememberTerms"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.admin.remember_terms.label')"
            />

            <OtInput
                v-model="settings.static.shop.footer.showPoweredBy"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.admin.footer.showPoweredBy.label')"
            />

            <OtInput
                v-model="settings.static.shop.footer.showLocaleSwitcher"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.admin.footer.showLocaleSwitcher.label')"
            />

            <OtInput
                v-model="settings.static.shop.showReservationTimer"
                type="toggle"
                :label="$t('dashboard.shop.settings.shop.admin.showReservationTimer.label')"
            />
        </OtInputField>
    </div>
</template>

<script setup lang="ts">
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import type { UnwrapNestedRefs } from 'vue';
import { injectOrFail } from '../../../../services/util';
import type { Roles } from '../../../../plugins/types';

const settings = injectOrFail<CustomShopSettingsClient>('settings');
const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');
</script>

<style lang="scss" scoped>
.shop-settings__shop {
    .ot-input-field > .ot-toggle:not(:last-child) {
        margin-bottom: .375rem;
    }

    .subtitle {
        color: var(--ot-color-core-foreground-secondary);
    }
}
</style>
