<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <OtSpinner v-else-if="loading" />

    <div
        v-else
        class="shop-styling"
    >
        <div class="shop-styling__tabs ot-mb[lg]">
            <div class="shop-styling__tabs-items">
                <VerticalTabs>
                    <template #default="{ selected }">
                        <!--
                            TODO CU-86c0a8wx8: Replace hardcoded translation below with slug
                            'dashboard.shops.styling.template.route_title'
                         -->
                        <VerticalTabsItem
                            label="Template"
                            :route="{ name: 'shops.styling.template' }"
                            :selected="selected"
                        >
                            <ShopsSettingsStyle view="template" />
                        </VerticalTabsItem>

                        <!--
                            TODO CU-86c0a8wx8: Replace hardcoded translation below with slug
                            'dashboard.shops.styling.colors.route_title'
                         -->
                        <VerticalTabsItem
                            label="Colors"
                            :route="{ name: 'shops.styling.colors' }"
                            :selected="selected"
                        >
                            <ShopsSettingsStyle view="colors" />
                        </VerticalTabsItem>
                    </template>
                </VerticalTabs>
            </div>
            <div class="shop-styling__tabs__preview">
                <div class="shop-styling__tabs__preview-container">
                    <OtPreview
                        ref="previewRef"
                        :header-label="$t('dashboard.tickets.ticket_design.preview.header')"
                        :src="shopUrl"
                        type="iframe"
                        @load="onLoadPreview"
                    />
                </div>
            </div>
        </div>
        <div class="shop-styling__footer">
            <OtPageFooter>
                <template #left>
                    <button
                        class="ot-button is-dark"
                        @click="$router.back()"
                    >
                        <OtIcon type="arrow-left" />
                        {{ $t('dashboard.common.action.back.text') }}
                    </button>
                </template>
                <template #right>
                    <button
                        class="ot-button"
                        :disabled="!hasLocalChanges"
                        @click="save"
                    >
                        <OtIcon type="check" />
                        {{ $t('dashboard.common.action.save.text') }}
                    </button>
                </template>
            </OtPageFooter>
        </div>
    </div>
</template>

<script setup lang="ts">
import { type OtPreview } from '@openticket/vue-dashboard-components';
import { injectOrFail } from '../../../services/util';
import ErrorView from '../../../components/ErrorView.vue';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import ShopsSettingsStyle from '../components/settings/ShopsSettingsStyle.vue';
import { shopSettingsConfigKey } from '../keys';

const {
    error,
    hasLocalChanges,
    loading,
    onLoadPreview,
    previewRef,
    save,
    shopUrl,
} = injectOrFail(shopSettingsConfigKey);
</script>

<style lang="scss" scoped>
.shop-styling {
    &__header {
        border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);

        h1 {
            min-height: 2.25rem;
        }
    }

    &__tabs {
        display: flex;
        flex-direction: row;
        gap: var(--ot-spacing-3xl);

        &-items {
            flex: 4;
        }

        &__preview {
            @media (max-width: 75rem) {
                display: none;
            }

            flex: 2;

            &-container {
                position: sticky;
                top: calc(var(--ot-layout-header-height, 5.5rem) + 1rem);
                right: 0;
                height: calc(80vh - var(--ot-layout-header-height, 5.5rem) + 1rem);
                min-height: 50vh;

                &::v-deep {
                    .preview {
                        min-height: 50vh;
                    }
                }
            }
        }
    }

    &__footer {
        button {
            gap: var(--ot-spacing-sm)
        }
    }
}
</style>
