<template>
    <div class="addon-product-form-fields">
        <OtFormRow>
            <InputField
                :label="$t('dashboard.addon_products.product_group.inputs.name.label')"
                :description="$t('dashboard.addon_products.product_group.inputs.name.description')"
                :required="form.rules.name?.includes('required')"
                :error="form.errors.name"
            >
                <OtInputText
                    v-model="form.model.$data.name"
                    class="input"
                    data-testid="addon-product-form-fields-name"
                    @input="form.errors.name = []"
                />
            </InputField>
        </OtFormRow>
        <OtFormRow v-if="isCreate">
            <InputField
                :label="$t('dashboard.addon_products.link_product.input_label')"
                :description="$t('dashboard.addon_products.link_product.input_description')"
            >
                <LinkUnlinkPicker
                    ref="linkAddonProductFormRef"
                    :items-label="$t('dashboard.addon_products.link_product.items_label')"
                    :pagination-relation="context.event.model.products"
                />
            </InputField>
        </OtFormRow>
        <OtFormRow>
            <InputField
                :label="$t('dashboard.addon_products.product_group.inputs.preset.label')"
                :description="$t('dashboard.addon_products.product_group.inputs.preset.description')"
            >
                <OtInputSelect
                    v-model="preset"
                    :options="presets"
                    @input="onPreset"
                >
                    <template #singleLabel="{ option }">
                        {{ presets[option].title }}
                    </template>
                    <template #option="{ option }">
                        <div class="addon-product-form-fields__preset">
                            <div class="addon-product-form-fields__preset__image">
                                <img
                                    :alt="presets[option].title"
                                    :src="presets[option].image"
                                >
                            </div>
                            <div class="addon-product-form-fields__preset__text">
                                <div class="addon-product-form-fields__preset__title">
                                    {{ presets[option].title }}
                                </div>
                                <div class="addon-product-form-fields__preset__description">
                                    {{ presets[option].description }}
                                </div>
                            </div>
                        </div>
                    </template>
                </OtInputSelect>
            </InputField>
        </OtFormRow>
        <OtFormRow>
            <InputField
                :label="$t('dashboard.addon_products.product_group.inputs.min_bound.label')"
                :description="$t('dashboard.addon_products.product_group.inputs.min_bound.description')"
                :required="form.rules.min_bound?.includes('required')"
                :error="form.errors.min_bound"
            >
                <OtInputFloat
                    v-model="form.model.$data.min_bound"
                    :min="0"
                    class="addon-product-form-fields__integer"
                    data-testid="addon-product-form-fields-min-bound"
                    @input="setPresetCustomAndResetErrors('min_bound')"
                />
            </InputField>
        </OtFormRow>
        <OtFormRow>
            <InputField
                :label="$t('dashboard.addon_products.product_group.inputs.max_bound.label')"
                :description="$t('dashboard.addon_products.product_group.inputs.max_bound.description')"
                :required="form.rules.max_bound?.includes('required')"
                :error="form.errors.max_bound"
            >
                <InputFloatUnlimited
                    v-model="form.model.$data.max_bound"
                    :force-unlimited="isMaxBoundUnlimited"
                    :min="0"
                    data-testid="addon-product-form-fields-max-bound"
                    size="small"
                    type="integer"
                    @input="setPresetCustomAndResetErrors('max_bound')"
                />
            </InputField>
        </OtFormRow>
        <OtFormRow>
            <InputField
                :label="$t('dashboard.addon_products.product_group.inputs.uniqueness.label')"
                :description="$t('dashboard.addon_products.product_group.inputs.uniqueness.description')"
                :required="form.rules.uniqueness?.includes('required')"
                :error="form.errors.uniqueness"
            >
                <InputFloatUnlimited
                    v-model="form.model.$data.uniqueness"
                    :force-unlimited="isUniquenessUnlimited"
                    :min="0"
                    data-testid="addon-product-form-fields-uniqueness"
                    size="small"
                    type="integer"
                    @input="setPresetCustomAndResetErrors('uniqueness')"
                />
            </InputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import {
    computed, ref, watch, type UnwrapNestedRefs,
} from 'vue';
import type { ManagementClient, ProductGroup } from '@openticket/lib-management';
import type { Context } from '../../../../services/context';
import InputField from '../../../../components/form/InputField.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import SelectMultipleSvg from '../../../../assets/images/product_group_can_select_multiple.svg';
import SelectOneSvg from '../../../../assets/images/product_group_can_select_one.svg';
import ForcedSelectionSvg from '../../../../assets/images/product_group_forced_selection.svg';
import CustomSelectionSvg from '../../../../assets/images/product_group_custom_selection.svg';
import InputFloatUnlimited from '../../../../components/InputFloatUnlimited.vue';
import LinkUnlinkPicker from '../../../../components/modal/link-unlink/LinkUnlinkPicker.vue';
import { useLocalization } from '../../../../composables';

type Preset = {
    title: string;
    description: string;
    image: string;
}

type Presets = 'select_one' | 'select_multiple' | 'forced_selection' | 'custom_selection';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<ProductGroup<ManagementClient>, ManagementClient>>;
    isCreate?: boolean;
}

const props = defineProps<Props>();

const context = injectOrFail<Context>('context');

const { t } = useLocalization();

const isMaxBoundUnlimited = ref<boolean>(false);
const isUniquenessUnlimited = ref<boolean>(false);
const preset = ref<string>('custom_selection');
const linkAddonProductFormRef = ref<InstanceType<typeof LinkUnlinkPicker>>();
const presets = ref<Record<Presets, Preset>>({
    select_one: {
        title: t('dashboard.addon_products.product_group.presets.select_one.title'),
        description: t('dashboard.addon_products.product_group.presets.select_one.description'),
        image: SelectOneSvg,
    },
    select_multiple: {
        title: t('dashboard.addon_products.product_group.presets.select_multiple.title'),
        description: t('dashboard.addon_products.product_group.presets.select_multiple.description'),
        image: SelectMultipleSvg,
    },
    forced_selection: {
        title: t('dashboard.addon_products.product_group.presets.forced_selection.title'),
        description: t('dashboard.addon_products.product_group.presets.forced_selection.description'),
        image: ForcedSelectionSvg,
    },
    custom_selection: {
        title: t('dashboard.addon_products.product_group.presets.custom_selection.title'),
        description: t('dashboard.addon_products.product_group.presets.custom_selection.description'),
        image: CustomSelectionSvg,
    },
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
const toLinkProducts = computed<string[] | undefined>(() => linkAddonProductFormRef.value?.diff.toLink);

watch(() => toLinkProducts.value, () => {
    if (toLinkProducts.value?.length) {
        props.form.hasLocalChanges = true;
    }
});

if (!context.isTicketContext()) {
    // TODO Proper error handling and logging
    throw Error('Invalid context');
}

function setPresetToCustom() {
    preset.value = 'custom_selection';
}

function setPresetCustomAndResetErrors(field: keyof typeof props.form.model.$data) {
    props.form.errors[field] = [];
    setPresetToCustom();
}

function onPreset(presetValue: Presets) {
    isMaxBoundUnlimited.value = false;
    isUniquenessUnlimited.value = false;
    switch (presetValue) {
        case 'select_one':
            props.form.model.$data.min_bound = 0;
            props.form.model.$data.max_bound = 1;
            props.form.model.$data.uniqueness = 1;
            break;
        case 'select_multiple':
            props.form.model.$data.min_bound = 0;
            props.form.model.$data.max_bound = 0;
            props.form.model.$data.uniqueness = 1;
            isMaxBoundUnlimited.value = true;
            break;
        case 'forced_selection':
            props.form.model.$data.min_bound = 1;
            props.form.model.$data.max_bound = 1;
            props.form.model.$data.uniqueness = 1;
            break;
        default:
            props.form.model.$data.min_bound = 0;
            props.form.model.$data.max_bound = 0;
            props.form.model.$data.uniqueness = 0;
            break;
    }
}

defineExpose({
    addonProductGuids: () => toLinkProducts.value,
});
</script>

<style scoped lang="scss">
.addon-product-form-fields {
    display: flex;
    flex-direction: column;

    &__integer {
        max-width: 10rem;
    }

    &__preset {
        display: flex;
        gap: var(--ot-spacing-unit);

        &__description {
            color: var(--ot-color-core-light-foreground-secondary);
            font-size: 0.813rem;
            font-weight: normal
        }

        &__image {
            width: 2.5rem;
            display: flex;

            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
}
</style>
