<template>
    <OtCard
        :no-padding="true"
        class="tfa-code-setup"
    >
        <ModalHeader
            :hide-logo="tfaSetupProvider.hideLogo"
            :hide-logout="tfaSetupProvider.hideLogout"
            text-centered
        >
            <template #title>
                <h1>{{ $t('dashboard.user.tfa.setup.code.title') }}</h1>
            </template>

            <template
                v-if="error.incorrectTFAInput || error.incorrectPassword"
                #extra
            >
                <h5 class="tfa-code-setup__warning warning ot-label">
                    {{ $t('dashboard.user.tfa.setup.warning') }}
                </h5>
            </template>
        </ModalHeader>

        <OtCardContent>
            <div>
                <OtInputField
                    :label="$t('dashboard.user.tfa.setup.password')"
                    :error="$t(error.incorrectPassword, { attribute: $t('dashboard.user.tfa.setup.attributes.password') })"
                >
                    <OtInput
                        v-model="password"
                        :label="$t('dashboard.user.tfa.setup.password')"
                        type="password"
                        name="password"
                        :invalid="!!error.incorrectPassword"
                    />
                </OtInputField>
            </div>
        </OtCardContent>

        <OtCardFooter class="tfa-code-setup__footer">
            <template #left>
                <button
                    class="ot-button is-dark"
                    type="button"
                    :title="$t('dashboard.common.action.back.title')"
                    @click="close()"
                >
                    <OtIcon
                        class="ot-button-icon--left"
                        type="arrow-left"
                    />
                    {{ $t('dashboard.common.action.back.text') }}
                </button>
            </template>
            <template #right>
                <button
                    class="ot-button"
                    :class="{'is-loading': submitting}"
                    type="button"
                    :disabled="!formCompleted || submitting"
                    :title="$t('dashboard.user.tfa.action.validate.title')"
                    @click="submit()"
                >
                    <OtIcon
                        class="ot-button-icon--left"
                        type="check"
                    />
                    {{ $t('dashboard.user.tfa.action.validate.text') }}
                </button>
            </template>
        </OtCardFooter>
    </OtCard>
</template>

<script setup lang="ts">
import type { TFAError } from '@openticket/vue-tfa-confirmation';
import { computed, ref } from 'vue';
import ModalHeader from '../../modal/ModalHeader.vue';
import { injectOrFail } from '../../../services/util';
import type { TfaSetupProvider } from '../types';

type Props = {
    submitting: boolean,
    error: TFAError
}

type Emits = {
    (e: 'close'): void,
    (e: 'submit', path: string, data: {password: string | null}): void
}

const props = withDefaults(
    defineProps<Props>(),
    {
        submitting: false,
        error: () => ({
            incorrectPassword: null,
            incorrectTFAInput: null,
        }),
    },
);
const emit = defineEmits<Emits>();

const tfaSetupProvider = injectOrFail<TfaSetupProvider>('TfaSetupProvider');

const password = ref<string | null>(null);
const formCompleted = computed(() => !!password.value);

const close = (): void => {
    emit('close');
};

const submit = (): void => {
    if (props.submitting) {
        return;
    }

    // TODO - @openticket/lib-auth
    emit(
        'submit',
        'twofactor/code/initialise',
        { password: password.value },
    );
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.tfa-code-setup {
    border-radius: var(--ot-spacing-xs);

    &__warning {
        margin-top: .5rem;
        color: var(--ot-color-accent-orange-dark);
    }

    &__footer {
        @include breakpoint(mob) {
            background: white !important;
            flex-direction: column;
            gap: var(--ot-spacing-xs);
        }

        button {
            @include breakpoint(mob) {
                width: 100%;
            }
        }
    }
}

::v-deep {
    & .card-footer__left,
    & .card-footer__right {
        & > * {
            @include breakpoint(mob) {
                margin: 0 !important;
            }
        }
    }

    & .card-footer__left {
        @include breakpoint(mob) {
            order: 3;
        }
    }

    & .card-footer__right {
        @include breakpoint(mob) {
            order: 1;
        }
    }
}
</style>
