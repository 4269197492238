<template>
    <div class="input-float-unlimited">
        <div
            v-show="unlimited"
            :class="{ 'input-float-unlimited__label--small': props.size === 'small' }"
            class="input-float-unlimited__label"
            @focusin="onUnlimitedTextFocus"
        >
            <OtInputText
                class="input"
                value="∞"
            />
        </div>

        <OtInputFloat
            v-show="!unlimited"
            ref="inputFloat"
            :class="{ 'input-float-unlimited__input--small': props.size === 'small' }"
            :min="props.min"
            :value="value"
            class="input-float-unlimited__input"
            data-testid="input-float-unlimited-input"
            @input="$emit('input', $event)"
        />

        <OtInputToggle
            :label="$t('dashboard.common.unlimited')"
            :value="unlimited"
            data-testid="input-float-unlimited-toggle"
            @input="handleToggle"
        />
    </div>
</template>

<script lang="ts" setup>
import { nextTick, ref, watch } from 'vue';
import type { OtInput } from '@openticket/vue-input';

type Props = {
    value: number,
    min?: number,
    size?: 'small',
    forceUnlimited?: boolean | undefined,
}

type Emits = {
    (e: 'input', value: number): void,
}

const props = withDefaults(defineProps<Props>(), {
    forceUnlimited: undefined,
    min: undefined,
    size: undefined,
});

const emit = defineEmits<Emits>();

const inputFloat = ref<InstanceType<typeof OtInput> | null>(null);
const unlimited = ref<boolean>(props.value === 0);

watch(() => props.forceUnlimited, (value) => {
    unlimited.value = value;
});

watch(() => props.value, () => {
    if (props.value === 0) {
        unlimited.value = true;
    }
});

async function onUnlimitedTextFocus() {
    unlimited.value = false;

    await nextTick();

    const inputElement = inputFloat.value?.$el.getElementsByTagName('input').item(0);
    inputElement?.focus();
}

function handleToggle() {
    unlimited.value = !unlimited.value;
    if (unlimited.value) {
        emit('input', 0);
    }
}
</script>

<style lang="scss" scoped>
.input-float-unlimited {
    display: flex;
    gap: var(--ot-spacing-unit);
    align-items: center;

    &__label {
        width: 100%;
    }

    &__input--small, &__label--small {
        max-width: 10rem;
    }
}
</style>
