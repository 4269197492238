import { ref } from 'vue';
import type VueNotifications from '@openticket/vue-notifications';
import { isOtError, send } from '@openticket/lib-log';
import type { LogLevel, OtError } from '@openticket/lib-log';
import type { PluginsManager } from '../plugins';
import { injectOrFail } from '../services/util/injectOrFail';
import { useLocalization } from './useLocalization';

type ErrorHandlerConfig = {
    thrownError: unknown;
    level?: LogLevel;
    showNotification?: boolean;
}

export function useGenericErrorHandling() {
    const notifications = injectOrFail<VueNotifications>('notifications');
    const plugins = injectOrFail<PluginsManager>('plugins');

    const { t } = useLocalization();

    const error = ref<Error | OtError | null>();

    function consoleWriter(e: OtError, prefix: string = ''): void {
        /* eslint-disable no-console */
        try {
            console.groupCollapsed(`${prefix}%cOT Error: ${e.slug}`, 'color: red;');
            console.debug(`${e.session} #${e.id} (sent: ${e.otSent ? 'yes' : 'no'})`);
            console.info(e.message);
            console.debug('href:', e.href);
            console.log('data:', { ...e.getLogContext(), _stack_: undefined });

            if (e.cause) {
                consoleWriter(e.cause, 'cause: ');
            }
        } catch (err) {
            console.error('Failed to write error to console.', { err });
        } finally {
            console.groupEnd();
        }
        /* eslint-enable no-console */
    }

    function _handleError(config: ErrorHandlerConfig): void {
        const { thrownError, level, showNotification } = { showNotification: true, ...config };

        if (thrownError instanceof Error) {
            error.value = thrownError;

            if (isOtError(thrownError)) {
                send(thrownError, level);
                notifications.danger(t(thrownError.slug, thrownError.getLogContext()));
                consoleWriter(thrownError);

                plugins.log.setLastError(thrownError);
                return;
            }

            if (showNotification) {
                notifications.danger(t('dashboard.common.error.generic'));
            }

            console.error('Unrecognized error:', { e: thrownError });
        }
    }

    function handleError(thrownError: unknown, level?: LogLevel): void {
        _handleError({ thrownError, level });
    }

    function handleErrorSilently(thrownError: unknown, level?: LogLevel): void {
        _handleError({
            thrownError,
            level,
            showNotification: false,
        });
    }

    return {
        error,
        handleError,
        handleErrorSilently,
    };
}
