<template>
    <div class="debug-plugins__context">
        <code
            class="debug-plugins__context__status full-width"
        >
            <span>Status:</span>

            <span v-if="loading">Loading</span>
            <span v-else>Ready</span>
        </code>

        <code
            v-if="context"
            class="debug-plugins__context__type full-width"
        >
            <span>Context:</span>

            <span>{{ context.type }}</span>
        </code>

        <code
            v-if="context"
            class="debug-plugins__context__company full-width"
        >
            <span>Company:</span>

            <span v-if="context.company">{{ context.company.name }} ({{ context.company.id }})</span>
            <span v-else>[No]</span>
        </code>

        <code
            v-if="context"
            class="debug-plugins__context__event full-width"
        >
            <span>Event:</span>

            <span v-if="context.event">{{ context.event.name }} ({{ context.event.id }})</span>
            <span v-else>[No]</span>
        </code>
    </div>
</template>

<script lang="ts" setup>
import type { Ref } from 'vue';
import { onMounted, ref } from 'vue';
import type { PluginsManager } from '../../../../plugins';
import type { Context } from '../../../../services/context';
import { injectOrFail } from '../../../../services/util';

const plugins = injectOrFail<PluginsManager>('plugins');
const context = ref<Context | null>(null) as Ref<Context | null>;
const loading = ref<boolean>(true);

onMounted(() => {
    void initializePluginDebug();
});

async function initializePluginDebug() {
    try {
        context.value = await plugins.context.loading;
    } finally {
        loading.value = false;
    }
}
</script>

<style lang="scss" scoped>
.debug-plugins__context {
    & > code {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;
    }
}
</style>
