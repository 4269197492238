<template>
    <div
        v-if="!!tickets.total && !!tickets.records.length"
        class="module__access-moments__unlinked-tickets__list"
    >
        <OtDataGrid
            class="module__access-moments__unlinked-tickets__list__grid"
            type="table"
            :pagination="tickets"
            :columns="columns"
            hide-header
            :empty-message-active-filter="$t('dashboard.common.filters.not_found')"
            :loading-message="$t('common.loading')"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="tickets"
                    :title="$t('dashboard.access_moments.unlinked_tickets.list.header.title')"
                />
            </template>

            <template #column__-data__name="{ value }">
                <span class="module__access-moments__unlinked-tickets__list__record">
                    <OtIcon type="ticket-alt" />
                    <h4>{{ value }}</h4>
                </span>
            </template>

            <template #actions="{record}">
                <button
                    class="ot-button is-outline is-small"
                    data-testid="access-moment-unlinked-tickets-list-link-ticket-button"
                    @click="unlinkedTicketsAccessMomentLinkModalRef?.show(record)"
                >
                    <OtIcon
                        type="plus"
                        size="small"
                        class="ot-button-icon--left"
                    />

                    {{ $t('dashboard.access_moments.unlinked_tickets.modal.link.text') }}
                </button>
            </template>
        </OtDataGrid>
        <!-- TODO: replace with generic LinkUnlinkModal -->
        <UnlinkedTicketsAccessMomentLinkModal
            ref="unlinkedTicketsAccessMomentLinkModalRef"
            :access-moments-list="accessMomentsList"
            @submit="$emit('reload')"
        />
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { Event, EventDate, ManagementClient } from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import type { Column } from '@openticket/vue-dashboard-components';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import UnlinkedTicketsAccessMomentLinkModal from './UnlinkedTicketsAccessMomentLinkModal.vue';

type Props = {
    accessMomentsList: Pagination<EventDate<Event<ManagementClient>>>;
}

type Emits = {
    (e: 'reload'): void;
}

defineProps<Props>();
defineEmits<Emits>();

const context = injectOrFail<Context>('context');

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const columns: Column[] = [
    {
        key: [ '$data', 'name' ],
        label: 'Name',
        type: 'string',
    },
];

const unlinkedTicketsAccessMomentLinkModalRef = ref<InstanceType<typeof UnlinkedTicketsAccessMomentLinkModal> | null>(null);

const tickets = ref(
    context.event.model.tickets.list({
        defaultFilters: { unlinked_event_dates: { scope: '' } },
        perPage: 10,
    }),
);

async function reload(): Promise<void> {
    await tickets.value.loadPage();
}

defineExpose({
    reload,
});
</script>

<style scoped lang="scss">
.module__access-moments__unlinked-tickets__list__record {
    display: flex;
    align-items: center;
    gap: var(--ot-spacing-unit);

    svg {
        color: var(--ot-color-core-light-accent-primary);
    }
}
</style>
