<template>
    <OtForm
        v-if="user"
        class="user-form"
    >
        <!-- TODO  :form="form" -->
        <OtFormRow>
            <OtFormField
                :label="$t('dashboard.common.input.name')"
                :error="error"
                :required="rules.name && rules.name.includes('required')"
                :optional="rules.name && rules.name.includes('optional')"
            >
                <OtInput
                    v-model="user.$data.name"
                    class="input"
                    type="text"
                    :disabled="disabled"
                />
            </OtFormField>
        </OtFormRow>

        <OtFormRow>
            <OtFormField
                :label="$t('dashboard.common.input.email')"
                :error="error"
                :required="rules.email && rules.email.includes('required')"
                :optional="rules.email && rules.email.includes('optional')"
            >
                <OtInput
                    class="input"
                    type="email"
                    :value="user.$data.email"
                    :disabled="true"
                />
            </OtFormField>

            <OtFormField
                :label="$t('dashboard.common.input.phone_number')"
                :error="error"
                :required="rules.phone && rules.phone.includes('required')"
                :optional="rules.phone && rules.phone.includes('optional')"
            >
                <OtInput
                    v-model="user.$data.phone"
                    class="input"
                    type="phone"
                    :disabled="disabled"
                />
            </OtFormField>
        </OtFormRow>

        <div class="user-form__save">
            <button
                class="ot-button"
                type="button"
                :disabled="disabled || !hasLocalChanges"
                :title="$t('dashboard.common.action.save.title')"
                @click="save()"
            >
                <OtIcon
                    class="ot-button-icon--left"
                    type="check"
                />
                {{ $t('dashboard.common.action.save.text') }}
            </button>
        </div>
    </OtForm>
</template>

<script setup lang="ts">
import type { AuthClient, User } from '@openticket/lib-auth';
import { watch } from 'vue';

type Props = {
    user: User<AuthClient>,
    rules: { [p: string]: string[] },
    disabled?: boolean,
    hasLocalChanges?: boolean,
}

const error = null; // TODO

const props = withDefaults(defineProps<Props>(), {
    disabled: false,
});

type Emits = {
    (e: 'data-changed', value: boolean): void
    (e: 'save'): void
}
const emit = defineEmits<Emits>();

watch(() => [ props.user.$data.name, props.user.$data.phone ], () => {
    emit('data-changed', true);
});

function save(): void {
    if (!props.disabled) {
        emit('save');
    }
}
</script>

<style lang="scss" scoped>
.user-form {
    &__save {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
