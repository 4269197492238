import type { LocalizationContract } from '@openticket/lib-localization';
import type VueI18n from 'vue-i18n';
import { injectOrFail } from '../services/util';

// TODO: This composable should be replaced by the one in Vue-UI when migrating
export function useLocalization() {
    const i18n = injectOrFail<VueI18n>('i18n');
    const localization = injectOrFail<LocalizationContract>('localization');

    return {
        i18n,

        /**
         * Formatters
         */
        l: localization.formatters,

        localization,

        /**
         * Parsers
         */
        p: localization.parsers,

        /**
         * Stringified translation.
         *
         * If you need the `TranslateResult`, use `i18n.t`.
         */
        t: (key: VueI18n.Path, values?: VueI18n.Values) => String(i18n.t(key, values)),

        /**
         * Translation message exist
         */
        te: i18n.te.bind(i18n),
    };
}
