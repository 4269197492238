<template>
    <div
        v-if="settings"
        class="shop-settings__order"
    >
        <h2 class="has-margin">
            {{ $t('dashboard.shop.settings.order.title') }}
        </h2>
        <h5 class="ot-text-tiny has-margin subtitle">
            {{ $t('dashboard.shop.settings.order.description') }}
        </h5>

        <OtInputField
            :label="$t('dashboard.shop.settings.order.general.title')"
        >
            <OtInput
                v-model="settings.static.order.enablePassbook"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.general.apple_wallet.label')"
            />
        </OtInputField>

        <OtInputField
            :label="$t('dashboard.shop.settings.order.resell.title')"
            :description="$t('dashboard.shop.settings.order.resell.description')"
        >
            <OtInput
                v-model="settings.static.order.enableResell"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.resell.resell.label')"
            />

            <OtInput
                v-model="settings.static.order.enableTicketSwap"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.resell.ticket_swap.label')"
                :disabled="!settings.static.order.enableResell"
            />

            <OtInput
                v-model="settings.static.order.enableTixel"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.resell.tixel.label')"
                :disabled="!settings.static.order.enableResell"
            />
        </OtInputField>

        <OtInputField
            :label="$t('dashboard.shop.settings.order.event_card.title')"
            :description="$t('dashboard.shop.settings.order.event_card.description')"
        >
            <OtInput
                v-model="settings.static.order.components.eventCard.showEventDate"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.event_card.show_event_date.label')"
            />

            <OtInput
                v-model="settings.static.order.components.eventCard.showLocation"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.event_card.show_location.label')"
            />
        </OtInputField>

        <OtInputField
            v-if="roles.isWhitelabelAdmin"
            :label="$t('dashboard.shop.settings.order.admin.title')"
            :description="$t('dashboard.shop.settings.order.admin.description')"
        >
            <OtInput
                v-model="settings.static.order.enableAppic"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.enable_appic.label')"
            />

            <OtInput
                v-model="settings.static.order.enablePartyPay"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.enable_party_pay.label')"
            />

            <OtInput
                v-model="settings.static.order.disableDownloadButton"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.disable_download_button.label')"
            />

            <OtInput
                v-model="settings.static.order.components.eventCard.disableDownloadButton"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.event_card.disable_download_button.label')"
            />

            <div class="ot-toggle-label">
                {{ $t('dashboard.shop.settings.order.admin.event_card.close_url.label') }}
            </div>
            <OtInput
                v-model="settings.static.order.components.eventCard.closeUrl"
                type="text"
            />

            <OtInput
                v-model="settings.static.order.header.showLogo"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.header.showLogo.label')"
            />

            <OtInput
                v-model="settings.static.order.footer.showPoweredBy"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.footer.showPoweredBy.label')"
            />

            <OtInput
                v-model="settings.static.order.footer.showLocaleSwitcher"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.footer.showLocaleSwitcher.label')"
            />

            <OtInput
                v-model="settings.static.order.footer.showCopyright"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.footer.showCopyright.label')"
            />

            <OtInput
                v-model="settings.static.order.footer.showSupport"
                type="toggle"
                :label="$t('dashboard.shop.settings.order.admin.footer.showSupport.label')"
            />
        </OtInputField>
    </div>
</template>

<script setup lang="ts">
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import type { UnwrapNestedRefs } from 'vue';
import { injectOrFail } from '../../../../services/util';
import type { Roles } from '../../../../plugins/types';

const settings = injectOrFail<CustomShopSettingsClient>('settings');
const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');
</script>

<style lang="scss" scoped>
.shop-settings__order {
    .ot-input-field > .ot-toggle:not(:last-child) {
        margin-bottom: .375rem;
    }

    .subtitle {
        color: var(--ot-color-core-foreground-secondary);
    }
}
</style>
