<template>
    <div class="module__auth__login">
        <div>
            <div class="ot-spinner" />
            <h5>{{ $t('dashboard.common.state.loading') }}...</h5>
        </div>
    </div>
</template>

<script lang="ts" setup>
import urlJoin from 'url-join';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { AuthClient, AuthorizationState } from '@openticket/lib-auth';
import { useRoute, useRouter } from 'vue-router/composables';
import { injectOrFail } from '../../../services/util';

interface ClientConfig {
    client_id?: string | Array<string | null>;
    redirect?: string | Array<string | null>;
    state?: string | Array<string | null>;
    [key: string]: unknown;
}

const auth = injectOrFail<AuthClient>('auth');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const router = useRouter();
const route = useRoute();

void (async () => {
    try {
        const clientConfig: ClientConfig = route.query;

        const redirect: string = clientConfig.redirect && typeof clientConfig.redirect === 'string'
            ? clientConfig.redirect
            : '/';

        const stateKey: string | undefined = clientConfig.state && typeof clientConfig.state === 'string'
            ? clientConfig.state
            : undefined;

        const state: AuthorizationState = {
            key: stateKey,
            redirect,
        };

        const clientId: string | undefined = clientConfig.client_id && typeof clientConfig.client_id === 'string'
            ? clientConfig.client_id
            : undefined;

        const returnUrl: string = urlJoin(whitelabel.dashboard.url, 'auth', 'callback');

        window.location.href = await auth.$token.authorizationUrl(returnUrl, state, clientId);
    } catch (e) {
        // TODO SEND LOG
        void router.push({ name: 'error', query: { reason: e instanceof Error ? e.message : 'Failed to login' } });
    }
})();
</script>

<style lang="scss" scoped>
.module__auth__login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .ot-spinner::after {
        position: relative;
    }

    h5 {
        margin-top: 1rem;
    }
}
</style>
