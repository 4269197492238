<template>
    <OtSpinner v-if="isLoading" />

    <div
        v-else-if="tokenErrors.notLoggedOut"
        class="ot-card forgot-password"
    >
        <ModalHeader hide-padding>
            <template #logout>
                <button
                    class="is-text is-small"
                    type="button"
                    :title="$t('dashboard.invitation.invalid.logout.title')"
                    @click="logoutAndReload"
                >
                    <OtIcon
                        class="ot-button-icon--left"
                        type="logout"
                    />
                    {{ $t('dashboard.invitation.invalid.logout.text') }}
                </button>
            </template>
        </ModalHeader>

        <div class="forgot-password__header">
            <h1>{{ $t('dashboard.reset_password.title') }}</h1>
        </div>

        <div class="forgot-password__content">
            <OtAside
                :title="$t('dashboard.reset_password.invalid')"
                :description="$t(tokenErrors.notLoggedOut)"
                type="is-warning"
                icon="warning"
            />
        </div>

        <div class="forgot-password__footer">
            <button
                class="ot-button"
                type="button"
                :title="$t('dashboard.invitation.invalid.dashboard.title')"
                @click="$router.push({name: 'home'})"
            >
                {{ $t('dashboard.invitation.invalid.dashboard.text') }}
                <OtIcon
                    class="ot-button-icon--right"
                    type="arrow-right"
                />
            </button>
        </div>
    </div>

    <div
        v-else-if="tokenErrors.token"
        class="ot-card forgot-password"
    >
        <ModalHeader hide-padding />

        <div class="forgot-password__header">
            <h1>{{ $t('dashboard.reset_password.title') }}</h1>
        </div>

        <OtAside
            :title="$t(tokenErrors.token)"
            type="is-warning"
            icon="warning"
        />
    </div>

    <FixResetPassword
        v-else
        :form="forgotPasswordForm"
        class="ot-card forgot-password"
    />
</template>

<script setup lang="ts">
import type { AuthClient, ForgotPassword, Password } from '@openticket/lib-auth';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { isAxiosError } from 'axios';
import urlJoin from 'url-join';
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import FixResetPassword from '../components/FixResetPassword.vue';
import { injectOrFail } from '../../../services/util';
import type { RudderStack } from '../../../services/rudderstack';
import { useResetTokenCheck } from '../composable/useResetTokenCheck';
import { useCustomValidatedPostForm } from '../../../composables/forms';

const auth = injectOrFail<AuthClient>('auth');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const rudderstack = injectOrFail<RudderStack>('rudderstack');
const isLoading = ref<boolean>(true);

const router = useRouter();
const { tokenErrors, checkValidResetToken } = useResetTokenCheck('forgotPassword');

void (async () => {
    rudderstack.track('vue-dashboard fix forgot password init');
    await checkValidResetToken(() => {
        forgotPasswordForm.reset();

        isLoading.value = false;
    });
})();

const forgotPasswordForm = reactive(
    useCustomValidatedPostForm<ForgotPassword<Password<AuthClient>>, ForgotPassword<Password<AuthClient>>>(
        auth.passwords.new().forgotPassword.new(),
        auth.passwords.new().forgotPassword,
        {
            onSubmit() {
                rudderstack.track('vue-dashboard fix forgot password submit success');

                if (whitelabel.dashboard.login_url) {
                    window.location.href = whitelabel.dashboard.login_url;
                } else {
                    void router.push({ name: 'auth.login' });
                }
            },
        },
    ),
);

async function logoutAndReload(): Promise<void> {
    try {
        // TODO - @openticket/lib-auth
        await Promise.all([
            auth.$token.logout(), // TODO @Peter - lib-auth add a 'clear tokens' (e.g. logout without hooks) method.
            auth.$http.get(urlJoin(auth.$path, 'sessions', 'logout')),
        ]);
    } catch (e: unknown) {
        if (isAxiosError(e)
            && e.response
            && (
                e.response.status === Number(import.meta.env.VITE_LOGOUT_REDIRECT_STATUS)
                || e.response.status === 355
            )) {
            void await checkValidResetToken(() => {
                forgotPasswordForm.reset();

                isLoading.value = false;
            });
        } else {
            await router.push({ name: 'error' });
        }
    }
}

</script>

<style lang="scss" scoped>
.forgot-password {
    &__header{
        margin-bottom: var(--ot-spacing-lg);

        .warning {
            color: var(--ot-color-accent-orange-dark);
        }

        &__top {
            display: flex;
            margin-bottom: var(--ot-spacing-lg);

            &__logo {
                max-width: 40%;
                text-align: left;
            }

            &__logout {
                color: var(--ot-color-core-brand);
                margin: auto 0 auto auto;
            }
        }

        &__title {
            text-align: center;
        }

    }

    &__content {
        margin-bottom: var(--ot-spacing-3xl);
    }

    &__footer .ot-button {
        width: 100%;
    }
}
</style>
