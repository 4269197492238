<template>
    <div class="debug-plugins__document-title">
        <code
            class="debug-plugins__document-title__status full-width"
        >
            <span>Status:</span>

            <span v-if="loading">Loading</span>
            <span v-else>Ready</span>
        </code>

        <code class="debug-plugins__document-title__cache full-width">
            <span class="row">Part cache:</span>
            <span class="row"><span>[Dashboard]</span><span>{{ dashboard }}</span></span>
            <span class="row"><span>[Whitelabel]</span><span>{{ whitelabel }}</span></span>
            <span class="row"><span>[Last route]</span><span>{{ lastRouteName }}</span></span>
            <span class="row"><span /><span>{{ lastRoutePath }}</span></span>
        </code>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import type { PluginsManager, DocumentTitlePlugin } from '../../../../plugins';
import { injectOrFail } from '../../../../services/util';

const plugins = injectOrFail<PluginsManager>('plugins');
const loading = ref<boolean>(true);
const plugin = ref<DocumentTitlePlugin | null>(null);

onMounted(() => {
    void initializePluginDebug();
});

async function initializePluginDebug() {
    try {
        plugin.value = await plugins.documentTitle.loading;
    } finally {
        loading.value = false;
    }
}

const dashboard = computed<string>(() => plugin.value?.partCache.dashboard || '');
const whitelabel = computed<string>(() => plugin.value?.partCache.whitelabel || '');
const lastRouteName = computed<string>(() => plugin.value?.partCache.lastRoute?.name || '');
const lastRoutePath = computed<string>(() => plugin.value?.partCache.lastRoute?.path || '');
</script>

<style lang="scss" scoped>
.debug-plugins__document-title {
    & > code {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;

        &.debug-plugins__document-title__cache {
            flex-direction: column;
            justify-content: flex-start;

            & > .row {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
</style>
