<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <OtSpinner v-else-if="loading" />

    <div
        v-else
        class="shop-update"
    >
        <div class="shop-update__tabs ot-mb[lg]">
            <div class="shop-update__tabs-items">
                <VerticalTabs>
                    <template #default="{ selected }">
                        <!--
                            TODO CU-86c0a8wx8: Replace hardcoded translation below with slug
                            'dashboard.shops.edit.details.route_title'
                         -->
                        <VerticalTabsItem
                            label="Details"
                            :route="{ name: 'shops.edit.details' }"
                            :selected="selected"
                        >
                            <ShopsSettingsDetails :form="shopForm" />
                        </VerticalTabsItem>

                        <VerticalTabsItem
                            :label="$t('dashboard.shops.styling.shop.route_title')"
                            :route="{ name: 'shops.edit.shop' }"
                            :selected="selected"
                        >
                            <ShopsSettingsShop />
                        </VerticalTabsItem>

                        <VerticalTabsItem
                            :label="$t('dashboard.shops.styling.order.route_title')"
                            :route="{ name: 'shops.edit.order' }"
                            :selected="selected"
                        >
                            <ShopsSettingsOrder />
                        </VerticalTabsItem>
                    </template>
                </VerticalTabs>
            </div>

            <div class="shop-update__tabs__preview">
                <div class="shop-update__tabs__preview-container">
                    <OtPreview
                        ref="previewRef"
                        :header-label="$t('dashboard.tickets.ticket_design.preview.header')"
                        :src="shopUrl"
                        type="iframe"
                        @load="onLoadPreview"
                    />
                </div>
            </div>
        </div>

        <div class="shop-styling__footer">
            <OtPageFooter>
                <template #left>
                    <button
                        class="ot-button is-dark"
                        @click="$router.back()"
                    >
                        <OtIcon type="arrow-left" />
                        {{ $t('dashboard.common.action.back.text') }}
                    </button>
                </template>

                <template #right>
                    <button
                        class="ot-button"
                        :disabled="!saveButtonEnabled"
                        data-testid="shop-settings-footer-save"
                        @click="handleSaveButtonClick"
                    >
                        <OtIcon type="check" />
                        {{ $t('dashboard.common.action.save.text') }}
                    </button>
                </template>
            </OtPageFooter>
        </div>
    </div>
</template>

<script setup lang="ts">
import { type OtPreview } from '@openticket/vue-dashboard-components';
import type { ManagementClient, Shop } from '@openticket/lib-management';
import {
    inject, type UnwrapNestedRefs, reactive, watch,
    computed,
} from 'vue';
import { useRoute } from 'vue-router/composables';
import { injectOrFail } from '../../../services/util';
import ErrorView from '../../../components/ErrorView.vue';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import ShopsSettingsShop from '../components/settings/ShopsSettingsShop.vue';
import ShopsSettingsOrder from '../components/settings/ShopsSettingsOrder.vue';
import ShopsSettingsDetails from '../components/settings/ShopsSettingsDetails.vue';
import { shopSettingsConfigKey } from '../keys';
import { useUpdateForm } from '../../../composables/forms';
import type { SidebarUpdateTriggers } from '../../../layouts/types';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables';

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');
const sidebarUpdate = inject<UnwrapNestedRefs<SidebarUpdateTriggers>>('sidebarUpdate');

if (!context.isShopContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const { handleErrorSilently } = useGenericErrorHandling();
const route = useRoute();

/* These properties are needed for the shop api settings */
const {
    error,
    hasLocalChanges,
    loading,
    onLoadPreview,
    previewRef,
    save,
    shopUrl,
} = injectOrFail(shopSettingsConfigKey);

/* This form is needed for the shop data in management */
const shopForm = reactive(
    useUpdateForm<Shop<ManagementClient>, ManagementClient>(
        context.shop.model,
        management.shops,
        {
            onSubmit: async (model) => {
                await context.updateContext('shop', model);
                void sidebarUpdate?.updateEvents();
            },
        },
    ),
);

const saveButtonEnabled = computed<boolean>(() => hasLocalChanges.value || shopForm.hasLocalChanges);

// Update form model when switching context
watch(() => context.shop, (shopContext) => {
    if (shopContext !== null) {
        shopForm.init(context.shop.model, management.shops);
    }
});

async function handleSaveButtonClick() {
    const shopApiRouteNames = [
        'shops.edit.shop',
        'shops.edit.order',
    ];

    // Either use shop api or management for saving, based on route
    if (shopApiRouteNames.includes(route.name || '')) {
        await save();
        return;
    }

    try {
        await shopForm.submit();
    } catch (e) {
        void handleErrorSilently(e);
    }
}
</script>

<style lang="scss" scoped>
.shop-update {
    &__header {
        border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);

        h1 {
            min-height: 2.25rem;
        }
    }

    &__tabs {
        display: flex;
        flex-direction: row;
        gap: var(--ot-spacing-3xl);

        &-items {
            flex: 4;
        }

        &__preview {
            @media (max-width: 75rem) {
                display: none;
            }

            flex: 2;

            &-container {
                position: sticky;
                top: calc(var(--ot-layout-header-height, 5.5rem) + 1rem);
                right: 0;
                height: calc(80vh - var(--ot-layout-header-height, 5.5rem) + 1rem);
                min-height: 50vh;

                &::v-deep {
                    .preview {
                        min-height: 50vh;
                    }
                }
            }
        }
    }

    &__footer {
        button {
            gap: var(--ot-spacing-sm)
        }
    }
}
</style>
