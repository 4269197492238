<template>
    <div class="addon-product-form-fields">
        <OtFormRow>
            <InputField
                :label="$t('dashboard.addon_products.details.inputs.name.label')"
                :description="$t('dashboard.addon_products.details.inputs.name.description')"
                :required="form.rules.name?.includes('required')"
                :error="form.errors.name"
            >
                <OtInputText
                    v-model="form.model.$data.name"
                    class="input"
                    data-testid="addon-product-form-fields-name"
                    @input="form.errors.name = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow v-if="!hideDescriptionField">
            <InputField
                :label="$t('dashboard.addon_products.details.inputs.description.label')"
                :description="$t('dashboard.addon_products.details.inputs.description.description')"
                :required="form.rules.description?.includes('required')"
                optional
                :error="form.errors.description"
            >
                <OtInputText
                    v-model="form.model.$data.description"
                    class="input"
                    data-testid="addon-product-form-fields-description"
                    @input="form.errors.description = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.addon_products.details.inputs.available_stock.label')"
                :description="$t('dashboard.addon_products.details.inputs.available_stock.description')"
                :required="form.rules.available_stock?.includes('required')"
                :error="form.errors.available_stock"
            >
                <InputFloatUnlimited
                    v-model="form.model.$data.available_stock"
                    data-testid="addon-product-form-fields-available-stock"
                    @input="form.errors.available_stock = []"
                />
            </InputField>
        </OtFormRow>

        <InputPriceVat
            data-testid="addon-product-form-fields-price"
            :translations="{
                price: {
                    label:$t('dashboard.addon_products.details.inputs.price.label'),
                    description: $t('dashboard.addon_products.details.inputs.price.description')
                },
                vat: {
                    label: $t('dashboard.addon_products.details.inputs.vat_percentage.label'),
                    description: $t('dashboard.addon_products.details.inputs.vat_percentage.description')
                }
            }"
            :currency="context.event.model.$data.currency"
            :price-errors="form.errors.price"
            :vat-errors="form.errors.vat_percentage"
            :vat-value="form.model.$data.vat_percentage"
            :price-value="form.model.$data.price"
            :price-rules="form.rules.price"
            @vat-change="updateVatPercentage"
            @price-change="updatePrice"
        />
    </div>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import type { ManagementClient, Product } from '@openticket/lib-management';
import type { Context } from '../../../../services/context';
import InputPriceVat from '../../../../components/form/InputPriceVat.vue';
import InputField from '../../../../components/form/InputField.vue';
import InputFloatUnlimited from '../../../../components/InputFloatUnlimited.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Product<ManagementClient>, ManagementClient>>;
    hideDescriptionField?: boolean;
}

const context = injectOrFail<Context>('context');

const props = defineProps<Props>();

if (!context.isEventContext()) {
    // TODO Proper error handling and logging
    throw Error('Invalid context');
}

function updateVatPercentage(value: number) {
    props.form.errors.vat_percentage = [];
    props.form.model.$data.vat_percentage = value;
}

function updatePrice(value: number) {
    props.form.errors.price = [];
    props.form.model.$data.price = value;
}
</script>

<style scoped lang="scss">
.addon-product-form-fields {
    display: flex;
    flex-direction: column;
}
</style>
