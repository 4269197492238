<template>
    <div class="ticket-sale-settings-form">
        <OtFormRow>
            <InputField
                :label="$t('dashboard.tickets.sale_settings.inputs.ticket_availability.label')"
                :description="$t('dashboard.tickets.sale_settings.inputs.ticket_availability.description')"
                :required="form.rules.status_overrule && form.rules.status_overrule.includes('required')"
                :optional="form.rules.status_overrule && form.rules.status_overrule.includes('optional')"
                :error="form.errors.status_overrule"
            >
                <OtInput
                    v-model="form.model.$data.status_overrule"
                    class="input"
                    type="select"
                    :options="ticketAvailabilityOptions"
                    @input="form.errors.status_overrule = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.tickets.sale_settings.inputs.sale_increments.label')"
                :description="$t('dashboard.tickets.sale_settings.inputs.sale_increments.description')"
                :required="form.rules.increment && form.rules.increment.includes('required')"
                :optional="form.rules.increment && form.rules.increment.includes('optional')"
                :error="form.errors.increment"
                :custom-error-attributes="{
                    lte: $t('dashboard.tickets.sale_settings.inputs.min_per_order.label')
                }"
            >
                <OtInputFloat
                    v-model="form.model.$data.increment"
                    class="input"
                    :min="1"
                    @input="form.errors.increment = []"
                />
            </InputField>
            <InputField
                :label="$t('dashboard.tickets.sale_settings.inputs.min_per_order.label')"
                :description="$t('dashboard.tickets.sale_settings.inputs.min_per_order.description')"
                :required="form.rules.min_orderable_amount_per_order
                    && form.rules.min_orderable_amount_per_order.includes('required')"
                :optional="form.rules.min_orderable_amount_per_order
                    && form.rules.min_orderable_amount_per_order.includes('optional')"
                :error="form.errors.min_orderable_amount_per_order"
                :custom-error-attributes="{
                    lte: $t('dashboard.tickets.sale_settings.inputs.max_per_order.label')
                }"
            >
                <OtInputFloat
                    v-model="form.model.$data.min_orderable_amount_per_order"
                    class="input"
                    :min="1"
                    @input="form.errors.min_orderable_amount_per_order = []"
                />
            </InputField>
            <InputField
                :label="$t('dashboard.tickets.sale_settings.inputs.max_per_order.label')"
                :description="$t('dashboard.tickets.sale_settings.inputs.max_per_order.description')"
                :required="form.rules.max_orderable_amount_per_order
                    && form.rules.max_orderable_amount_per_order.includes('required')"
                :optional="form.rules.max_orderable_amount_per_order
                    && form.rules.max_orderable_amount_per_order.includes('optional')"
                :error="form.errors.max_orderable_amount_per_order"
                :custom-error-attributes="{
                    gte: $t('dashboard.tickets.sale_settings.inputs.min_per_order.label')
                }"
            >
                <OtInputFloat
                    v-model="form.model.$data.max_orderable_amount_per_order"
                    class="input"
                    :min="1"
                    :max="250"
                    @input="form.errors.max_orderable_amount_per_order = []"
                />
            </InputField>
        </OtFormRow>

        <!-- TODO: Restore this when 'limit availability' data is available -->
        <!-- <div class="ot-separator ot-my[2xl]" />

        <div class="ot-mb[xl]">
            <h2>{{ $t('dashboard.tickets.sale_settings.inputs.limit_availability.title') }}</h2>
            <div class="ot-text-body">{{ $t('dashboard.tickets.sale_settings.inputs.limit_availability.description') }}</div>
        </div>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.tickets.sale_settings.inputs.limit_availability.available_from.label')"
                :description="$t('dashboard.tickets.sale_settings.inputs.limit_availability.available_from.description')"
                :required="form.rules.available_from && form.rules.available_from.includes('required')"
                :optional="form.rules.available_from && form.rules.available_from.includes('optional')"
                :error="form.errors.available_from"
            >
                <OtInputDate
                    class="input"
                    :time="true"
                    :maxDate="form.model.$data.available_until"
                    v-model="form.model.$data.available_from"
                    @input="form.errors.available_from = []"
                    disabled
                />
            </InputField>
            <InputField
                :label="$t('dashboard.tickets.sale_settings.inputs.limit_availability.available_until.label')"
                :description="$t('dashboard.tickets.sale_settings.inputs.limit_availability.available_until.description')"
                :required="form.rules.available_until && form.rules.available_until.includes('required')"
                :optional="form.rules.available_until && form.rules.available_until.includes('optional')"
                :error="form.errors.available_until"
            >
                <OtInputDate
                    class="input"
                    :time="true"
                    :minDate="form.model.$data.available_from"
                    v-model="form.model.$data.available_until"
                    @input="form.errors.available_until = []"
                    disabled
                />
            </InputField>
        </OtFormRow> -->

        <!-- TODO: Restore this when countdown data is available -->
        <!-- <OtFormRow>
            <InputField
                :label="$t('dashboard.tickets.sale_settings.inputs.limit_availability.countdown.label')"
                :description="$t('dashboard.tickets.sale_settings.inputs.limit_availability.countdown.description')"
            >
                <OtInput
                    class="input"
                    type="toggle"
                    v-model="dummy.enableCountdown"
                    :label="$t('dashboard.tickets.sale_settings.inputs.limit_availability.countdown.toggle.label')"
                />
                <OtInput
                    class="input"
                    type="float"
                    suffix="hours"
                    v-model="dummy.countdown"
                    :disabled="!dummy.enableCountdown"
                />
            </InputField>
        </OtFormRow> -->

        <div class="ot-separator ot-my[2xl]" />

        <OtFormRow>
            <InputField
                :label="$t('dashboard.tickets.sale_settings.inputs.ticket_reselling.label')"
                :description="$t('dashboard.tickets.sale_settings.inputs.ticket_reselling.description')"
                :required="form.rules.swappable && form.rules.swappable.includes('required')"
                :optional="form.rules.swappable && form.rules.swappable.includes('optional')"
                :error="form.errors.swappable"
            >
                <OtInput
                    v-model="form.model.$data.swappable"
                    class="input"
                    type="toggle"
                    :label="$t('dashboard.tickets.sale_settings.inputs.ticket_reselling.toggle.label')"
                    @input="form.errors.swappable = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.tickets.sale_settings.inputs.coupon_code_ticket_visibility.label')"
                :description="$t('dashboard.tickets.sale_settings.inputs.coupon_code_ticket_visibility.description')"
                :required="form.rules.hide_without_coupon && form.rules.hide_without_coupon.includes('required')"
                :optional="form.rules.hide_without_coupon && form.rules.hide_without_coupon.includes('optional')"
                :error="form.errors.hide_without_coupon"
            >
                <OtInput
                    v-model="form.model.$data.hide_without_coupon"
                    class="input"
                    type="toggle"
                    :label="$t('dashboard.tickets.sale_settings.inputs.coupon_code_ticket_visibility.toggle.label')"
                    @input="form.errors.hide_without_coupon = []"
                />
            </InputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { UnwrapNestedRefs } from 'vue';
import type { ManagementClient, Ticket } from '@openticket/lib-management';
import InputField from '../../../../components/form/InputField.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { useLocalization } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Ticket<ManagementClient>, ManagementClient>>
}

defineProps<Props>();

const { t } = useLocalization();

const ticketAvailabilityOptions = ref({
    auto: t('dashboard.tickets.sale_settings.inputs.ticket_availability.options.auto'),
    sold_out: t('dashboard.tickets.sale_settings.inputs.ticket_availability.options.sold_out'),
});
</script>
