<template>
    <div class="profile-invitations">
        <button
            class="is-small is-fullwidth is-outline"
            type="button"
            :title="$t('dashboard.header.profile.invitations.button.title')"
            @click="openInvitations()"
        >
            <OtIcon
                type="inbox"
                size="small"
                class="ot-button-icon--left"
            />
            {{ $tc('dashboard.header.profile.invitations.button.text', count) }}
        </button>
        <!-- TODO: Modal does not go over sidebar (z-index issue) -->
        <ProfileInvitationsModal
            ref="invitationsModal"
            @close="closeEvent"
        />
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import type { AuthClient, Invitation } from '@openticket/lib-auth';
import type { Pagination } from '@openticket/lib-crud';
import type { OtInlineModal } from '@openticket/vue-dashboard-components';
import ProfileInvitationsModal from '../ProfileInvitationsModal.vue';
import { injectOrFail } from '../../../../services/util';
import { useGenericErrorHandling } from '../../../../composables/useGenericErrorHandling';

type Emits = {
    (e: 'has-invitations', value: boolean): void
}
const emit = defineEmits<Emits>();

const auth = injectOrFail<AuthClient>('auth');

const invitationsModal = ref<InstanceType<typeof OtInlineModal>>();
const count = ref<number>(0);

void (async () => {
    await updateCount();
})();

function openInvitations(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    invitationsModal.value?.open();
}

async function updateCount() {
    try {
        const invitations: Pagination<Invitation<AuthClient>> = auth.invitations.list();

        await invitations.initialization;

        count.value = invitations.total ? invitations.total : 0;
        emit('has-invitations', !!count.value);
    } catch (e) {
        useGenericErrorHandling();
    }
}

async function closeEvent() {
    await updateCount();
}
</script>
