<template>
    <FormWrapper
        class="event-update"
        :form="eventForm"
        back-route="events.home"
    >
        <template #secondary-actions>
            <RevisionModal
                :id="context.event?.id || ''"
                ref="revisionsModalRef"
                type="event"
                :model-name="context.event?.name || ''"
            />
        </template>

        <VerticalTabs>
            <template #default="{ selected }">
                <VerticalTabsItem
                    :label="$t('dashboard.events.edit.details.route_title')"
                    :route="{ name: 'events.edit.details' }"
                    :selected="selected"
                >
                    <EventDetailsForm :form="eventForm" />
                </VerticalTabsItem>
                <VerticalTabsItem
                    :label="$t('dashboard.events.edit.marketing.route_title')"
                    :route="{ name: 'events.edit.marketing' }"
                    :selected="selected"
                >
                    <EventMarketingForm :form="eventForm" />
                </VerticalTabsItem>
                <VerticalTabsItem
                    :label="$t('dashboard.company.edit.actions.route_title')"
                    :route="{ name: 'events.edit.actions' }"
                    :selected="selected"
                >
                    <EventAdminActions />
                </VerticalTabsItem>
            </template>
        </VerticalTabs>
    </FormWrapper>
</template>

<script setup lang="ts">
import type { Event, ManagementClient } from '@openticket/lib-management';
import {
    inject, reactive, ref, type UnwrapNestedRefs, watch,
} from 'vue';
import type VueNotifications from '@openticket/vue-notifications';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import FormWrapper from '../../../components/form/FormWrapperNew.vue';
import { useUpdateForm } from '../../../composables/forms';
import EventDetailsForm from '../components/forms/EventDetailsForm.vue';
import EventMarketingForm from '../components/forms/EventMarketingForm.vue';
import RevisionModal from '../../../components/revisions/RevisionModal.vue';
import { useGenericErrorHandling, useLocalization } from '../../../composables';
import type { SidebarUpdateTriggers } from '../../../layouts/types';
import EventAdminActions from '../components/actions/EventAdminActions.vue';

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');
const notifications = injectOrFail<VueNotifications>('notifications');
const sidebarUpdate = inject<UnwrapNestedRefs<SidebarUpdateTriggers>>('sidebarUpdate');

const { handleError } = useGenericErrorHandling();
const { t } = useLocalization();

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const revisionsModalRef = ref<InstanceType<typeof RevisionModal> | null>(null);

const eventForm = reactive(
    useUpdateForm<Event<ManagementClient>, ManagementClient>(
        context.event.model,
        management.events,
        {
            notifications: {
                show: true,
                variant: 'fail',
            },
            onSubmit: async (model) => {
                // Check if the location got updated
                if (model.$data.location_id && model.$data.location_id !== context.event.model.$data.location_id) {
                    try {
                        const location = await management.locations.find(model.$data.location_id);
                        await context.event.model.location.link(location);
                    } catch (e: unknown) {
                        void handleError(e);
                        eventForm.hasLocalChanges = true;
                        return;
                    }
                }

                notifications.success(t('dashboard.common.save_success', { name: eventForm.getModelName() }));
                await context.updateContext('event', model);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                await revisionsModalRef.value?.updateRevisions();
                void sidebarUpdate?.updateEvents();
            },
        },
    ),
);

// Update form model when switching context
watch(() => context.event, (eventContext) => {
    if (eventContext !== null) {
        eventForm.init(context.event.model, management.events);
    }
});
</script>
