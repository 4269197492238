<template>
    <FormWrapper :form="eventForm">
        <OtFormRow>
            <InputField
                :label="$t('dashboard.events.wizard.steps.details.form.event_name.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.event_name.description')"
                :required="eventForm.rules.name && eventForm.rules.name.includes('required')"
                :error="eventForm.errors.name"
            >
                <OtInput
                    v-model="eventForm.model.$data.name"
                    class="input"
                    data-testid="event-wizard-event-form-name"
                    type="text"
                    @input="eventForm.errors.name = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.events.wizard.steps.details.form.location.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.location.description')"
                :required="true"
                :error="eventForm.errors.location"
            >
                <LocationsInput
                    v-model="location.$data.guid"
                    data-testid="event-wizard-event-form-location"
                    @error="(e) => error = e"
                    @input="eventForm.errors.location = []"
                />
            </InputField>

            <InputField
                :label="$t('dashboard.events.wizard.steps.details.form.currency.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.currency.description')"
                :required="eventForm.rules.currency && eventForm.rules.currency.includes('required')"
                :error="eventForm.errors.currency"
            >
                <OtInputSelect
                    v-model="eventForm.model.$data.currency"
                    class="input"
                    sort="alphabetically"
                    :options="currencies"
                    :searchable="true"
                    :internal-search="true"
                    data-testid="event-wizard-event-form-currency"
                    @input="eventForm.errors.currency = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                data-testid="event-wizard-event-form-start"
                :label="$t('dashboard.events.wizard.steps.details.form.date_start.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.date_start.description')"
                :required="eventDateForm.rules.start && eventDateForm.rules.start.includes('required')"
                :error="eventForm.errors.start"
            >
                <OtInputDate
                    v-model="eventDateForm.model.$data.start"
                    class="input"
                    :time="true"
                    position="above"
                    @input="eventForm.errors.start = []"
                />
            </InputField>

            <InputField
                data-testid="event-wizard-event-form-end"
                :label="$t('dashboard.events.wizard.steps.details.form.date_end.label')"
                :description="$t('dashboard.events.wizard.steps.details.form.date_end.description')"
                :required="eventDateForm.rules.end && eventDateForm.rules.end.includes('required')"
                :error="eventForm.errors.end"
            >
                <OtInputDate
                    v-model="eventDateForm.model.$data.end"
                    class="input"
                    :time="true"
                    position="above"
                    @input="eventForm.errors.end = []"
                />
            </InputField>
        </OtFormRow>
    </FormWrapper>
</template>

<script setup lang="ts">
import {
    computed,
} from 'vue';
import { isValidateError, validate } from '@openticket/lib-crud';
import type { ManagementClient } from '@openticket/lib-management';
import { isOtError } from '@openticket/lib-log';
import InputField from '../../../../components/form/InputField.vue';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import FormWrapper from '../../../../components/form/FormWrapper.vue';
import LocationsInput from '../../../../components/form/LocationsInput.vue';
import { useGenericErrorHandling, useLocalization } from '../../../../composables';
import type { WizardProvide } from '../../types';

const { error, handleError } = useGenericErrorHandling();
const { localization } = useLocalization();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');
const wizardProvide = injectOrFail<WizardProvide>('wizardProvide');

const { location, eventDateForm, eventForm } = wizardProvide;

if (!eventForm.model.$data.currency && context.isCompanyContext()) {
    eventForm.model.$data.currency = context.company.model.$data.currency;
}

const currencies = computed<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    for (const key of Object.keys(localization.supportedCurrencies)) {
        map[key] = `${localization.supportedCurrencies[key].currencySymbol} - ${localization.supportedCurrencies[key].currencyDisplayName}`;
    }

    return map;
});

async function submit(): Promise<boolean> {
    if (!context.isCompanyContext()) {
        return false;
    }

    eventForm.errors = {};

    // Before submitting the event, validate the location and event date
    if (!location.value.id) {
        eventForm.errors = {
            location: [ {
                data: {},
                message: 'validation.required',
            } ],
        };
    }

    const eventDateValidation = validate(eventDateForm.model.$raw, eventDateForm.rules);
    if (!eventDateValidation.valid) {
        eventForm.errors = {
            ...eventForm.errors,
            ...eventDateValidation.result.messages,
        };
    }

    eventForm.model.$data.locale = context.company.model.$data.locale;
    const eventValidation = validate(eventForm.model.$raw, eventForm.rules);
    if (!eventValidation.valid) {
        eventForm.errors = {
            ...eventForm.errors,
            ...eventValidation.result.messages,
        };
    }

    if (Object.keys(eventForm.errors).length) {
        return false;
    }

    try {
        // Update the relational data to include the selected guid, thereby enabling the use of the correct path.
        const locationRelation = management.locations.new();
        locationRelation.$raw = location.value.$raw;
        eventForm.init(eventForm.model, locationRelation.events);
        await eventForm.submit();

        const eventRelation = management.events.new();
        eventRelation.$raw = eventForm.model.$raw;
        eventDateForm.init(eventDateForm.model, eventRelation.eventDates);
        await eventDateForm.submit();

        return true;
    } catch (e) {
        if (isOtError(e) && isValidateError(e.cause)) {
            eventForm.errors = e.cause.validation.result.messages;
            return false;
        }
        void handleError(e);
    }

    return false;
}

defineExpose({
    submit,
});
</script>
