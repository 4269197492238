<template>
    <div class="link-unlink-picker">
        <ErrorView
            v-if="error"
            :label="error.message"
        />

        <OtPaginationSelect
            v-else-if="pagination"
            ref="paginationSelect"
            :pagination="pagination"
            :value="selectedItems"
            data-primary-key="name"
            data-testid="link-unlink-picker-select"
            :can-create="!!createModal"
            multiple
            :placeholder="$t('dashboard.components.link_unlink.select.placeholder', { items: itemsLabel })"
            @input="handleSelectInput"
            @create="modalRef.open()"
        >
            <template #primaryLabel="{ option }">
                <slot
                    name="primaryLabel"
                    :option="option"
                >
                    {{ option.name || '-' }}
                </slot>
            </template>

            <template #secondaryLabel="{ option }">
                <slot
                    name="secondaryLabel"
                    :option="option"
                />
            </template>
        </OtPaginationSelect>
        <OtSpinner v-else />

        <component
            :is="createModal"
            v-if="createModal && modalRef.isOpen"
            :key="id"
            :modal="modalRef"
            @submit="onCreate"
        />
    </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue';
import { Pagination } from '@openticket/lib-crud';
import type {
    LinkMultiable,
    Listable,
    Model,
    ModelConfig,
    Parent,
    Pickable,
    CrudNode,
    UnlinkMultiable,
} from '@openticket/lib-crud';
import { OtPaginationSelect } from '@openticket/vue-dashboard-components';
import type FormModal from '../../form/FormModal.vue';
import { useGenericErrorHandling } from '../../../composables';
import ErrorView from '../../ErrorView.vue';
import { useFormModal } from '../../../composables/forms';

type Props = {
    // Provide a Pagination to remove an unnecessary call to fetch them otherwise
    initialPagination?: Pagination<Model<Parent, ModelConfig>> | undefined;
    paginationRelation: Listable<Model<Parent, ModelConfig>, ModelConfig, Parent>,

    linkRelation?: Pickable<Model<Parent, ModelConfig>, ModelConfig, Parent>
        & LinkMultiable<Model<CrudNode<Parent>, ModelConfig>, ModelConfig, CrudNode<Parent>, Model<Parent, ModelConfig>>
        & UnlinkMultiable<Model<CrudNode<Parent>, ModelConfig>, ModelConfig, CrudNode<Parent>, Model<Parent, ModelConfig>>;

    isUnlinked?: boolean;
    createModal?: InstanceType<typeof FormModal>;
    defaultSort?: [string, 'asc' | 'desc'][];
    itemsLabel?: string;
}

type Emits = {
    (e: 'error', error: Error): void,
    (e: 'input', value: unknown): void,
    (e: 'submit', value: string[]): void,
    (e: 'saved', value: string[]): void,
}
const props = withDefaults(
    defineProps<Props>(),
    {
        createModal: undefined,
        defaultSort: undefined,
        initialPagination: undefined,
        isUnlinked: false,
        itemsLabel: 'items',
        linkRelation: undefined,
    },
);
const emit = defineEmits<Emits>();

const { error, handleError } = useGenericErrorHandling();

const diff = reactive<{ toLink: string[], toUnlink: string[] }>({
    toLink: [],
    toUnlink: [],
});
const hasLocalChanges = ref<boolean>(false);
const id = ref<string>('');
const originalLinks = ref<string[]>([]);
const selectedItems = ref<string[]>([]);
const paginationSelect = ref<typeof OtPaginationSelect | null>(null);
const pagination = ref<Pagination<Model<Parent, ModelConfig>> | null>(null);

const modalRef = reactive(useFormModal());

function handleSelectInput(listOfGuids: string[]) {
    hasLocalChanges.value = true;
    selectedItems.value = listOfGuids;

    const existingLinks = new Set(originalLinks.value);
    const newLinks = new Set(listOfGuids);

    diff.toLink = listOfGuids.filter((guid) => !existingLinks.has(guid));
    diff.toUnlink = originalLinks.value.filter((guid) => !newLinks.has(guid));
}

async function onCreate(model: Model<Parent, ModelConfig>): Promise<void> {
    if (model.id) {
        handleSelectInput([ ...selectedItems.value, model.id ]);
    }
    await populateList(true);
}

async function populateList(skipLink?: boolean): Promise<void> {
    try {
        // TODO: Remove the `false` below when it is possible to copy an existing Pagination object in lib-crud. See CU-86c0dqvzf
        // eslint-disable-next-line no-constant-condition
        if (props.initialPagination && false) {
            // Clone the provided access moments to remove reference to original Pagination, otherwise we would get unexpected
            // side effects. Linting and TS is complaining about this as the `pageLoader` property is private. However,
            // implementing this will result in one less call.
            // @ts-expect-error The `pageLoader` property is private
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            pagination.value = new Pagination<Model<Parent, ModelConfig>>(props.initialPagination.pageLoader);
        } else {
            // When no pagination has been provided, fetch the items
            pagination.value = props.paginationRelation.list({ defaultSort: props.defaultSort });
        }

        await pagination.value.initialization;

        // When the parent is known to be unlinked, don't perform the extra call of fetching an empty list
        if (!props.linkRelation || props.isUnlinked || skipLink === true) {
            return;
        }

        try {
            const itemList = props.linkRelation.pick();
            await itemList.initialization;

            originalLinks.value = itemList.records
                .filter((item) => !!item.linked)
                .map((model) => model.id || '');
            selectedItems.value = originalLinks.value;
        } catch (e) {
            void handleError(e);

            if (e instanceof Error) {
                emit('error', e);
            }
        }
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}

async function submit(): Promise<void> {
    // If no link relation is set, the parent is responsible for handling the submit.
    if (!props.linkRelation) {
        emit('submit', diff.toLink);
        return;
    }

    try {
        if (diff.toLink.length) {
            await props.linkRelation.linkMulti(...diff.toLink);
        }

        if (diff.toUnlink.length) {
            await props.linkRelation.unlinkMulti(...diff.toUnlink);
        }
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    } finally {
        const toRemove = new Set(diff.toUnlink);

        originalLinks.value = [
            ...originalLinks.value,
            ...diff.toLink,
        ].filter((guid) => !toRemove.has(guid));

        hasLocalChanges.value = false;
        emit('saved', originalLinks.value);
    }
}

// Run this on created
void populateList();

defineExpose({
    hasLocalChanges,
    submit,
    diff,
});
</script>
