<template>
    <OtFilters
        class="shops-filters"
        :categories-to-hide="filterCategoriesToHide"
        :categories-order="filterCategoriesOrder"
        :labels="$t('models.shop')"
        :pagination="shops"
        :translations="$t('dashboard.common.filters')"
        :view-filter="{ label: companyViewFilterSlug }"
    >
        <template #tag__contains_event="{ filter }">
            <OtFilterTag
                v-if="filter.active.scope !== null"
                closable
                :title="$t('models.shop.scopes.contains_event.label')"
                :values="[ eventNameResolver(filter.active.scope, 'scope') ]"
                @close="() => shops.setFilter(filter.attribute, 'scope', null)"
            />
        </template>

        <OtFilterCategoryCustom
            id="contains_event"
            v-slot="{ state }"
            class="shops-filters__custom__contains-event"
            :attributes="[ 'contains_event' ]"
            icon="event"
            :label="$t('models.shop.scopes.contains_event.label')"
        >
            <OtInputSearch
                :placeholder="$t('common.placeholder.search')"
                @input="(val) => events.setFilter('name', 'contains', val || null)"
            />

            <span v-if="events.records.length === 0">
                {{ $t('dashboard.events.list.empty.title') }}
            </span>

            <template v-else>
                <OtInputRadioGroup
                    :value="state.contains_event.scope"
                    :options="containsEventOptions"
                    @input="(val) => state.contains_event.scope = val"
                />

                <!-- TODO: Replace this with OtPaginationControls when moving to Vue-UI -->
                <div
                    v-if="containsEventLastPage > 1"
                    class="shops-filters__custom__contains-event__controls"
                >
                    <div
                        class="shops-filters__custom__contains-event__controls__icon"
                        :disabled="containsEventCurrentPage < 2 || containsEventLastPage < 2"
                        role="button"
                        tabindex="0"
                        @click="shiftContainsEventPage(-1)"
                        @keydown.space="shiftContainsEventPage(-1)"
                        @keydown.enter="shiftContainsEventPage(-1)"
                    >
                        <OtIcon
                            type="carrot-left"
                            size="small"
                        />
                    </div>

                    <span class="ot-text-small">
                        {{ containsEventCurrentPage }} / {{ containsEventLastPage }}
                    </span>

                    <div
                        class="shops-filters__custom__contains-event__controls__icon"
                        :disabled="containsEventCurrentPage >= containsEventLastPage || containsEventLastPage < 2"
                        role="button"
                        tabindex="0"
                        @keydown.space="shiftContainsEventPage(1)"
                        @click="shiftContainsEventPage(1)"
                        @keydown.enter="shiftContainsEventPage(1)"
                    >
                        <OtIcon
                            type="carrot-right"
                            size="small"
                        />
                    </div>
                </div>
            </template>
        </OtFilterCategoryCustom>;
    </OtFilters>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import type { Pagination } from '@openticket/lib-crud';
import type { Event, ManagementClient, Shop } from '@openticket/lib-management';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling, useLocalization } from '../../../composables';

type Props = {
    shops: Pagination<Shop<ManagementClient>>;
}

defineProps<Props>();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const { handleError } = useGenericErrorHandling();
const { t } = useLocalization();

const filterCategoriesToHide = [
    'company_terms',
    'created_at',
    'custom_redirect',
    'event_selection',
    'facebook_auto_attend',
    'facebook_page_url',
    'from_email',
    'from_name',
    'google_tag',
    'greedy_date_selection',
    'updated_at',
];

const filterCategoriesOrder = [
    'name',
    'boolean_filters',
    'contains_event',
];

const events = ref<Pagination<Event<ManagementClient>>>(management.events.list({
    defaultSort: [ [ 'name', 'asc' ] ],
    perPage: 8,
}));

const companyViewFilterSlug = computed<string>(() => (context.isCompanyContext()
    ? context.company.name
    : t('dashboard.sidebar.context_filter.company.all_companies')
));

const containsEventOptions = computed<Record<string, { label: string }>>(() => events.value.records
    .reduce<Record<string, { label: string }>>((previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.id || '']: { label: currentValue.$data.name },
    }), {}));

const containsEventCurrentPage = computed<number>(() => events.value.currentPage || 1);
const containsEventLastPage = computed<number>(() => events.value.lastPage || 1);

function eventNameResolver(guid: string, filtertype: string) {
    const value = reactive({
        filtertype,
        label: '',
    });

    try {
        void management.events.find(guid).then((model) => {
            value.label = model.$data.name;
        });
    } catch (e) {
        void handleError(e);
    }

    return value;
}

async function shiftContainsEventPage(shift: number): Promise<void> {
    try {
        await events.value.loadPage(Math.max(1, containsEventCurrentPage.value + shift));
    } catch (e) {
        void handleError(e);
    }
}
</script>

<style lang="scss" scoped>
.shops-filters {
    &__custom__contains-event {
        white-space: wrap;
        display: flex;
        flex-direction: column;
        gap: var(--ot-spacing-unit);

        &__controls {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: var(--ot-spacing-xs);

            &__icon {
                display: flex;
                align-items: center;
                cursor: pointer;

                &[disabled], &.disabled {
                    color: var(--ot-color-core-accent-primary);
                    pointer-events: none;
                }
            }
        }
    }
}
</style>
