<template>
    <div
        v-if="revisions && showRevisions"
        class="revisions"
    >
        <button
            class="is-text revisions_button"
            @click="showRevisionsModal"
        >
            {{ $t('dashboard.revisions.last_edit') }} {{ lastEditDate }}<br>
            <span v-if="lastEditUser">{{ $t('dashboard.revisions.by') }} <strong>{{ lastEditUser }}</strong></span>
        </button>
        <OtDialogModal
            ref="modalRef"
            :show-close-icon="true"
        >
            <OtCard class="revisions_modal">
                <OtCardHeader
                    :title="$t('dashboard.revisions.title')"
                    :subtitle="modelName"
                />
                <OtCardContent>
                    <RevisionList :mutations="revisions.records || []" />
                    <RevisionPagination
                        v-if="revisions.lastPage && revisions.lastPage > 1"
                        :revisions="revisions"
                    />
                </OtCardContent>
            </OtCard>
        </OtDialogModal>
    </div>
</template>

<script setup lang="ts">
import {
    computed, ref, watch,
} from 'vue';
import {
    OtDialogModal, OtCard, OtCardContent, OtCardHeader,
} from '@openticket/vue-dashboard-components';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { injectOrFail, getPastRelativeTimeString } from '../../services/util';
import RevisionList from './RevisionList.vue';
import type { RevisionModelType } from '../../services/revisor';
import RevisionPagination from './RevisionPagination.vue';
import { useLocalization, useRevisions } from '../../composables';

type Props = {
    type: RevisionModelType
    id?: string | null
    modelName?: string
}

const props = defineProps<Props>();

const { revisions, updateRevisions } = useRevisions(props.type, computed(() => props.id));

const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const { localization } = useLocalization();

const modalRef = ref<InstanceType<typeof OtDialogModal> | null>(null);
const lastEditDate = ref('');
const lastEditUser = ref('');

const showRevisions = computed(() => revisions.value && revisions.value.records && revisions.value.records.length > 0);

watch([ () => revisions.value?.records, () => localization.locale.language ], () => {
    if (revisions.value?.currentPage === 1) {
        setLastEdited();
    }
});

function setLastEdited() {
    if (!revisions.value || !revisions.value.records || !revisions.value.records.length) {
        return;
    }

    const firstRecord = revisions.value.records.at(0);

    if (!firstRecord) {
        return;
    }

    lastEditDate.value = getPastRelativeTimeString(new Date(firstRecord.time), localization.locale.language);

    if (firstRecord.meta) {
        lastEditUser.value = firstRecord.meta.anonymous ? whitelabel.name : firstRecord.meta.user_name;
    }
}

function showRevisionsModal() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    modalRef.value?.show();
}

defineExpose({
    updateRevisions,
});
</script>

<style lang="scss" scoped>
.revisions {
    color: var(--ot-color-core-light-accent-primary);

    &_button {
        display: inline-block;
        text-align: right;
        font-size: 11px;
        line-height: 12px;

        > span {
            display: inline-block;
            width: 18ch;
            margin-top: 2px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        strong {
            font-weight: medium;
        }
    }
}
</style>
