<template>
    <div class="event-wizard-details">
        <div class="event-wizard-details__docs">
            <OtDocsSection
                :label="$t('dashboard.common.read_docs')"
                :type="'calendar'"
                :url="$whitelabel.dashboard.docs_urls?.events?.new"
            >
                <h1>{{ $t('dashboard.events.wizard.steps.details.title') }}</h1>
                <span>{{ $t('dashboard.events.wizard.steps.details.description') }}</span>
            </OtDocsSection>
        </div>

        <div class="event-wizard-details__form">
            <EventForm ref="eventForm" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import EventForm from '../forms/EventForm.vue';

const eventForm = ref<InstanceType<typeof EventForm> | null>(null);

function submit() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
    return eventForm.value?.submit();
}

defineExpose({
    submit,
});
</script>

<style lang="scss" scoped>
.event-wizard-details {
    &__docs {
        padding: var(--ot-spacing-xl) 0;
        border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);
        margin-bottom: var(--ot-spacing-xl);

        h1 {
            color: var(--ot-color-core-light-foreground-primary);
            margin-bottom: var(--ot-spacing-xs);
        }

        span {
            color: var(--ot-color-core-light-foreground-secondary);
        }
    }
}
</style>
