<template>
    <div
        v-if="events && !error"
        class="module__events__list"
    >
        <ComingSoon :number-of-graphs="2" />

        <OtDataGrid
            class="module__events__list__grid"
            type="tiles"
            :pagination="events"
            :columns="columns"
            show-switcher
            :empty-title="$t('dashboard.events.list.empty.title')"
            :empty-message="$t('dashboard.events.list.empty.message')"
            :empty-message-active-filter="$t('dashboard.common.filters.not_found')"
            :loading-message="$t('dashboard.events.list.header.loading')"
        >
            <template #list-header="{ typeSwitcher }">
                <OtListHeader
                    :pagination="events"
                    searchable
                    :search-key="'name'"
                    :search-placeholder="$t('dashboard.events.list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.events.list')"
                >
                    <template #filters>
                        <EventsFilters :events="events" />
                    </template>

                    <template #actions-top>
                        <button
                            class="ot-button is-small"
                            data-testid="event-wizard-btn"
                            @click="eventCompanySelector?.open()"
                        >
                            <OtIcon
                                type="plus"
                                class="ot-button-icon--left"
                            />
                            {{ $t('dashboard.events.new.title') }}
                        </button>
                    </template>

                    <template #actions-bottom>
                        <component :is="typeSwitcher" />
                    </template>
                </OtListHeader>
            </template>

            <template #empty-action-no-filter>
                <button
                    class="ot-button is-outline"
                    data-testid="event-wizard-btn"
                    @click="eventCompanySelector?.open()"
                >
                    <OtIcon
                        type="plus"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.events.new.title') }}
                </button>
            </template>

            <template #tile="{ record }">
                <OtAdvancedTile
                    class="module__events__list__grid__record"
                    clickable
                    :record="record"
                    :title="record.$data.name"
                    @click="goToEventsHome(record.$data.guid)"
                >
                    <template #content>
                        <EventsListRecord :record="record" />
                    </template>
                </OtAdvancedTile>
            </template>

            <template #column__-data__company_id="{ record, value }">
                <a
                    class="module__events__list-record__company-name ot-link ot-clickable"
                    role="button"
                    tabindex="0"
                    @click="goToCompaniesHome(record.$data.company_id)"
                    @keydown.space="goToCompaniesHome(record.$data.company_id)"
                    @keydown.enter="goToCompaniesHome(record.$data.company_id)"
                >
                    {{ companyName(value) }}
                </a>
            </template>

            <template #column__-data__start="{ value }">
                {{ value ? $l.dateTime(value) : null }}
            </template>

            <template #column__-data__end="{ value }">
                {{ value ? $l.dateTime(value) : null }}
            </template>

            <template #actions="{ record }">
                <OtTileActions
                    :buttons="[
                        {
                            title: $t('dashboard.events.list.view.title', { event: record.$data.name }),
                            text: $t('dashboard.events.list.view.text', { ticket: record.$data.name }),
                            icon: 'carrot-right',
                            classes: 'is-outline is-small',
                            clickHandler: () => goToEventsHome(record.$data.guid)
                        }
                    ]"
                />
            </template>
        </OtDataGrid>

        <CompanySelector
            ref="eventCompanySelector"
            :create-model-label="$t('dashboard.events.model_name').toString().toLocaleLowerCase()"
            @selected="handleNewEvent"
        />
    </div>

    <ErrorView
        v-else-if="error"
        :label="error.message"
    />

    <OtSpinner v-else />
</template>

<script lang="ts" setup>
import type { ManagementClient } from '@openticket/lib-management';
import type { Column } from '@openticket/vue-dashboard-components';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router/composables';
import type { AuthClient, CompanyRaw } from '@openticket/lib-auth';
import type { Context } from '../../../services/context';
import EventsListRecord from '../components/EventsListRecord.vue';
import ErrorView from '../../../components/ErrorView.vue';
import { injectOrFail } from '../../../services/util';
import ComingSoon from '../../../components/ComingSoon.vue';
import { useGenericErrorHandling, useLocalization, useRouterPagination } from '../../../composables';
import CompanySelector from '../../../components/CompanySelector.vue';
import EventsFilters from '../components/EventsFilters.vue';

const { t } = useLocalization();
const router = useRouter();
const { error } = useGenericErrorHandling();

const auth = injectOrFail<AuthClient>('auth');
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const companies = ref<CompanyRaw[]>([]);
const eventCompanySelector = ref<InstanceType<typeof CompanySelector> | null>(null);

const { list: events, setPagination } = useRouterPagination(management.events, {
    defaultFilters: { upcoming: { scope: '' } },
    defaultSort: [ [ 'start', 'asc' ] ],
    perPage: 10,
});

const columns: Array<Column> = [
    {
        key: [ '$data', 'name' ],
        label: t('models.event.attributes.name.label'),
        type: 'string',
    },
    {
        key: [ '$data', 'company_id' ],
        type: 'custom',
        label: t('models.company.name.single'),
    },
    {
        key: [ '$data', 'start' ],
        type: 'date',
        label: t('models.event.attributes.start.label'),
    },
    {
        key: [ '$data', 'end' ],
        type: 'date',
        label: t('models.event.attributes.end.label'),
    },
];

watch(() => context.company, (newValue, oldValue) => {
    if (!context.isEventContext() && newValue?.id !== oldValue?.id) {
        void setPagination(management.events);
    }
});

async function goToCompaniesHome(companyId: string) {
    await context.updateContext('company', { company: companyId });
}

async function goToEventsHome(eventId: string) {
    await router.push({
        name: 'events.home',
        params: { event: eventId },
    });
}

function handleNewEvent() {
    void router.push({
        name: 'events.new',
        params: {
            company: context?.company?.id,
        },
    });
}

async function prepareCompanyNameResolver(): Promise<void> {
    companies.value = (await auth.$token.$info)?.companies ?? [];
}

function companyName(companyId: string): string | undefined {
    return companies.value.find((singleCompany) => singleCompany.guid === companyId)?.name;
}

void prepareCompanyNameResolver();
</script>

<style lang="scss" scoped>
.module__events__list {
    padding-top: var(--ot-spacing-default);

    &__filters {
        display: flex;
        flex-direction: row;
        gap: var(--ot-spacing-default);
    }

    &-record__company-name::after {
        display: none;
    }
}
</style>
