<template>
    <OtSimpleTile
        :title="record.$data.name"
        :record="record"
        clickable
        class="module__access-moments__list__grid__tile"
        @click="goToAccessMomentEditPage()"
    >
        <template #title>
            <div class="module__access-moments__list__grid__tile__title">
                <h3>{{ record.$data.name || '-' }}</h3>
                <OtFlair
                    icon="linked"
                    inversed
                    :type="ticketsCount > 0 ? 'is-brand' : 'is-neutral'"
                >
                    {{ String(
                        $tc('dashboard.access_moments.list.tile.ticket_count', ticketsCount)
                    ).toLocaleUpperCase() }}
                </OtFlair>
            </div>
        </template>

        <template #column__-data__location_id>
            {{ locationName }}
        </template>

        <template #column__-data__start="{ value }">
            {{ $l.dateTime(value) }}
        </template>

        <template #column__-data__end="{ value }">
            {{ $l.dateTime(value) }}
        </template>

        <template #actions>
            <OtTileActions
                :buttons="[
                    {
                        title: $t('dashboard.access_moments.list.link_tickets.link.title', { moment: record.$data.name }),
                        text: $t('dashboard.access_moments.list.link_tickets.link.text'),
                        icon: 'plus',
                        classes: 'is-outline',
                        clickHandler: () => $emit('link-action'),
                    },
                    {
                        title: $t('dashboard.access_moments.list.tile.edit.title', { name: record.$data.name }),
                        text: $t('dashboard.access_moments.list.tile.edit.text'),
                        icon: 'carrot-right',
                        classes: 'is-outline',
                        clickHandler: () => goToAccessMomentEditPage()
                    }
                ]"
            />
        </template>
    </OtSimpleTile>
</template>

<script setup lang="ts">
import type {
    EventDate, ManagementClient, Location,
} from '@openticket/lib-management';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useGenericErrorHandling } from '../../../composables/useGenericErrorHandling';
import { injectOrFail } from '../../../services/util';

type Props = {
    record: EventDate<ManagementClient>;
}

type Emits = {
    (e: 'link-action'): void,
}

const props = defineProps<Props>();
defineEmits<Emits>();

const { handleError } = useGenericErrorHandling();
const router = useRouter();

const management = injectOrFail<ManagementClient>('management');
const location = ref<Location<ManagementClient>>();

const locationName = computed(() => (location.value && location.value.$data.name) ?? '-');
const ticketsCount = computed(() => (props.record.$data && props.record.$data.tickets_count) ?? 0);

void (async () => {
    if (!props.record.$data) {
        return;
    }

    try {
        location.value = props.record.$data.location_id
            ? await management.locations.find(props.record.$data.location_id)
            : undefined;
    } catch (error) {
        handleError(error);
    }
})();

function goToAccessMomentEditPage(): void {
    if (!props.record.$data.guid) {
        return;
    }

    void router.push({
        name: 'access-moments.home',
        params: { accessMoment: props.record.$data.guid },
    });
}
</script>

<style scoped lang="scss">
.module__access-moments__list__grid__tile {

    &__title {
        display: flex;
        gap: var(--ot-spacing-xs);

        h3 {
            flex: none;
        }

        &::v-deep .ot-flair__content {
            padding: 0 var(--ot-spacing-xs);
        }
    }
}
</style>
