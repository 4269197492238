<template>
    <div class="ticket-details-form">
        <OtFormRow>
            <InputField
                :label="$t('dashboard.tickets.details.inputs.ticket_name.label')"
                :description="$t('dashboard.tickets.details.inputs.ticket_name.description')"
                :required="form.rules.name && form.rules.name.includes('required')"
                :optional="form.rules.name && form.rules.name.includes('optional')"
                :error="form.errors.name"
            >
                <OtInput
                    v-model="form.model.$data.name"
                    class="input"
                    type="text"
                    @input="form.errors.name = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                :label="$t('dashboard.tickets.details.inputs.ticket_capacity.label')"
                :description="$t('dashboard.tickets.details.inputs.ticket_capacity.description')"
                :required="form.rules.available_stock && form.rules.available_stock.includes('required')"
                :optional="form.rules.available_stock && form.rules.available_stock.includes('optional')"
                :error="form.errors.available_stock"
            >
                <InputFloatUnlimited
                    v-model="form.model.$data.available_stock"
                    @input="form.errors.available_stock = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputTicketPrice
                :currency="context.event.model.$data.currency"
                :form="form"
            />
        </OtFormRow>

        <OtFormRow v-if="flags['app__access_moments__navigation']">
            <InputField
                :label="$t('dashboard.access_moments.tickets.label')"
                :description="$t('dashboard.access_moments.tickets.description')"
                optional
            >
                <!-- TODO: replace with generic LinkUnlinkPicker -->
                <AccessMomentsPicker
                    ref="accessMomentsPickerRef"
                    :ticket="context.ticket.model"
                />
            </InputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import { ref, type UnwrapNestedRefs, watch } from 'vue';
import type { ManagementClient, Ticket } from '@openticket/lib-management';
import InputField from '../../../../components/form/InputField.vue';
import InputFloatUnlimited from '../../../../components/InputFloatUnlimited.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';
import InputTicketPrice from '../../../../components/form/InputTicketPrice.vue';
import AccessMomentsPicker from '../../../access-moments/components/AccessMomentsPicker.vue';
import type { FeatureFlagPlugin } from '../../../../plugins/featureFlag.plugin';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Ticket<ManagementClient>, ManagementClient>>
}

const props = defineProps<Props>();

const context = injectOrFail<Context>('context');
const { flags } = injectOrFail<FeatureFlagPlugin>('featureFlags');

if (!context.isTicketContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const accessMomentsPickerRef = ref<InstanceType<typeof AccessMomentsPicker> | null>(null);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
watch(() => accessMomentsPickerRef.value?.hasLocalChanges, (value) => {
    if (value) {
        props.form.hasLocalChanges = true;
    }
});

async function linkAccessMoments(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    await accessMomentsPickerRef.value?.submit();
}

defineExpose({
    linkAccessMoments,
});
</script>
