import type { Route, RouteConfig } from 'vue-router';

import ShopsListView from './views/ShopsListView.vue';
import ShopTicketsView from './views/ShopTicketsView.vue';
import ShopStylingView from './views/ShopStylingView.vue';
import WelcomeMessageWrapper from '../../layouts/WelcomeMessageWrapper.vue';
import { onCompanyContextSelect } from '../../services/context/contextFilter';
import ShopHomeWrapper from './layouts/ShopHomeWrapper.vue';
import { RouterView } from '../../services/util';
import ShopUpdateView from './views/ShopUpdateView.vue';

export function shopsRoutes(): RouteConfig[] {
    return [

        // Redirect legacy dashboard shop styling url to current shop styling route
        {
            path: 'shops/:shop/settings',
            redirect: (to: Route) => ({
                name: 'shops.styling.style',
                params: {
                    shop: to.params.shop,
                },
                query: {
                    legacy: 'true', // Removes query params like access_token, as and locale but keeps legacy
                },
            }),
        },

        {
            path: 'shops',
            component: RouterView,
            children: [
                {
                    path: '',
                    name: 'shops.list',
                    component: ShopsListView,
                    meta: {
                        context: 'global',
                        contextIfSet: 'company',
                        title: 'dashboard.document_title.shops.list',
                        titleFallback: 'Shops',
                        onContextSelect: onCompanyContextSelect,
                    },
                },
                {
                    path: ':shop',
                    component: WelcomeMessageWrapper,
                    children: [
                        {
                            path: '',
                            name: 'shops.home',
                            component: ShopHomeWrapper,
                            meta: {
                                context: 'shop',
                                title: 'dashboard.document_title.shops.shop',
                                titleFallback: 'Shop',
                                onContextSelect(id) {
                                    if (!id || typeof id === 'symbol') {
                                        return null;
                                    }
                                    return {
                                        params: { shop: id },
                                    };
                                },
                            },
                            redirect: (to: Route) => ({
                                name: 'shops.tickets',
                                params: { ...to.params },
                            }),
                            children: [
                                {
                                    path: 'tickets',
                                    name: 'shops.tickets',
                                    component: ShopTicketsView,
                                    meta: {
                                        title: 'dashboard.document_title.shops.tickets',
                                        titleFallback: 'Tickets',
                                    },
                                },
                                {
                                    path: 'styling',
                                    name: 'shops.styling',
                                    component: ShopStylingView,
                                    meta: {
                                        title: 'dashboard.document_title.shops.styling',
                                        titleFallback: 'Styling',
                                        onContextSelect(id) {
                                            if (!id || typeof id === 'symbol') {
                                                return null;
                                            }
                                            return {
                                                name: 'shops.styling.style',
                                                params: { shop: id },
                                            };
                                        },
                                    },
                                    redirect: (to: Route) => ({
                                        name: 'shops.styling.style',
                                        params: { ...to.params },
                                    }),
                                    children: [
                                        {
                                            path: 'style',
                                            name: 'shops.styling.style',
                                            redirect: (to: Route) => ({
                                                name: 'shops.styling.template',
                                                params: { ...to.params },
                                            }),
                                        },
                                        {
                                            path: 'template',
                                            name: 'shops.styling.template',
                                        },
                                        {
                                            path: 'colors',
                                            name: 'shops.styling.colors',
                                        },
                                        {
                                            path: 'shop',
                                            name: 'shops.styling.shop',
                                            redirect: (to: Route) => ({
                                                name: 'shops.edit.shop',
                                                params: { ...to.params },
                                            }),
                                        },
                                        {
                                            path: 'order',
                                            name: 'shops.styling.order',
                                            redirect: (to: Route) => ({
                                                name: 'shops.edit.order',
                                                params: { ...to.params },
                                            }),
                                        },
                                    ],
                                },
                                {
                                    path: 'edit',
                                    name: 'shops.edit',
                                    component: ShopUpdateView,
                                    meta: {
                                        title: 'dashboard.document_title.shops.settings',
                                        titleFallback: 'Shop Settings',
                                    },
                                    redirect: (to: Route) => ({
                                        name: 'shops.edit.details',
                                        params: { ...to.params },
                                    }),
                                    children: [
                                        {
                                            path: 'details',
                                            name: 'shops.edit.details',
                                        },
                                        {
                                            path: 'shop',
                                            name: 'shops.edit.shop',
                                        },
                                        {
                                            path: 'order',
                                            name: 'shops.edit.order',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],

                },
            ],
        },
    ];
}
