<template>
    <div class="debug-plugins__hotkeys">
        <code
            v-for="(hotkey, i) in hotkeys"
            :key="`hotkey__${i}__${hotkey.key}`"
            class="debug-plugins__hotkeys__hotkey full-width"
        >
            <span>{{ hotkey.description }}</span>

            <span>
                <template v-for="char in hotkey.parsedModifiers"><span
                    :key="`hotkey__${i}__${hotkey.key}__${char}`"
                    class="debug-plugins__hotkeys__hotkey__key"
                >{{ char }}</span>+</template>

                <span class="debug-plugins__hotkeys__hotkey__key">{{ hotkey.key }}</span>
            </span>
        </code>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { PluginsManager, HotkeyListener } from '../../../../plugins';
import { injectOrFail } from '../../../../services/util';

const plugins = injectOrFail<PluginsManager>('plugins');

type Hotkeys = Array<HotkeyListener & { parsedModifiers: string[] }>;
const hotkeys = computed<Hotkeys>(() => plugins.hotkeys.flatActions.map((action) => {
    const parsedModifiers: string[] = [];

    if (action.modifiers.metaKey) {
        parsedModifiers.push('❖');
    }

    if (action.modifiers.ctrlKey) {
        parsedModifiers.push('⎈');
    }

    if (action.modifiers.altKey) {
        parsedModifiers.push('⎇');
    }

    if (action.modifiers.shiftKey) {
        parsedModifiers.push('⇧');
    }

    return {
        ...action,
        parsedModifiers,
    };
}));
</script>

<style lang="scss" scoped>
.debug-plugins__hotkeys {
    &__hotkey {
        display: grid;
        margin-top: 1px;
        gap: 1px;
        grid-template-columns: 1fr max-content;
        vertical-align: center;
        font-size: 0.75rem;
        line-height: 1.8rem;

        &__key {
            display: inline-block;
            margin: .2rem .1rem;
            padding: .1rem;
            border: 1px rgba(0 0 0 /30%) solid;
            border-radius: .1rem;

            font-size: 1.3em;
            line-height: 1;
            box-shadow: rgba(0 0 0 /30%) 1px 1px 1px;
        }

    }
}

.debug-plugins__hotkeys::v-deep {
    code.debug-plugins__hotkeys__hotkey {
        display: grid;
    }
}
</style>
