import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import type { AuthClient, FinalizeToken } from '@openticket/lib-auth';
import type { RudderAnalytics } from '@rudderstack/analytics-js';
import { isAxiosError } from 'axios';
import { injectOrFail } from '../../../services/util';
import { isErrorDescriptionAxiosError } from '../../../services/http/axios';

type Errors = {
  notLoggedOut: string | null;
  token: string | null;
}

export function useResetTokenCheck(type: 'forgotPassword' | 'finalizeUser') {
    const auth = injectOrFail<AuthClient>('auth');
    const rudderstack = injectOrFail<RudderAnalytics>('rudderstack');

    const router = useRouter();
    const route = useRoute();

    const errors = reactive<Errors>({
        notLoggedOut: null,
        token: null,
    });

    async function checkValidResetToken(
        finallyCallback?: () => void,
        successCallback?: (response?: FinalizeToken<AuthClient>) => void,
    ) {
        errors.notLoggedOut = null;
        errors.token = null;

        const { token } = route.params;

        if (type === 'forgotPassword' && !token) {
            void router.push({
                name: 'error',
                query: {
                    reason: 'Password reset token is missing',
                },
            });

            return;
        }

        try {
            if (type === 'forgotPassword') {
                await auth.passwords.new().forgotPassword.checkToken(token);
                if (successCallback) {
                    successCallback();
                }
            }

            if (type === 'finalizeUser') {
                const response = await auth.finalizeUser.checkToken(token);
                if (successCallback) {
                    successCallback(response);
                }
            }
        } catch (e: unknown) {
            if (!e
                || typeof e !== 'object'
                || !('axiosError' in e)
                || !e.axiosError
                || !isAxiosError(e.axiosError)
                || !isErrorDescriptionAxiosError(e.axiosError)
            ) {
                void router.push({ name: 'error', query: { ...route.query, reason: 'Broked error' } });

                return;
            }

            const { axiosError } = e;

            const { error_description } = axiosError.response.data;

            if (
                axiosError.response.status === 400 && typeof error_description === 'string'
            ) {
                rudderstack.track(`vue-dashboard fix ${type} not logged out error`);
                errors.notLoggedOut = error_description;
                return;
            }

            if (axiosError.response.status === 404 && typeof error_description === 'string') {
                rudderstack.track(`vue-dashboard fix ${type} token error`);
                errors.token = error_description;
                return;
            }

            // Catch all scenario for any other error case
            void router.push({ name: 'error' });
        } finally {
            if (finallyCallback) {
                finallyCallback();
            }
        }
    }

    return {
        tokenErrors: errors,
        checkValidResetToken,
    };
}
