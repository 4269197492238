<template>
    <OtSimpleTile
        :title="record.$data.name"
        :record="record"
        class="ticket-list__record"
        clickable
        @click="goToTicketEditPage(record.$data.event_id, record.$data.guid)"
    >
        <template #asset>
            <img
                class="ticket-list__record-asset"
                src="../../../assets/images/ticket_asset.svg"
                alt="Ticket Asset"
            >
        </template>

        <template #title>
            <div class="ticket-list__record-title">
                <h3>{{ record.$data.name }}</h3>
                <OtFlair
                    v-if="record.$data.status_overrule === 'sold_out'"
                    icon="check"
                    type="is-black"
                >
                    {{ String(
                        $t('dashboard.tickets.sale_settings.inputs.ticket_availability.options.sold_out')
                    ).toLocaleUpperCase() }}
                </OtFlair>
            </div>
        </template>

        <template #column__-data__available_stock="{ record: columnRecord , value }">
            <FractionColumn
                :numerator="columnRecord.$data.sold_count"
                :denominator="value"
            />
        </template>

        <template #column__-data__min_price="{ value }">
            <!-- TODO Pricing here is a lot harder than just min_price. Needs to be discussed!! -->
            {{ value === 0 ? $t('dashboard.tickets.details.inputs.ticket_price.free') : $l.currency(value, currency) }}
        </template>

        <template #actions>
            <OtTileActions
                :buttons="[
                    {
                        title: $t('dashboard.tickets.list.edit.title', { ticket: record.$data.name }),
                        text: $t('dashboard.tickets.list.edit.text', { ticket: record.$data.name }),
                        icon: 'carrot-right',
                        classes: 'is-outline',
                        clickHandler: () => goToTicketEditPage(record.$data.event_id, record.$data.guid)
                    }
                ]"
            />
        </template>
    </OtSimpleTile>
</template>

<script setup lang="ts">
import type { ManagementClient, Ticket } from '@openticket/lib-management';
import type VueRouter from 'vue-router';
import { useRouter } from 'vue-router/composables';
import FractionColumn from '../../../components/columns/FractionColumn.vue';

type Props = {
    record: Ticket<ManagementClient>;
    currency: string;
}

defineProps<Props>();
const router: VueRouter = useRouter();

function goToTicketEditPage(event?: string, ticket?: string): void {
    if (event && ticket) {
        void router.push({
            name: 'tickets.edit',
            params: { event, ticket },
        });
    }
}
</script>

<style scoped lang="scss">
@import '../../../assets/scss/mixins.scss';

.ticket-list__record {
    &-asset {
        height: 100%;
        margin-right: var(--ot-spacing-xl);

        @include breakpoint(mob) {
            margin-right: var(--ot-spacing-xs);
        }

        & img {
            width: 100%;
            height: 100%;
        }
    }

    &-title {
        display: flex;
        gap: var(--ot-spacing-xs);

        h3 {
            flex: none;
        }

        &::v-deep .ot-flair__content {
            padding: 0 var(--ot-spacing-xs);
        }
    }
}
</style>
