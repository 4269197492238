<template>
    <OtCard
        class="tfa-totp-setup"
        no-padding
    >
        <ModalHeader
            :hide-logo="tfaSetupProvider.hideLogo"
            :hide-logout="tfaSetupProvider.hideLogout"
            text-centered
        >
            <template #title>
                <h1>{{ $t('dashboard.user.tfa.setup.totp.title') }}</h1>
            </template>

            <template
                v-if="!prepared"
                #subtitle
            >
                {{ $t('dashboard.user.tfa.setup.totp.explanation') }}
            </template>

            <template
                v-if="prepared && (error.incorrectTFAInput || error.incorrectPassword)"
                #extra
            >
                <h5 class="warning">
                    {{ $t('dashboard.user.tfa.setup.warning') }}
                </h5>
            </template>
        </ModalHeader>

        <form @submit.prevent="submit()">
            <OtCardContent>
                <img
                    v-if="!setup"
                    class="tfa-totp-setup__loading"
                    :src="$whitelabel.resources.spinner"
                    alt="loading..."
                >

                <TfaTotpPrepare
                    v-else-if="!prepared"
                    :totp="setup.data.totp"
                />

                <TfaTotpConfirm
                    v-else
                    v-model="formData"
                    :error="error"
                />
            </OtCardContent>

            <OtCardFooter class="tfa-totp-setup__footer">
                <template
                    v-if="!tfaSetupProvider.hideBackButton || prepared"
                    #left
                >
                    <button
                        class="ot-button is-dark"
                        type="button"
                        :title="$t('dashboard.common.action.back.title')"
                        @click="back"
                    >
                        <OtIcon
                            class="ot-button-icon--left"
                            type="arrow-left"
                        />
                        {{ $t('dashboard.common.action.back.text') }}
                    </button>
                </template>

                <template #right>
                    <button
                        v-if="setup && prepared"
                        class="ot-button"
                        :class="{'is-loading': submitting}"
                        type="submit"
                        :disabled="!formCompleted || submitting"
                        :title="$t('dashboard.user.tfa.action.validate_and_enable.title')"
                    >
                        <OtIcon
                            class="ot-button-icon--left"
                            type="check"
                        />
                        {{ $t('dashboard.user.tfa.action.validate_and_enable.text') }}
                    </button>
                    <button
                        v-else
                        class="ot-button"
                        type="button"
                        :disabled="!setup"
                        :title="$t('dashboard.common.action.next.title')"
                        @click="next"
                    >
                        {{ $t('dashboard.common.action.next.text') }}
                        <OtIcon
                            class="ot-button-icon--right"
                            type="arrow-right"
                        />
                    </button>
                </template>
            </OtCardFooter>
        </form>
    </OtCard>
</template>

<script setup lang="ts">
import type { AuthClient } from '@openticket/lib-auth';
import type { TFAError } from '@openticket/vue-tfa-confirmation';
import urlJoin from 'url-join';
import { computed, ref } from 'vue';
import type { PluginsManager } from '../../../plugins';
import ModalHeader from '../../modal/ModalHeader.vue';
import TfaTotpConfirm from './TfaTotpConfirm.vue';
import TfaTotpPrepare from './TfaTotpPrepare.vue';
import type { TfaSetupProvider, TfaTotpData, TotpSetup } from '../types';
import { injectOrFail } from '../../../services/util';

interface TotpSetupData {
    data: {
        totp: TotpSetup;
    }
}

type Props = {
    submitting: boolean,
    error: TFAError,
}

type Emits = {
    (e: 'close'): void,
    (e: 'submit', path: string, data: {password: string, input: string, config: string}): void
}

withDefaults(
    defineProps<Props>(),
    {
        submitting: false,
        error: () => ({
            incorrectPassword: null,
            incorrectTFAInput: null,
        }),
    },
);

const emit = defineEmits<Emits>();

const plugins = injectOrFail<PluginsManager>('plugins');
const tfaSetupProvider = injectOrFail<TfaSetupProvider>('TfaSetupProvider');

const setup = ref<TotpSetupData | null>(null);
const prepared = ref<boolean>(false);

const formData = ref<TfaTotpData>({
    password: null,
    input: null,
});

const formCompleted = computed(() => !!formData.value.password && !!formData.value.input);

void (async () => {
    const auth: AuthClient = await plugins.auth.loading;
    // TODO - @openticket/lib-auth
    setup.value = await auth.$http.get(urlJoin(auth.$path, 'twofactor', 'totp', 'prepare'));
})();

const back = (): void => {
    if (prepared.value) {
        prepared.value = false;
        formData.value.password = null;
        formData.value.input = null;
    } else {
        emit('close');
    }
};

const next = (): void => {
    if (!prepared.value) {
        prepared.value = true;
    }
};

const submit = (): void => {
    if (setup.value && formData.value.password && formData.value.input) {
        emit(
            'submit',
            '/twofactor/totp/confirm',
            {
                password: formData.value.password,
                input: formData.value.input,
                config: setup.value.data.totp.config,
            },
        );
    }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.tfa-totp-setup {
    border-radius: var(--ot-spacing-xs);

    &__footer {
        @include breakpoint(mob) {
            background: white !important;
            flex-direction: column;
            gap: var(--ot-spacing-xs);
        }

        button {
            @include breakpoint(mob) {
                width: 100%;
            }
        }
    }

    .warning {
        color: var(--ot-color-accent-orange-dark);
    }
}

::v-deep {
    & .card-footer__left,
    & .card-footer__right {
        & > * {
            @include breakpoint(mob) {
                margin: 0 !important;
            }
        }
    }

    & .card-footer__left {
        @include breakpoint(mob) {
            order: 3;
        }
    }

    & .card-footer__right {
        @include breakpoint(mob) {
            order: 1;
        }
    }
}
</style>
