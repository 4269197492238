import {
    OtLocaleFlag,
    OtLocaleSwitch,
} from '@openticket/vue-localization';
import '@openticket/vue-localization/lib/style.css';
import { reactive, type VueConstructor } from 'vue';
import {
    Localization,
    type LocaleKeys,
    type LocalizationContract,
    type PartialConfig,
} from '@openticket/lib-localization';
import VueI18n from 'vue-i18n';
import { getGlobal, type OpenTicketGlobal } from '../openticket';
import type { PluginsManager } from './manager';
import type { LocalizationOptions } from './types';
import { Plugin } from './plugin';

export class LocalizationPlugin extends Plugin<LocalizationContract> {

    /**
     * Holds the translation package's logic
     */
    private _i18n!: VueI18n;

    /**
     * Holds the localization instance
     */
    private _localization!: LocalizationContract;

    /**
     * Change the vueI18n locale and update the html lang attribute if available.
     *
     * Data should be merged separately when updated.
     */
    private _localeChanged(locale: LocaleKeys): void {
        this._i18n.locale = locale;
    }

    /**
     * Add the loaded translation data to the vueI18n instance.
     */
    private _translationDataUpdated(locale: LocaleKeys): void {
        this._i18n.mergeLocaleMessage(locale, this._localization.getMessagesFor(locale));
    }

    async install(plugins: PluginsManager, Vue: VueConstructor): Promise<void> {
        try {
            // Registers the translation methods on the vue instance before initializing its
            // instance in the OpenTicketLocalizationVue package.
            // --- Normally VueI18n is installed manually here, but VueI18n also supports auto install.
            // --- Due to VueLocalization not exporting the augmented VueI18n constructor,
            // --- it is less bad to let it auto-install here.
            // --- window.Vue (VueConstructor) is needed for this.
            // Vue.use(VueI18n);
            window.Vue = window.Vue || Vue;

            const options: LocalizationOptions = plugins.options.localization || {};

            this._localization = reactive(new Localization(undefined));

            const vueI18nOptions: VueI18n.I18nOptions = {
                fallbackLocale: this._localization.locale.locale,
                locale: this._localization.locale.locale,
                silentTranslationWarn: true,
            };

            if (options.defaultMessages) {
                vueI18nOptions.messages = {};

                vueI18nOptions.messages[this._localization.locale.locale] = options.defaultMessages;
            }

            // TODO: Move to Vue-UI when switching to Vue 3
            this._i18n = new VueI18n(vueI18nOptions);

            // Mix the i18n instance into the Vue constructor options,
            // so it is available BEFORE running any beforeCreate hooks.
            // When installing (Vue.use) the VueI18n plugin, it mixes in a beforeCreate hook,
            // which triggers for ALL components.
            // Which requires (fails terribly otherwise) the i18n option to be set.
            Vue.mixin({
                i18n: this._i18n,
                provide: {
                    i18n: this._i18n,
                    localization: this._localization,
                },
            });

            const OT: OpenTicketGlobal = getGlobal();

            OT.Localization = this._localization;

            this._localization.on('locale-change', (locale: LocaleKeys) => {
                this._localeChanged(locale);
            });

            this._localization.on('translation-data-update', (locale: LocaleKeys) => {
                this._translationDataUpdated(locale);
            });

            // Replace `Vue.prototype` with `app.config.globalProperties` in Vue 3
            Object.defineProperty(Vue.prototype, '$localization', {
                get: () => this._localization,
                set: () => {
                    console.warn('Re-setting [$localization] is not allowed, ignoring.');
                },
            });
            Object.defineProperty(Vue.prototype, '$l', {
                get: () => this._localization.formatters,
                set: () => {
                    console.warn('Re-setting [$l] is not allowed, ignoring.');
                },
            });
            Object.defineProperty(Vue.prototype, '$p', {
                get: () => this._localization.parsers,
                set: () => {
                    console.warn('Re-setting [$p] is not allowed, ignoring.');
                },
            });

            Vue.component('OtLocaleFlag', OtLocaleFlag);
            Vue.component('OtLocaleSwitch', OtLocaleSwitch);

            this.resolveRegistration();

            let defaults: Array<string | null | Array<string | null>> | undefined;

            // Due to node_modules caching, it might be possible that `preferredLocales` is undefined. This try-catch ensures that
            // the app does not crash. Delete your node_modules directory, install packages and everything should behave correctly
            try {
                const localeOverwrite: string | null = new URLSearchParams(window.location.search).get('locale') || null;
                const preferredLocales = this._localization.preferredLocales();

                defaults = [
                    preferredLocales.userOverwrite,
                    localeOverwrite,
                    preferredLocales.browser,
                ];
            } catch (e) { /* No-op */ }

            const config: PartialConfig = {
                locale: {
                    defaults,
                    supported: [ 'en_GB', 'en_US', 'nl_NL', 'de_DE', 'fr_FR', 'es_ES' ],
                },
            };

            if (import.meta.env.VITE_DEFAULT_TRANSLATIONS_URL) {
                config.urls = {
                    translations: import.meta.env.VITE_DEFAULT_TRANSLATIONS_URL,
                };
            }

            await this._localization.init(config);

            this.resolve(this._localization);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

    get i18n(): VueI18n {
        return this._i18n;
    }

    get localization(): LocalizationContract {
        return this._localization;
    }

}
