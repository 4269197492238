<template>
    <div class="profile-settings-security">
        <ProfileTfaManagement />

        <div class="ot-separator ot-my[xl]" />

        <ProfileChangePasswordForm :form="passwordForm" />
    </div>
</template>

<script setup lang="ts">
import type { AuthClient, Password, ResetPassword } from '@openticket/lib-auth';
import { reactive } from 'vue';
import ProfileChangePasswordForm from '../components/forms/ProfileChangePasswordForm.vue';
import ProfileTfaManagement from '../components/ProfileTfaManagement.vue';
import { injectOrFail } from '../../../services/util';
import { useCustomValidatedPostForm } from '../../../composables/forms';

const auth = injectOrFail<AuthClient>('auth');

const passwordForm = reactive(useCustomValidatedPostForm<ResetPassword<Password<AuthClient>>,
    ResetPassword<Password<AuthClient>>>(
        auth.passwords.new().resetPassword.new(),
        auth.passwords.new().resetPassword,
        {
            onSubmit() {
                passwordForm.model.$raw = auth.passwords.new().resetPassword.new().$raw;
            },
        },
    ));
</script>
