<template>
    <OtDialogModal
        ref="ticketAccessMomentsLinkModalRef"
        class="ticket-access-moments-link-modal"
        data-testid="access-moment-unlinked-ticket-modal"
        :width="540"
        @close="$emit('close')"
    >
        <OtCard
            v-if="ticketModel"
            class="ticket-access-moments-link-modal__card"
        >
            <OtCardHeader :title="$t('dashboard.access_moments.unlinked_tickets.modal.title')" />

            <OtCardContent>
                <i18n
                    tag="span"
                    path="dashboard.access_moments.unlinked_tickets.modal.description"
                    class="ticket-access-moments-link-modal__card__description"
                >
                    <template #ticket>
                        <span class="ticket-access-moments-link-modal__card__description__highlighted">
                            {{ ticketModel.$data.name }}
                        </span>
                    </template>
                </i18n>

                <OtFormRow>
                    <InputField
                        :label="$t('dashboard.access_moments.unlinked_tickets.modal.select.label')"
                        :description="$t('dashboard.access_moments.unlinked_tickets.modal.select.description')"
                    >
                        <!-- TODO: replace with generic LinkUnlinkPicker -->
                        <AccessMomentsPicker
                            :key="ticketModel.id"
                            ref="accessMomentsPickerRef"
                            :access-moments="accessMomentsList"
                            :ticket="ticketModel"
                            ticket-is-unlinked
                        />
                    </InputField>
                </OtFormRow>
            </OtCardContent>

            <OtCardFooter>
                <button
                    class="ot-button is-dark"
                    :title="$t('dashboard.common.action.cancel.title')"
                    @click="close"
                >
                    <OtIcon
                        type="close"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.common.action.cancel.text') }}
                </button>

                <button
                    class="ot-button"
                    :title="$t('dashboard.access_moments.unlinked_tickets.modal.link.title', { ticket: ticketModel.$data.name })"
                    data-testid="access-moment-unlinked-ticket-modal-submit"
                    @click="submit()"
                >
                    <OtIcon
                        type="check"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.access_moments.unlinked_tickets.modal.link.text') }}
                </button>
            </OtCardFooter>
        </OtCard>
    </OtDialogModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type {
    Event, EventDate, ManagementClient, Ticket,
} from '@openticket/lib-management';
import { OtDialogModal } from '@openticket/vue-dashboard-components';
import type { Pagination } from '@openticket/lib-crud';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import InputField from '../../../components/form/InputField.vue';
import { useGenericErrorHandling } from '../../../composables';
import AccessMomentsPicker from './AccessMomentsPicker.vue';

type Props = {
    accessMomentsList: Pagination<EventDate<Event<ManagementClient>>>;
}

defineProps<Props>();

type Emits = {
    (e: 'close'): void,
    (e: 'error', error: Error): void
    (e: 'submit', model: Ticket<ManagementClient>): void
}

const emit = defineEmits<Emits>();

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const { handleError } = useGenericErrorHandling();

if (!context.isEventContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const accessMomentsPickerRef = ref<InstanceType<typeof AccessMomentsPicker> | null>(null);
const ticketAccessMomentsLinkModalRef = ref<InstanceType<typeof OtDialogModal> | null>(null);
const ticketModel = ref<Ticket<ManagementClient> | null>(null);

function close() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    ticketAccessMomentsLinkModalRef.value?.close();

    ticketModel.value = null;
}

function show(model: Ticket<Event<ManagementClient>>) {
    if (!model) {
        return;
    }

    try {
        ticketModel.value = management.tickets.$factory(model.$raw);
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    ticketAccessMomentsLinkModalRef.value?.show();
}

async function submit() {
    if (!ticketModel.value) {
        return;
    }

    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        await accessMomentsPickerRef.value?.submit();

        emit('submit', ticketModel.value);
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }

    close();
}

defineExpose({
    show,
});
</script>

<style scoped lang="scss">
.ticket-access-moments-link-modal {
    overflow: visible;

    &__card {
        overflow: visible !important;

        &__description {
            &__highlighted {
                color: var(--ot-color-core-brand);
                font-weight: 500;
            }
        }

        &::v-deep {
            .card-content {
                display: flex;
                flex-direction: column;
                gap: var(--ot-spacing-unit);
                padding-bottom: var(--ot-spacing-unit);
            }

            .card-footer {
                border-radius: 0 0 var(--ot-input-radius) var(--ot-input-radius);
            }
        }
    }
}
</style>
