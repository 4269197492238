<template>
    <div class="module__auth__callback">
        <div>
            <div class="ot-spinner" />
            <h5>{{ $t('dashboard.common.state.loading') }}...</h5>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { AuthClient, AuthorizationState } from '@openticket/lib-auth';
import { send, Log } from '@openticket/lib-log';
import { useRoute, useRouter } from 'vue-router/composables';
import urlJoin from 'url-join';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import { InvalidStateKeyAuthError } from '@openticket/lib-auth';
import { injectOrFail } from '../../../services/util';
import type { RudderStack } from '../../../services/rudderstack';
import type { PluginsManager } from '../../../plugins';

const auth = injectOrFail<AuthClient>('auth');
const rudderstack = injectOrFail<RudderStack>('rudderstack');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const plugins = injectOrFail<PluginsManager>('plugins');

const router = useRouter();
const route = useRoute();

void (async () => {
    const { code, state } = route.query;

    if (!code || typeof code !== 'string') {
        await router.push({ name: 'error', query: { reason: 'Invalid authorization code.' } }); // TODO slug

        return;
    }

    if (!state || typeof state !== 'string') {
        await router.push({ name: 'error', query: { reason: 'Invalid authorization state.' } }); // TODO slug

        return;
    }

    try {
        const redirectUri: string = urlJoin(whitelabel.dashboard.url, 'auth', 'callback');

        const stateData: AuthorizationState = await auth.$token.authorize(code, state, redirectUri);

        try {
            const logContext: { [p: string]: string } = {
                authenticated: '',
                state_redirect: '',
            };

            const user = (await auth.$token.$info)?.user;

            try {
                logContext.authenticated = user?.guid || '';
                logContext.state_redirect = stateData.redirect;
            } catch (e) {
                logContext.fail = (e instanceof Error ? e.message : '');
            }

            rudderstack.identify(user?.guid, {
                ...user,
                current_company_context: '',
                wl_admin: (await auth.$token.isWhitelabelAdmin() ? 'true' : 'false'),
                company_ids: (await auth.$token.$info)?.companies.map((company) => company.guid).join(', '),
            });
            rudderstack.track('vue-dashboard auth login success');

            send({
                href: window.location.href,
                message: 'Dashboard: Auth authorization success',
                slug: 'dashboard.log.messages.auth.authorization.success',
                ts: Date.now(),
                getLogContext: (): { [p: string]: string } => logContext,
            }, Log.Info);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.debug('Failed to send authorization success log message', { e });
            // Fail silently
        }

        await router.push({ path: stateData.redirect });
    } catch (e) {
        // TODO Better error handling and logging??
        let reason = 'Failed to authorize user.';

        console.error(reason, e);

        if (e instanceof Error) {
            reason = e.message;
        }

        rudderstack.track('vue-dashboard auth login failed');

        try {
            send({
                cause: {
                    slug: (e instanceof Error ? e.name : ''),
                    message: (e instanceof Error ? e.message : ''),
                },
                href: window.location.href,
                message: 'Dashboard: Auth authorization failed',
                slug: 'dashboard.log.messages.auth.authorization.failed',
                ts: Date.now(),
                getLogContext: (): { [p: string]: string } => ({ authenticated: '', reason }),
            }, Log.Fatal);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.debug('Failed to send authorization failed log message', { e: err });
            // Fail silently
        }

        // A non-existing state key in user's storage will fail dramatically,
        // while the user needs to be redirected to the login page.
        if (e instanceof InvalidStateKeyAuthError) {
            await plugins.auth.logout();
            return;
        }

        await router.push({ name: 'error', query: { reason } });
    }
})();
</script>

<style lang="scss" scoped>
.module__auth__callback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .ot-spinner::after {
        position: relative;
    }

    h5 {
        margin-top: 1rem;
    }
}
</style>
