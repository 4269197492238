<template>
    <div class="tfa-totp-confirm">
        <div>
            <OtInputField
                :label="$t('dashboard.user.tfa.setup.password')"
                :error="$t(error.incorrectPassword, { attribute: $t('dashboard.user.tfa.setup.attributes.password') })"
            >
                <OtInput
                    :label="$t('dashboard.user.tfa.setup.password')"
                    type="password"
                    name="password"
                    :invalid="!!error.incorrectPassword"
                    :value="value.password"
                    @input="setPassword"
                />
            </OtInputField>

            <OtInputField
                :label="$t('dashboard.user.tfa.setup.totp.code')"
                :error="$t(error.incorrectTFAInput, { attribute: $t('dashboard.user.tfa.setup.attributes.totp_code') })"
            >
                <OtInputDigitGroup
                    placeholder="0"
                    :blur-on-complete="false"
                    :initial-focus="-1"
                    :invalid="!!error.incorrectTFAInput"
                    @complete="setInput"
                />
            </OtInputField>
        </div>

        <div class="explanation-label">
            {{ $t('dashboard.user.tfa.setup.totp.explanation') }}
        </div>
    </div>
</template>

<script setup lang="ts">
import type { TFAError } from '@openticket/vue-tfa-confirmation';
import type { TfaTotpData } from '../types';

type Props = {
    value: TfaTotpData,
    error: TFAError
}

type Emits = {
    (e: 'input', value: TfaTotpData): void
}

const props = withDefaults(
    defineProps<Props>(),
    {
        error: () => ({ incorrectPassword: null, incorrectTFAInput: null }),
    },
);
const emit = defineEmits<Emits>();

const setPassword = (val: string): void => {
    emit('input', {
        ...props.value,
        password: val,
    });
};

const setInput = (val: number[] | null): void => {
    emit('input', {
        ...props.value,
        input: val ? val.map((n: number) => n.toFixed(0)).join('') : null,
    });
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/mixins.scss";

.tfa-totp-confirm {
    .explanation-label {
        font-size: 0.8rem;
        color: var(--ot-color-core-light-accent-primary);
        text-align: center;
        margin-bottom: var(--ot-spacing-3xl);
        @include breakpoint(mob) {
            margin-bottom: 0;
        }
    }
}

::v-deep {
    & .ot-input-code {
        @include breakpoint(mob) {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
