<template>
    <div class="modules__shops__shop-url">
        <OtIcon
            class="ot-clickable modules__shops__shop-url__copy"
            type="copy"
            role="button"
            tabindex="0"
            :aria-label="$t('dashboard.shops.list.link.copy')"
            @click.native.prevent.stop="copyShopUrl(record.id)"
            @keydown.space="copyShopUrl(record.id)"
            @keydown.enter="copyShopUrl(record.id)"
        />

        <a
            class="ot-link modules__shops__shop-url__link"
            target="_blank"
            :href="generateUrl(record.id)"
            :title="record.$data.name"
            :aria-label="$t('dashboard.shops.list.link.label', { shop: record.$data.name })"
            @click.stop
        >
            {{ generateUrl(record.id) }}
        </a>
    </div>
</template>

<script lang="ts" setup>
import { isOtError, send } from '@openticket/lib-log';
import type {
    Event,
    CompanyPath,
    ManagementClient,
    Shop,
} from '@openticket/lib-management';
import type VueNotifications from '@openticket/vue-notifications';
import type { Whitelabel } from '@openticket/lib-whitelabels';
import urlJoin from 'url-join';
import { FailedCopyShopUrlError } from '../services/failed.copy.shop.url.error';
import { injectOrFail } from '../../../services/util';
import { useClipboard, useLocalization } from '../../../composables';

type Props = {
    record: Shop<ManagementClient | CompanyPath<ManagementClient> | Event<ManagementClient>>
}

const props = defineProps<Props>();
const notifications = injectOrFail<VueNotifications>('notifications');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');

const { t } = useLocalization();

function generateUrl(shop: string | null): string | null {
    if (!shop) {
        return null;
    }

    return urlJoin(whitelabel.shop.url, shop);
}

async function writeShopUrlToClipboard(shop: string | null): Promise<void> {
    if (!shop) {
        throw new FailedCopyShopUrlError('', '');
    }

    const url: string | null = generateUrl(shop);

    if (!url) {
        throw new FailedCopyShopUrlError(shop, '');
    }

    try {
        const { clipboard } = useClipboard();
        await clipboard.writeText(url);
    } catch (e) {
        if (e instanceof Error) {
            throw new FailedCopyShopUrlError(shop, url, e);
        }
    }
}

async function copyShopUrl(shop: string | null): Promise<void> {
    try {
        await writeShopUrlToClipboard(shop);

        notifications.info(t('dashboard.shops.list.link.copied', {
            shop: props.record.$data.name,
        }));
    } catch (e) {
        if (isOtError(e)) {
            send(e);
            notifications.warning(t(e.slug, e.getLogContext()));
        }
    }
}

</script>

<style lang="scss" scoped>
.modules__shops__shop-url {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__copy {
        flex-shrink: 0;
    }

    &__link {
        flex-grow: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        color: var(--ot-color-core-brand);
    }
}
</style>
