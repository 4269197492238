import type VueRouter from 'vue-router';
import type { Route } from 'vue-router';
import type { Context } from '../services/context';
import type { ContextPlugin } from './context.plugin';
import type { LocalizationPlugin } from './localization.plugin';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { RouterPlugin } from './router.plugin';
import type { WhitelabelPlugin } from './whitelabel.plugin';

interface Translator {
    (slug: string, values?: { [key: string]: string }): string | object;
}

interface PartCache {
    dashboard: string;
    lastRoute: Route | null;
    whitelabel: string | null;
}

/**
 * The document title plugin registers listeners for the dynamic update of the document title.
 *
 * It is build up out of multiple parts.
 *
 * From right to left (last to first):
 *
 * The document title always ends with the general document title, i.e. 'dashboard'.
 * In its most basic form, it is a hard coded fallback (i.e. 'Dashboard'), but it can contain some dynamic values.
 * When localization is loaded (and the - hardcoded - slug is translatable), it will be translated.
 * When the whitelabel config is loaded, the whitelabel name is added as a value to the translation.
 *
 * Before this - in reverse hierarchical order (most specific first) - are any route records that contain
 * a `title` or `titleFallback` property. Any others are ignored.
 *
 * The `title` property should be a translatable slug. If it is missing, or not translatable, the `titleFallback` is used.
 * If neither are present, the route record is ignored.
 *
 * Any loaded component can push key value pairs to the document title plugin. These values are linked to the first component's
 * matched route that matches with the current route's record chain. If no match is found, the pair is ignored.
 * Any key can only exist once. Any later key will overwrite previous ones.
 *
 * When the current route changes, these tuples are cleaned up and any pairs not belonging to a route in the current record
 * chain will be purged.
 */
export class DocumentTitlePlugin extends Plugin<DocumentTitlePlugin> {

    private context: Context | null = null;

    private translator: null | Translator = null;

    readonly partCache: PartCache = {
        dashboard: 'Dashboard',
        lastRoute: null,
        whitelabel: null,
    };

    async install(plugins: PluginsManager): Promise<void> {
        try {
            // Set early temporary title.
            // (This will only contain the most basic fallback).
            this.update();

            // Every individual initialization step will trigger its own update after it is done.
            await Promise.all([
                this.onRouterLoaded(plugins.router),
                this.onLocalizationLoaded(plugins.localization),
                this.onWhitelabelLoaded(plugins.whitelabel),
                this.onContextLoaded(plugins.context),
            ]);

            this.resolve(this);
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());
            }

            this.reject(e);
        }
    }

    update(): void {
        try {
            // Remove any values which do not match any of the matched routes for the destination of the page transition.
            const { dashboard, lastRoute } = this.partCache;
            const parts: string[] = [ dashboard ];

            if (this.translator && lastRoute && this.context) {
                const { values } = this.context;

                for (const record of lastRoute.matched) {
                    const value: string = this.translate(record.meta.title || '', values, record.meta.titleFallback || '');

                    if (value) {
                        parts.unshift(value);
                    }
                }
            }

            window.document.title = parts.join(' | ');
        } catch (e: unknown) {
            // Setting the document title should never break execution anywhere!
            console.warn('Failed to set document title', e);

            window.document.title = this.partCache.dashboard;
        }
    }

    private async onRouterLoaded(plugin: RouterPlugin): Promise<void> {
        try {
            const router: VueRouter = await plugin.loading;

            this.partCache.lastRoute = router.currentRoute;

            router.afterEach((to: Route) => {
                try {
                    this.partCache.lastRoute = to;

                    this.update();
                } catch (e: unknown) {
                    // Silent failure
                    this.partCache.lastRoute = null;
                }
            });

            this.update();
        } catch (e: unknown) {
            // Setting the document title should never break execution anywhere!
            this.partCache.lastRoute = null;
        }
    }

    private async onLocalizationLoaded(plugin: LocalizationPlugin): Promise<void> {
        try {
            const localization = await plugin.loading;
            const { i18n } = plugin;

            this.translator = (
                key: string,
                values?: unknown[] | { [key: string]: string },
            ) => i18n.t(key, values);

            this.partCache.dashboard = this.translateDashboardPart();

            localization.on('locale-change', () => {
                try {
                    this.partCache.dashboard = this.translateDashboardPart();

                    this.update();
                } catch (e: unknown) {
                    this.partCache.dashboard = 'Dashboard';
                }
            });

            this.update();
        } catch (e: unknown) {
            // Setting the document title should never break execution anywhere!

            this.partCache.dashboard = 'Dashboard';
        }
    }

    private async onWhitelabelLoaded(plugin: WhitelabelPlugin): Promise<void> {
        try {
            this.partCache.whitelabel = (await plugin.loading).name;

            this.partCache.dashboard = this.translateDashboardPart();

            this.update();
        } catch (e: unknown) {
            // Setting the document title should never break execution anywhere!

            this.partCache.whitelabel = null;
        }
    }

    private async onContextLoaded(plugin: ContextPlugin): Promise<void> {
        try {
            this.context = await plugin.loading;

            // TODO Listen to value updates???

            this.update();
        } catch (e: unknown) {
            // No-op - Setting the document title should never break execution anywhere!
        }
    }

    private translate(slug: string, values: undefined | { [key: string]: string }, fallback: string): string {
        try {
            if (slug && this.translator) {
                const translation = this.translator(slug, values);

                if (translation && typeof translation === 'string' && translation !== slug) {
                    return translation;
                }
            }
        } catch (e: unknown) { /* Silent fail */ }

        return fallback;
    }

    private translateDashboardPart(): string {
        const values: { [key: string]: string } = {
            whitelabel: this.partCache.whitelabel || '',
        };

        return this.translate('dashboard.document_title.main', values, 'Dashboard').trim();
    }

}
