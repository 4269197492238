<template>
    <div
        v-if="roles.isWhitelabelAdmin"
        class="admin-actions-columns"
    >
        <ErrorView
            v-if="error"
            :label="error.message"
        />
        <div class="admin-actions-columns__wrapper">
            <div class="admin-actions-columns__column">
                <slot name="left" />
            </div>
            <div class="admin-actions-columns__column">
                <slot name="right" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import { injectOrFail } from '../../services/util';
import type { Roles } from '../../plugins/types';
import ErrorView from '../ErrorView.vue';
import { useGenericErrorHandling } from '../../composables';

const { error } = useGenericErrorHandling();

const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins.scss";

.admin-actions-columns {
    max-width: 1600px;

    button {
        font-weight: 600;
        font-size: 0.875rem;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        @include breakpoint(tab) {
            grid-template-columns: 1fr;
        }
    }

    &__column {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }
}
</style>
