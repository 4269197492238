<template>
    <div
        class="debug-overlay"
        :class="{ 'is-left': preferences.isLeft }"
    >
        <div
            class="debug-overlay__move-content"
            role="switch"
            tabindex="0"
            :aria-checked="preferences.isLeft"
            @click="toggleLocation"
            @keydown.space="toggleLocation"
            @keydown.enter="toggleLocation"
        >
            <OtIcon :type="preferences.isLeft ? 'arrow-right' : 'arrow-left'" />
        </div>

        <div class="debug-overlay__content">
            <h1>DEBUGGER</h1>

            <DebugApp
                :opened="preferences.keptOpen.app"
                :is-loading="isLoading"
                :components-registered="componentsRegistered"
                @keep-open="keepOpen('app')"
            />

            <DebugPlugins
                :opened="preferences.keptOpen.plugins"
                @keep-open="keepOpen('plugins')"
            />

            <h1>TODO: Finish ERROR VIEW.</h1>

            <hr>

            <h1>TODO: documentTitle.setValue</h1>
            <small>on all individual views AND relevant components??</small>

            <hr>

            <div
                class="debug-overlay__close"
                role="button"
                tabindex="0"
                @click="close"
                @keydown.space="close"
                @keydown.enter="close"
            >
                <OtIcon type="close" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router/composables';
import DebugApp from './parts/app/DebugApp.vue';
import DebugPlugins from './parts/plugins/DebugPlugins.vue';

interface KeptOpen {
    plugins: boolean;
    app: boolean;
}

interface Preferences {
    isLeft: boolean;
    keptOpen: KeptOpen;
}

const preferences = ref<Preferences>({
    isLeft: false,
    keptOpen: {
        app: false,
        plugins: false,
    },
});

type Props = {
    isLoading: boolean;
    componentsRegistered: boolean;
};
defineProps<Props>();

type Emits = {
    (e: 'close'): void
}
const emit = defineEmits<Emits>();

loadPreferences();

onBeforeRouteUpdate((_, __, next) => {
    next();

    loadPreferences();
});

function loadPreferences() {
    try {
        const fromStorage: string = window.localStorage.getItem('ot-dashboard-debugging-preferences') || '{}';
        const persisted = JSON.parse(fromStorage) as Preferences;

        preferences.value.isLeft = !!persisted.isLeft;

        preferences.value.keptOpen = {
            ...preferences.value.keptOpen,
            ...persisted.keptOpen,
        };
    } catch (e) { /* No-op */ }
}

function close() {
    emit('close');
}

function savePreferences() {
    try {
        const toStorage: Preferences = {
            isLeft: preferences.value.isLeft,
            keptOpen: preferences.value.keptOpen,
        };

        window.localStorage.setItem('ot-dashboard-debugging-preferences', JSON.stringify(toStorage));
    } catch (e) { /* No-op */ }
}

function toggleLocation(): void {
    preferences.value.isLeft = !preferences.value.isLeft;

    savePreferences();
}

function keepOpen(key: keyof KeptOpen): void {
    preferences.value.keptOpen[key] = !preferences.value.keptOpen[key];

    savePreferences();
}

</script>

<style lang="scss" scoped>
.debug-overlay {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-items: stretch;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    z-index: 99996;
    pointer-events: none;

    &__content > :not(:last-child) {
        margin-bottom: 1rem;
    }

    &__content {
        position: fixed;
        display: flex;
        flex-direction: column;

        top: 0;
        bottom: 0;
        right: 0;

        width: 500px;
        max-width: 65%;

        background: rgba(0, 106, 255, .75);
        backdrop-filter: blur(1px);
        box-shadow: rgb(0 0 0 / 30%) 0 0 20px;
        color: #FFFFFF;
        padding: 1.5rem;

        overflow-y: auto;
        z-index: 99996;
        pointer-events: auto;

        & > h1 {
            text-align: center;
            margin-bottom: 1rem;
        }

        &::v-deep {
            .ot-card > header, .ot-card > .debug__header {
                display: flex;

                & > .hspace {
                    flex-grow: 1;
                }

                & > .oti-drop-down, & > .oti-drop-up {
                    margin-left: 1.5rem;
                }

                & .oti-warning, & .oti-checkmark {
                    margin-right: .5rem;
                }

                & .oti-warning {
                    color: var(--ot-color-accent-orange-dark, #FF5500)
                }

                & .oti-checkmark {
                    color: var(--ot-color-accent-green-dark, #00C805)
                }
            }

            code {
                display: inline-flex;
                padding: 0 24px;
                background: var(--ot-color-core-brand, #006AFF);
                color: var(--ot-color-core-brand-invert, #FFFFFF);
                white-space: pre-wrap;

                &.is-failure {
                    background: var(--ot-color-accent-orange-dark, #FF5500);
                }

                &.full-width {
                    width: 100%;
                }
            }
        }
    }

    &__move-content, &__close {
        position: absolute;
        top: 1.375rem;
        color: var(--ot-color-core-brand-invert, #FFFFFF);
        z-index: 99997;
        pointer-events: auto;
        cursor: pointer;
    }

    &__move-content {
        right: 1.5rem;
    }

    &__close {
        left: 1.5rem;
    }

    &:not(.is-left) > &__move-content:hover {
        color: var(--ot-color-core-forground-primary, #000000);
    }

    .is-left > &__move-content {
        right: unset;
        left: 1.5rem;
    }

    .is-left > &__content > &__close {
        right: 1.5rem;
        left: unset;
    }

    &__move-content:hover ~ &__content,
    .is-left > &__content {
        right: unset;
        left: 0;
    }
}
</style>
