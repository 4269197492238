import { mapFilters } from '@openticket/lib-crud';
import type{
    FilterTypes,
    Listable,
    Model,
    ModelConfig,
    Pagination,
    PaginationFilters,
    PaginationOptions,
    Parent,
    RulesMap,
    ToMany,
} from '@openticket/lib-crud';
import { onUnmounted, ref, type Ref } from 'vue';
import { useRouter } from 'vue-router/composables';

// TODO: Fix types https://github.com/Microsoft/TypeScript/issues/1213
export function useRouterPagination<
    M extends Model<P, C, R>,
    C extends ModelConfig,
    P extends Parent,
    R extends RulesMap = {},
    PC extends ModelConfig = {},
>(
    _relation: ToMany<M, C, P, R, PC> & Listable<M, C, P, R, PC>,
    initialPerPageOrOptions: number | PaginationOptions = {},
): {
    list: Ref<Pagination<M>>,
    setPagination: (relation: ToMany<M, C, P, R, PC> & Listable<M, C, P, R, PC>) => void,
} {
    const router = useRouter();

    const list = ref<Pagination<M>>() as Ref<Pagination<M>>;
    const removeFilterAppliedHook = ref<(() => void) | null>(null);
    const initialPath = router.currentRoute.path;

    list.value = createPagination(_relation);
    removeFilterAppliedHook.value = list.value.onFilterApplied(updateUrl);

    onUnmounted(() => {
        removeFilterAppliedHook.value?.();
    });

    function setPagination(relation: ToMany<M, C, P, R, PC> & Listable<M, C, P, R, PC>) {
        removeFilterAppliedHook.value?.();
        list.value = createPagination(relation);
        removeFilterAppliedHook.value = list.value.onFilterApplied(updateUrl);
    }

    function createPagination(relation: ToMany<M, C, P, R, PC> & Listable<M, C, P, R, PC>): Pagination<M> {
        let options: PaginationOptions = {};

        if (typeof initialPerPageOrOptions === 'number') {
            options.perPage = initialPerPageOrOptions;
        } else {
            options = initialPerPageOrOptions;
        }

        const urlFilters: PaginationFilters = {};
        const { query } = router.currentRoute;

        // Get the filter queries from the url
        for (const [ key, value ] of Object.entries(query)) {
            if (!isFilterQuery(key)) {
                continue;
            }

            const split = key.split(/\[(.*?)\]/); // Split to get target between '[' and ']';

            const filterTypes: FilterTypes[] = [ 'contains', 'ends_with', 'equals', 'max', 'min', 'scope', 'starts_with' ];

            for (const filterType of filterTypes) {
                if (split[1].endsWith(filterType)) {
                    const attribute = split[1].slice(0, split[1].length - filterType.length - 1);
                    if (!urlFilters[attribute]) {
                        urlFilters[attribute] = {};
                    }
                    urlFilters[attribute][filterType] = value.toString();
                    break;
                }
            }
        }

        if (list.value) {
            options.defaultFilters = list.value.filters.active;
        } else if (Object.keys(urlFilters).length) {
            options.defaultFilters = urlFilters;
        } else {
            void updateUrl(options.defaultFilters);
        }

        return relation.list(options);
    }

    async function updateUrl(filters?: PaginationFilters): Promise<void> {
        if (!filters || !isInitialPath()) {
            return;
        }

        const { query } = router.currentRoute;
        const newQuery: Record<string, string | (string | null)[] | undefined> = structuredClone(query);

        const mapped = mapFilters(filters);

        for (const key of Object.keys(newQuery)) {
            if (isFilterQuery(key)) {
                delete newQuery[key];
            }
        }

        for (const key of Object.keys(mapped)) {
            if (isFilterQuery(key)) {
                newQuery[key] = mapped[key];
            }
        }

        if (Object.entries(query).toString() !== Object.entries(newQuery).toString()) {
            await router.replace({ params: router.currentRoute.params, query: newQuery });
        }
    }

    // Check if query key follows 'filter[*]'
    function isFilterQuery(queryKey: string): boolean {
        return /filter\[(.*?)\]/.test(queryKey);
    }

    function isInitialPath(): boolean {
        const trimSlashes = /^\/+|\/+$/g;
        return initialPath.replace(trimSlashes, '') === router.currentRoute.path.replace(trimSlashes, '');
    }

    return {
        list,
        setPagination,
    };
}
