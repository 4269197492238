<template>
    <OtDropdown
        v-if="roles.isWhitelabelAdmin"
        ref="dropdownRef"
        content-alignment="right"
        class="order-admin-actions"
    >
        <template #trigger>
            <button
                class="ot-button is-outline is-small is-success"
            >
                {{ $t('dashboard.orders.admin_actions.label') }}

                <OtIcon
                    type="meatballs-menu"
                    size="small"
                    class="ot-button-icon--right"
                />
            </button>
        </template>

        <template #content>
            <div class="order-admin-actions__content">
                <ul>
                    <li
                        v-for="[key, action] in Object.entries(actions)"
                        :key="key"
                    >
                        <button
                            class="order-admin-actions__content__action"
                            :disabled="action.isLoading"
                            @click="performAdminAction(action)"
                        >
                            <div class="order-admin-actions__content__action__icon">
                                <OtSpinner v-if="action.isLoading" />

                                <OtIcon
                                    v-else
                                    type="lock"
                                    size="small"
                                />
                            </div>

                            <span class="ot-text-body-strong">{{ action.label }}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </template>
    </OtDropdown>
</template>

<script setup lang="ts">
import type { OrderExtended, ManagementClient } from '@openticket/lib-management';
import { OtDropdown } from '@openticket/vue-dashboard-components';
import { reactive, ref, type UnwrapNestedRefs } from 'vue';
import type VueNotifications from '@openticket/vue-notifications';
import { injectOrFail } from '../../../services/util';
import type { Roles } from '../../../plugins/types';
import type { AdminActions } from '../../../services/admin-actions/adminActions.service';
import { useGenericErrorHandling, useLocalization } from '../../../composables';

type AdminAction = {
    cb: () => void | Promise<void>;
    label: string;
    isLoading: boolean;
    successLabel: string;
}

type Props = {
    order: OrderExtended<ManagementClient>,
}

const props = defineProps<Props>();

const adminActions = injectOrFail<AdminActions>('adminActions');
const notifications = injectOrFail<VueNotifications>('notifications');
const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

const { handleError } = useGenericErrorHandling();
const { t } = useLocalization();

const actions = reactive<Record<string, AdminAction>>({
    rerenderTickets: {
        cb: async () => {
            if (!props.order.id) {
                return;
            }
            await adminActions.rerenderOrderTickets(props.order.id);
        },
        label: t('dashboard.orders.admin_actions.rerender_tickets.label'),
        isLoading: false,
        successLabel: t('dashboard.orders.admin_actions.rerender_tickets.success'),
    },
    resendConfirmationEmail: {
        cb: async () => {
            if (!props.order.id) {
                return;
            }
            await adminActions.resendOrderConfirmationEmail(props.order.id);
        },
        label: t('dashboard.orders.admin_actions.resend_confirmation_email.label'),
        isLoading: false,
        successLabel: t('dashboard.orders.admin_actions.resend_confirmation_email.success'),
    },
});

const dropdownRef = ref<InstanceType<typeof OtDropdown> | null>(null);

function close(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dropdownRef.value?.close();
}

async function performAdminAction(action: AdminAction): Promise<void> {
    action.isLoading = true;

    try {
        await action.cb();

        notifications.success(action.successLabel);
    } catch (e) {
        handleError(e);
    }

    close();
    action.isLoading = false;
}
</script>

<style scoped lang="scss">
.order-admin-actions {
    // TODO Remove when switching to vue-ui
    ::v-deep .ot-card {
        border-radius: var(--ot-spacing-sm);
        overflow: hidden;
        margin-top: var(--ot-spacing-3xs);
    }

    &__content {
        min-width: 15rem;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li:not(:last-child) {
                border-bottom: 1px solid var(--ot-color-core-accent-secondary);
            }
        }

        &__action {
            all: unset;
            width: 100%;
            display: flex;
            align-items: center;
            gap: var(--ot-spacing-sm);
            padding: var(--ot-spacing-unit) var(--ot-spacing-lg);
            box-sizing: border-box;
            cursor: pointer;
            text-wrap: nowrap;
            user-select: none;

            h5 {
                margin: 0;
                flex-grow: 1;
            }

            .spinner {
                padding: 0;
            }

            &__icon {
                width: 1rem;
                display: flex;
                align-items: center;
                color: var(--ot-color-accent-green-dark);
            }

            &:hover {
                background-color: var(--ot-color-core-background-secondary);
            }

            &:active:not(:disabled) {
                opacity: 1;
                color: var(--ot-color-core-brand);
                background-color: var(--ot-color-core-light-brand-faded);
            }
        }
    }
}
</style>
