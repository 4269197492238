<template>
    <div class="module__events__list-record">
        <div class="module__events__list-record__left">
            <h3>{{ record.$data.name }}</h3>

            <a
                v-if="company"
                class="module__events__list-record__left__company-name ot-link ot-clickable"
                role="button"
                tabindex="0"
                @click.stop="goToCompaniesHome(record.$data.company_id)"
                @keydown.space="goToCompaniesHome(record.$data.company_id)"
                @keydown.enter="goToCompaniesHome(record.$data.company_id)"
            >{{ company.name }}</a>

            <div
                v-if="record.$data.start && record.$data.end"
                class="module__events__list-record__left__date"
            >
                <OtIcon type="calendar" />
                {{ $l.dateTimeRangeCollapseLong(record.$data.start, record.$data.end) }}
            </div>
        </div>

        <div class="module__events__list-record__right">
            <div
                v-if="daysUntilStartEvent && daysUntilStartEvent > 0"
                class="module__events__list-record__right__diff"
            >
                <div
                    class="ot-text-intro-strong"
                >
                    {{ $t('dashboard.common.time.diff.days', { days: daysUntilStartEvent }) }}
                </div>
                <div
                    class="module__events__list-record__right__diff__label ot-text-body-medium"
                >
                    {{ $t('dashboard.events.list.diff.until') }}
                </div>
            </div>

            <div class="module__events__list-record__right__actions">
                <a
                    class="ot-button is-outline"
                    :title="$t('dashboard.events.list.view.title', { event: record.$data.name })"
                    role="button"
                    tabindex="0"
                    @click.stop="goToEventsHome(record.$data.guid)"
                    @keydown.space="goToEventsHome(record.$data.guid)"
                    @keydown.enter="goToEventsHome(record.$data.guid)"
                >
                    {{ $t('dashboard.events.list.view.text') }}
                    <OtIcon
                        type="carrot-right"
                        class="ot-button-icon--right"
                    />
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router/composables';
import type VueRouter from 'vue-router';
import type { Event, ManagementClient } from '@openticket/lib-management';
import { computed, ref } from 'vue';
import type { AuthClient, Company } from '@openticket/lib-auth';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';

interface Props {
  record: Event<ManagementClient>;
}

const props = defineProps<Props>();

const router: VueRouter = useRouter();
const companies = ref<Company<AuthClient>[] | null>(null);

const auth = injectOrFail<AuthClient>('auth');
const context = injectOrFail<Context>('context');

void (async () => {
    companies.value = (await auth.$token.$info)?.companies;
})();

async function goToCompaniesHome(companyId: string) {
    await context.updateContext('company', { company: companyId });
}

async function goToEventsHome(eventId: string) {
    await router.push({
        name: 'events.home',
        params: { event: eventId },
    });
}

const daysUntilStartEvent = computed<number | null>(() => {
    if (!props.record?.$data?.start) {
        return null;
    }

    // Use the browser's current datetime and the datetime of the
    // start of the event. Timezone is included in the start attribute
    // of the record, so this is automatically covered.
    const today = new Date();
    const eventDate = new Date(props.record.$data.start);
    const secondsUntil = (eventDate - today) / 1000;

    // One day contains 60 * 60 * 24 seconds. No need to take care of
    // leap days. Use floor to round down.
    return Math.floor(secondsUntil / (60 * 60 * 24));
});

const company = computed<Company<AuthClient> | null>(() => {
    if (!props.record?.$data?.company_id) {
        return null;
    }

    return (companies.value || []).find((singeCompany) => singeCompany.guid === props.record.$data.company_id);
});
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/mixins.scss';

.module__events__list-record {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @include breakpoint(mob) {
    flex-direction: column;
    gap: var(--ot-spacing-2xl);
  }

  &__left {
    &__company-name, &__date {
      /* TODO: Missing in design system: Body/Large Strong */
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.375rem; /* 137.5% */
    }

    &__company-name::after {
      display: none;
    }

    &__date .oti {
      width: 1em;
      height: 1em;
      vertical-align: middle;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    justify-content: stretch;

    @include breakpoint(mob) {
      justify-content: right;
    }

    & > * {
      display: flex;
      align-items: center;
    }

    &__diff {
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      padding-right: 1rem;
      margin-right: 1rem;
      border-right: solid 1px var(--ot-color-core-accent-tertiary);

      &__label {
        color: var(--ot-color-core-foreground-secondary);
        white-space: nowrap;
      }

      .ot-text-body-medium {
        /* TODO: Missing in design system: Body/Medium */
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem; /* 128.571% */
      }
    }
  }
}
</style>
