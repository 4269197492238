<template>
    <div class="events-details-form">
        <OtFormRow>
            <InputField
                data-testid="events-detailsform-name"
                :label="$t('dashboard.events.edit.details.form.event_name.label')"
                :description="$t('dashboard.events.edit.details.form.event_name.description')"
                :required="form.rules.name && form.rules.name.includes('required')"
                :optional="form.rules.name && form.rules.name.includes('optional')"
                :error="form.errors.name"
            >
                <OtInput
                    v-model="form.model.$data.name"
                    class="input"
                    type="text"
                    @input="form.errors.name = []"
                />
            </InputField>
        </OtFormRow>

        <OtFormRow>
            <InputField
                data-testid="events-detailsform-location"
                :label="$t('dashboard.events.edit.details.form.location.label')"
                :description="$t('dashboard.events.edit.details.form.location.description')"
                :required="true"
                :error="form.errors.location"
            >
                <LocationsInput
                    v-model="form.model.$data.location_id"
                    @input="form.errors.location = []"
                />
            </InputField>

            <InputField
                data-testid="events-detailsform-currency"
                :label="$t('dashboard.events.edit.details.form.currency.label')"
                :description="$t('dashboard.events.edit.details.form.currency.description')"
                :required="form.rules.currency && form.rules.currency.includes('required')"
                :optional="form.rules.currency && form.rules.currency.includes('optional')"
                :error="form.errors.currency"
            >
                <OtInputSelect
                    v-model="form.model.$data.currency"
                    class="input"
                    sort="alphabetically"
                    :options="currencies"
                    :searchable="true"
                    :internal-search="true"
                    @input="form.errors.currency = []"
                />
            </InputField>
        </OtFormRow>

        <!-- TODO: Restore this when sealed status is included in event model -->
        <!-- <OtFormRow>
            <InputField
                :label="$t('dashboard.events.edit.details.form.sealed.label')"
                :description="$t('dashboard.events.edit.details.form.sealed.description')"
            >
                <OtInputToggle
                    class="input"
                    :label="$t('dashboard.events.edit.details.form.sealed.toggle_text')"
                    v-model="dummy.sealed"
                />
            </InputField>
        </OtFormRow> -->

        <OtFormRow>
            <InputField
                data-testid="events-detailsform-category"
                :label="$t('models.event.attributes.category.label')"
                :description="$t('dashboard.events.edit.details.form.category.description')"
                :required="form.rules.category?.includes('required')"
                :optional="!form.rules.category?.includes('required')"
                :error="form.errors.category"
            >
                <OtInputSelect
                    v-model="form.model.$data.category"
                    class="input"
                    :options="categories"
                    @input="form.errors.category = []"
                />
            </InputField>

            <InputField
                data-testid="events-detailsform-subcategories"
                :label="$t('models.event.attributes.subcategories.label')"
                :description="$t('dashboard.events.edit.details.form.subcategories.description')"
                :required="form.rules.subcategories?.includes('required')"
                :optional="!form.rules.subcategories?.includes('required')"
                :error="form.errors.subcategories"
            >
                <OtInputSelect
                    v-model="form.model.$data.subcategories"
                    class="input"
                    :options="subcategories"
                    multiple
                    @input="form.errors.subcategories = []"
                />
            </InputField>
        </OtFormRow>

        <div v-if="flags['app__event_settings__details__cancel_reschedule']">
            <div
                class="ot-separator ot-my[xl]"
            />
            <OtFormRow>
                <CancelOrScheduleEventRow />
            </OtFormRow>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { UnwrapNestedRefs } from 'vue';
import { computed, watch } from 'vue';
import type { ManagementClient, Event } from '@openticket/lib-management';
import InputField from '../../../../components/form/InputField.vue';
import LocationsInput from '../../../../components/form/LocationsInput.vue';
import { injectOrFail } from '../../../../services/util';
import type { ModelFormComposableResult } from '../../../../composables/forms';
import { CATEGORIES } from '../../constants';
import CancelOrScheduleEventRow from '../CancelOrScheduleEventRow.vue';
import type { FeatureFlagPlugin } from '../../../../plugins/featureFlag.plugin';
import { useLocalization } from '../../../../composables';

type Props = {
    form: UnwrapNestedRefs<ModelFormComposableResult<Event<ManagementClient>, Event<ManagementClient>>>
}

const props = defineProps<Props>();

const { flags } = injectOrFail<FeatureFlagPlugin>('featureFlags');

const { localization, t } = useLocalization();

const OTHER_KEY = 'other' as const;

const currencies = computed<Record<string, string>>(() => {
    const map: Record<string, string> = {};
    const { supportedCurrencies } = localization;

    if (supportedCurrencies) {
        for (const key of Object.keys(supportedCurrencies)) {
            map[key] = `${supportedCurrencies[key].currencySymbol} - ${supportedCurrencies[key].currencyDisplayName}`;
        }
    }

    return map;
});

const categories = computed<Record<string, string>>(() => createOptions('category'));
const subcategories = computed<Record<string, string>>(() => createOptions('subcategories'));

// Update selected categories to only valid ones when the category changes
watch(() => props.form.model.$data.category, (category) => {
    const currentSubcategories = props.form.model.$data.subcategories;

    if (!currentSubcategories || !category || !(category in CATEGORIES)) {
        return;
    }

    props.form.model.$data.subcategories = currentSubcategories
        .filter((item) => [ ...CATEGORIES[category], OTHER_KEY ].includes(item));
});

function createOptions(type: 'category' | 'subcategories'): Record<string, string> {
    let data: string[] = [];

    if (type === 'category') {
        data = Object.keys(CATEGORIES);
    } else if (type === 'subcategories') {
        const { category } = props.form.model.$data;

        if (category && category in CATEGORIES) {
            data = CATEGORIES[category];
        }
    }

    const map: Record<string, string> = data
        .sort((a, b) => (a > b ? 1 : -1))
        .reduce((accumulator, value) => ({
            ...accumulator,
            [value]: t(`models.event.attributes.${type}.enum.${value}`),
        }), {});

    // Add `other` as last key so it will appear at the bottom
    map[OTHER_KEY] = t(`models.event.attributes.${type}.enum.other`);

    return map;
}
</script>
