import type {
    Model,
    ModelConfig,
    Parent,
    RulesMap,
} from '@openticket/lib-crud';
import {
    hasMixin, isValidateError, Update, RootUpdate,
} from '@openticket/lib-crud';
import type { Ref, WatchStopHandle } from 'vue';
import { computed, ref, watch } from 'vue';
import type { NavigationGuardNext, Route } from 'vue-router';
import type { DialogController } from '@openticket/vue-dashboard-components';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router/composables';
import { isOtError } from '@openticket/lib-log';
import type VueNotifications from '@openticket/vue-notifications';
import type { NotificationConfig } from '@openticket/vue-notifications';
import { injectOrFail } from '../../services/util';
import type {
    FormErrors, ModelFormConfig, ModelFormMixin, Relation, Submission,
} from './types';
import { useSubmission } from './useSubmission';
import { FormMixinMissingError } from './errors';
import { useLocalization } from '../useLocalization';

export interface ModelFormComposableResult<
    M extends Model<P, ModelConfig, RulesMap>,
    P extends Parent,
> {
    errors: Ref<Record<string, FormErrors>>,
    getModelName: () => string,
    hasLocalChanges: Ref<boolean>,
    init: (_m: M, _r: Relation<M, P>, _mix?: ModelFormMixin) => void,
    model: Ref<M>,
    reset(): void,
    rules: Ref<{ [key: string]: string[] }>,
    submit: () => Promise<Submission>,
}

export function useModelForm<
    M extends Model<P, ModelConfig, RulesMap>,
    P extends Parent,
>(
    _model: M,
    _relation: Relation<M, P>,
    _mixin: ModelFormMixin,
    {
        beforeSubmit = () => Promise.resolve(),
        disableLocalChangesDialog = false,
        notifications = {
            show: true,
            variant: 'all',
        },
        onSubmit = () => Promise.resolve(),
        toUpdateMixinAfterCreation = false,
        byId = false,
    }: ModelFormConfig<M, P> = {},
): ModelFormComposableResult<M, P> {
    const dialog = injectOrFail<DialogController>('dialog');
    const vueNotifications = injectOrFail<VueNotifications>('notifications');

    const { t } = useLocalization();

    let originalModel = _model;
    let originalRelation = _relation;

    // Note: Type inference of generics in refs is sometimes incorrect, thus casting is needed. See https://github.com/vuejs/core/issues/2136
    const model = ref<M>(_relation.$factory(_model.$data)) as Ref<M>;
    const relation = ref<Relation<M, P>>(_relation) as unknown as Ref<Relation<M, P>>;
    const mixin = ref<ModelFormMixin>(_mixin);

    const errors = ref<Record<string, FormErrors>>({});
    const hasLocalChanges = ref<boolean>(false);
    const rules = ref<{[key: string]: string[]}>({});
    const watcher = ref<WatchStopHandle>();

    const { asSuccess, asValidationFail } = useSubmission();

    void init(_model, _relation);

    function init(_m: M, _r: Relation<M, P>, _mix: ModelFormMixin = mixin.value): void {
        mixin.value = _mix;

        originalModel = _m;
        originalRelation = _r;

        model.value = _r.$factory(_m.$data);
        relation.value = _r;

        if (watcher.value) {
            watcher.value();
        }

        void _initRules();

        errors.value = {};
        hasLocalChanges.value = false;

        const modelData = computed(() => ({ ...model.value.$data }));
        watcher.value = watch(modelData, (newData) => {
            // To avoid constant stringification; TODO: change local changes false if values revert to original.
            if (hasLocalChanges.value) {
                return;
            }

            hasLocalChanges.value = JSON.stringify(newData) !== JSON.stringify(originalModel.$data);
        }, { deep: true });
    }

    async function submit(): Promise<Submission> {
        await beforeSubmit();

        let isRootUpdate = false;

        try {
            // Specific check for RootUpdate, should be removed when lib-crud removes the root mixins
            if (mixin.value.mixinName === 'UPDATE' && hasMixin(relation.value, RootUpdate(''))) {
                isRootUpdate = true;
                return await _tryMixin(RootUpdate);
            }

            return await _tryMixin(mixin.value);
        } catch (e) {
            if (isOtError(e) && isValidateError(e.cause)) {
                errors.value = e.cause.validation.result.messages;
                return asValidationFail();
            }

            if (isRootUpdate && isOtError(e) && isOtError(e.cause) && isValidateError(e.cause.cause)) {
                errors.value = e.cause.cause.validation.result.messages;
                return asValidationFail();
            }

            _handleNotification('fail', e instanceof Error ? e.message : undefined);

            throw e;
        }
    }

    // Resets modelForm to initial values.
    function reset() {
        init(originalModel, originalRelation);
    }

    function getModelName(): string {
        return 'name' in model.value.$data && typeof model.value.$data.name === 'string'
            ? model.value.$data.name
            : model.value.$modelName();
    }

    async function _initRules() {
        rules.value = await model.value.$rules();
    }

    async function _tryMixin(mix: ModelFormMixin | typeof RootUpdate): Promise<Submission> {
        if (!hasMixin(relation.value, mix)) {
            throw new FormMixinMissingError(mix.mixinName, relation.value.$path);
        }

        const isUpdateMixin = mix.mixinName === 'UPDATE' || mix.mixinName === 'ROOT_UPDATE';
        const isByIdMixin = mix.mixinName === 'CUSTOM-VALIDATED-POST';

        let args: (M | string[] | boolean)[] = [ model.value ];

        if (isUpdateMixin) {
            args = [ model.value, _getDirtyAttributes() ];
        } else if (isByIdMixin) {
            args = [ model.value, byId ];
        }

        const response = await relation.value[mix.methodName](...args) as M | void;

        // Use the response to initialise the form again, and thus update the originalModel.
        if (response && isUpdateMixin) {
            init(response, relation.value);
        }

        return _handleSuccess(response ?? model.value);
    }

    function _getDirtyAttributes() {
        return Object.keys(model.value.$data).filter((key) => {
            if (Array.isArray(model.value.$data[key])) {
                return JSON.stringify(model.value.$data[key]) !== JSON.stringify(originalModel.$data[key]);
            }

            if (model.value.$data[key] === '') {
                return originalModel.$data[key] !== null && originalModel.$data[key] !== undefined
                    && originalModel.$data[key] !== '';
            }

            return model.value.$data[key] !== originalModel.$data[key];
        });
    }

    async function _handleSuccess(response: M) {
        _handleNotification('success');

        hasLocalChanges.value = false;

        if (toUpdateMixinAfterCreation) {
            mixin.value = Update;
        }

        await onSubmit(response);

        return asSuccess();
    }

    function _handleNotification(type: 'success' | 'fail', failMessage?: string) {
        if (!notifications.show) {
            return;
        }

        if (type === 'success' && (notifications.variant === 'all' || notifications.variant === 'success')) {
            vueNotifications.success(t('dashboard.common.save_success', { name: getModelName() }));
        } else if (type === 'fail' && (notifications.variant === 'all' || notifications.variant === 'fail')) {
            let message: NotificationConfig['message'] = t('dashboard.common.save_failed');

            if (failMessage) {
                message = {
                    text: message,
                    subText: failMessage,
                };
            }

            vueNotifications.danger({ message });
        }
    }

    const _handleRouteChange = async (_to: Route, _from: Route, next: NavigationGuardNext) => {
        if (
            hasLocalChanges.value
        ) {
            const confirm = await dialog.confirm({
                // TODO Below is quite ugly, we need a better solution to use translations in script setup (maybe composable?)
                title: t('dashboard.common.confirm.unsaved_changes.title') || '',
                description: t('dashboard.common.confirm.unsaved_changes.description') || '',
                type: 'is-danger',
            });

            if (!confirm) {
                return;
            }

            reset();
        }

        next();
    };

    if (!disableLocalChangesDialog) {
        onBeforeRouteLeave(_handleRouteChange);
        onBeforeRouteUpdate(_handleRouteChange);
    }

    return {
        errors,
        getModelName,
        hasLocalChanges,
        init,
        model,
        reset,
        rules,
        submit,
    };
}
