import type { Route, RouteConfig } from 'vue-router';
import { RouterView } from '../../services/util';
import AddonProductsListView from './views/AddonProductsListView.vue';
import AddonProductUpdateView from './views/AddonProductUpdateView.vue';

export function addonProductsRoutes(): RouteConfig[] {
    return [
        {
            path: 'addon-products',
            component: RouterView,
            meta: {
                title: 'dashboard.document_title.addon-products.list',
                titleFallback: 'Add-on Products',
                onContextSelect: (id: string | symbol | undefined) => {
                    if (typeof id === 'string') {
                        return {
                            name: 'addon-products.list',
                            params: { event: id },
                        };
                    }
                    return null;
                },
            },
            children: [
                {
                    path: '',
                    name: 'addon-products.list',
                    component: AddonProductsListView,
                    meta: {
                        allowedByFlag: 'app__addon_products__navigation',
                    },
                },
                {
                    path: ':addonProduct',
                    component: RouterView,
                    meta: {
                        context: 'addonProduct',
                        title: 'dashboard.document_title.addon_products.addon_product',
                        titleFallback: 'Addon Product',
                    },
                    redirect: (to: Route) => ({
                        name: 'addon-products.edit',
                        params: { ...to.params },
                    }),
                    children: [
                        {
                            path: 'edit',
                            name: 'addon-products.edit',
                            component: AddonProductUpdateView,
                            redirect: (to: Route) => ({
                                name: 'addon-products.edit.details',
                                params: { ...to.params },
                            }),
                            children: [
                                {
                                    path: 'details',
                                    name: 'addon-products.edit.details',
                                },
                                {
                                    path: 'linked-tickets',
                                    name: 'addon-products.edit.linked-tickets',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
}
