<template>
    <ActionTile :title="$t('dashboard.company.edit.actions.seats.title')">
        <OtFormRow>
            <InputField
                :error="companySettingsForm.errors.seats_secret_key"
                :label="$t('dashboard.company.edit.actions.seats.secret_workspace_key.label')"
                :required="companySettingsForm.rules.seats_secret_key
                    && companySettingsForm.rules.seats_secret_key.includes('required')"
            >
                <OtInput
                    v-model="companySettingsForm.model.$data.seats_secret_key"
                    class="input"
                    data-testid="actions-seats-secret-key"
                    type="text"
                    @input="companySettingsForm.errors.seats_secret_key = []"
                />
            </InputField>
        </OtFormRow>
        <OtFormRow>
            <InputField
                :error="companySettingsForm.errors.seats_public_key"
                :label="$t('dashboard.company.edit.actions.seats.public_workspace_key.title')"
                :required="companySettingsForm.rules.seats_public_key
                    && companySettingsForm.rules.seats_public_key.includes('required')"
            >
                <OtInput
                    v-model="companySettingsForm.model.$data.seats_public_key"
                    class="input"
                    data-testid="actions-seats-public-key"
                    type="text"
                    @input="companySettingsForm.errors.seats_public_key = []"
                />
            </InputField>
        </OtFormRow>
        <OtFormRow>
            <InputField
                :description="$t('dashboard.company.edit.actions.seats.allow_orphan_seats.description')"
                :error="companySettingsForm.errors.seats_allow_orphan"
                :label="$t('dashboard.company.edit.actions.seats.allow_orphan_seats.label')"
                :required="companySettingsForm.rules.seats_allow_orphan
                    && companySettingsForm.rules.seats_allow_orphan.includes('required')"
            >
                <OtInputToggle
                    v-model="companySettingsForm.model.$data.seats_allow_orphan"
                    class="input"
                    data-testid="actions-seats-allow-orphan"
                    @input="companySettingsForm.errors.seats_allow_orphan = []"
                />
            </InputField>
        </OtFormRow>
        <button
            :disabled="!hasChanges"
            class="is-small ot-button"
            data-testid="actions-seats-submit"
            @click="save"
        >
            {{ $t('dashboard.company.edit.actions.seats.submit') }}
        </button>
    </ActionTile>
</template>

<script lang="ts" setup>
import type { UnwrapNestedRefs } from 'vue';
import { computed, ref } from 'vue';
import type { CompanySettings, ManagementClient } from '@openticket/lib-management';
import InputField from '../../../../components/form/InputField.vue';
import ActionTile from './ActionTile.vue';
import type { ModelFormComposableResult } from '../../../../composables/forms';

type Props = {
    companySettingsForm: UnwrapNestedRefs<ModelFormComposableResult<
        CompanySettings<ManagementClient>,
        CompanySettings<ManagementClient>
    >>;
}

const props = defineProps<Props>();

// TODO: replace with partial update, currently there is only the watermark that conflicts so checking this suffices for now.
const originalSeats = ref<string>(stringifySeats());
const hasChanges = computed<boolean>(() => (originalSeats.value !== stringifySeats()));

function stringifySeats() {
    return JSON.stringify({
        seats_public_key: props.companySettingsForm.model.$data.seats_public_key,
        seats_secret_key: props.companySettingsForm.model.$data.seats_secret_key,
        seats_allow_orphan: props.companySettingsForm.model.$data.seats_allow_orphan,
    });
}

async function save() {
    await props.companySettingsForm.submit();
    originalSeats.value = stringifySeats();
}

</script>

<style scoped lang="scss">
    ::v-deep {
        & .action-tile__content {
            gap: unset;
        }
    }
</style>
