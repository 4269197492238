<template>
    <div class="debug-plugins__log">
        <code
            class="debug-plugins__log__status full-width"
        >
            <span>Status:</span>

            <span v-if="loading">Loading</span>
            <span v-else>Ready</span>
        </code>

        <template v-if="client">
            <code class="debug-plugins__log__config full-width">
                <span class="row">Config:</span>
                <span class="row"><span>app:&nbsp;</span><span>{{ client.app }}</span></span>
                <span class="row"><span>session:&nbsp;</span><span>{{ client.session }}</span></span>
                <span class="row"><span>User agent:&nbsp;</span><span>{{ client.userAgent }}</span></span>
            </code>
        </template>

        <template v-if="plugins.log.lastError">
            <code class="debug-plugins__log__last-error full-width">
                <span class="row">LastError:</span>
            </code>
            <template v-for="error in chain">
                <code
                    :key="error.id"
                    class="debug-plugins__log__last-error full-width"
                >
                    <span class="row"><span>{{ error.slug }}</span></span>
                    <span class="row"><span>{{ { ...error.getLogContext(), _stack_: undefined } }}</span></span>
                </code>
            </template>
        </template>
    </div>
</template>

<script lang="ts" setup>
import type { Log, OtError } from '@openticket/lib-log';
import { computed, onMounted, ref } from 'vue';
import type { PluginsManager } from '../../../../plugins';
import { injectOrFail } from '../../../../services/util';

const plugins = injectOrFail<PluginsManager>('plugins');
const loading = ref<boolean>(true);

const client = ref<Log | null>(null);

onMounted(() => {
    void initializePluginDebug();
});

async function initializePluginDebug() {
    try {
        client.value = await plugins.log.loading;
    } finally {
        loading.value = false;
    }
}

const chain = computed<OtError[]>(() => {
    if (!plugins.log.lastError) {
        return [];
    }

    const errors: OtError[] = [];

    let current: OtError | null = plugins.log.lastError;

    while (current) {
        errors.push(current);
        current = current.cause;
    }

    return errors;
});
</script>

<style lang="scss" scoped>
.debug-plugins__log {
    & > code {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;

        & > span:not(:first-child) {
            padding-left: .5rem;
        }

        &.debug-plugins__log__config, &.debug-plugins__log__last-error {
            flex-direction: column;
            justify-content: flex-start;

            & > .row {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
</style>
